import React from 'react';
import { Link } from 'react-router-dom';

const ErrorBox: React.FC<{ text: string; link?: string }> = ({ text, link }) => {
  return (
    <div className="alert alert-danger">
      {text}{' '}
      {link && (
        <>
          {' '}
          - <Link to={link}>Retour</Link>
        </>
      )}
    </div>
  );
};

export default ErrorBox;
