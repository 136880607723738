import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CircularLoader from '../../common/navigation/CircularLoader';
import Info from '../navigation/Info';
import Pagination from '../../common/navigation/Pagination';
import { BACKEND_URL } from '../../config//environment';
/**
 * Load purchaser links used to validate DCE requests coming from companies.
 */
const PurchaserLinks: React.FC = () => {
  const [links, setLinks] = useState<Array<IPurchaser> | null>(null);
  const [lastPage, setLastPage] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  /**
   * Load purchaser links first time.
   */
  useEffect(() => {
    if (!links) {
      changePage(1);
    }
  }, []);

  /**
   * Load purchaser links in a specific page with a query string filter.
   * @param page Current page.
   */
  const changePage = (page: number) => {
    setLoading(true);
    axios
      .post<IPaginatePurchaser>(`${BACKEND_URL}/api/admin/purchasers?page=${page}`, {
        filter: searchQuery,
      })
      .then(({ data }) => {
        setLinks(data.purchasers.data);
        setLastPage(data.purchasers.last_page);
        setCurrentPage(data.purchasers.current_page);
        setTotal(data.purchasers.total);
        setLoading(false);
      });
  };

  /**
   * Triggered when the user press a touch to detect if it is "Enter". It it is, is starts a search.
   * @param key Key pressed.
   */
  const searchLinks = (key: string) => {
    if (key === 'Enter' || searchQuery === '') {
      setLastPage(1);
      changePage(1);
    }
  };

  return (
    <>
      <div className="alert alert-info">
        Retrouvez ici la liste de tous les liens des DO leur permettant de valider les demandes
        d&apos;accès DCE.
      </div>

      <div className="card card-body">
        <div className="row">
          <div className="col-sm-9 col-lg-10">
            <input
              type="text"
              id="text-field-search"
              data-cy="text-field-search"
              className="form-control auth-input mt-0"
              placeholder="Rechercher un DO par son e-mail"
              autoComplete="off"
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyUp={(e) => searchLinks(e.key)}
            />
          </div>

          <div className="col-sm-3 col-lg-2 px-1 py-1">
            <button className="btn btn-primary" onClick={() => changePage(1)}>
              Rechercher
            </button>
          </div>
        </div>

        {loading && <CircularLoader />}

        {!loading && links && links.length === 0 && (
          <Info text="Aucun liens de validation DO n'a été trouvé." />
        )}

        {!loading && links && links.length > 0 && (
          <div className="table-responsive">
            <div className="alert alert-info mt-1 mb-1">
              {total} {total > 1 ? 'liens DO trouvés.' : 'lien DO trouvé.'}
            </div>
            <table className="table table-hover table-bordered mt-2">
              <thead>
                <tr>
                  <th scope="col">Email du DO</th>
                  <th scope="col">Liens</th>
                  <th scope="col">Lien généré</th>
                </tr>
              </thead>
              <tbody style={{ verticalAlign: 'top' }}>
                {links &&
                  links.length > 0 &&
                  links.map((purchaser: IPurchaser) => (
                    <tr key={purchaser.id}>
                      <td>{purchaser.mail}</td>
                      <td>
                        <a
                          href={`${process.env.DOMAIN_APP_URL}/projects-requests-validation/${purchaser.token}`}
                          target="_blank"
                          rel="noreferrer"
                          className="no-uppercase"
                        >
                          {process.env.DOMAIN_APP_URL}/projects-requests-validation/
                          {purchaser.token}
                        </a>
                      </td>
                      <td>{purchaser.created_at.toString().split('-').reverse().join('/')}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {lastPage > 0 && (
        <Pagination lastPage={lastPage} currentPage={currentPage} toCall={changePage} />
      )}
    </>
  );
};

export default PurchaserLinks;
