import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useHistory } from 'react-router-dom';
import ErrorMessage from '../../common/errors/ErrorMessage';
import { BACKEND_URL } from '../../config//environment';

const EditFAQForm: React.FC = () => {
  const history = useHistory();
  const [error, setError] = useState<string>(null);
  const [categories, setCategories] = useState<Array<IFaqCategory> | null>(null);
  const [subCategories, setSubCategories] = useState<Array<IFaqCategory> | null>(null);
  const [question, setQuestion] = useState<IFaqQuestion>({
    id: '',
    question: '',
    answer: '',
    slug: '',
    visible: false,
    public_visibility: false,
    app_visibility: false,
    faq_category_id: '',
    main_category: '',
    created_at: new Date(),
    updated_at: new Date(),
  });

  const { faqId }: { faqId: string } = useParams();

  const getCategories = (mainId: string = null) => {
    return axios.get(
      `${BACKEND_URL}/api/admin/faq-categories`,
      mainId && {
        params: {
          id: mainId,
        },
      }
    );
  };

  const sendFaqUpdateRequest = (id: string, form: IFaqQuestion) =>
    axios.put(`${BACKEND_URL}/api/admin/faq-questions/${id}`, form);

  const showQuestion = (id: string) => axios.get(`${BACKEND_URL}/api/admin/faq-questions/${id}`);

  useEffect(() => {
    if (!categories) {
      getCategories().then(({ data }) => setCategories(data.faq_categories));

      showQuestion(faqId).then(({ data }) => {
        const faqQuestion = data.faq_question;
        setQuestion({
          ...faqQuestion,
          main_category: faqQuestion.faq_category.main_category.id,
        });

        getCategories(faqQuestion.faq_category.main_category.id).then(({ data }) =>
          setSubCategories(data.faq_categories)
        );
      });
    }
  }, []);

  const handleOnSubmitForm = (event: React.FormEvent) => {
    sendFaqUpdateRequest(faqId, question)
      .then(() => {
        history.push('/admin/faq');
      })
      .catch((err) => {
        const error = err?.response?.data?.errorMessage;
        setError(error ? error : 'Une erreur est survenue.');
      });

    event.preventDefault();
  };

  const handleCategoryChange = (event: React.ChangeEvent) => {
    const target = event.target as HTMLSelectElement;
    const { value, name } = target;

    setQuestion({
      ...question,
      [name]: value,
    });

    getCategories(value).then(({ data }) => setSubCategories(data.faq_categories));
  };

  const handleInputChange = (event: React.ChangeEvent) => {
    const input = event.target as HTMLInputElement;
    const { name } = input;
    let { value }: { value: boolean | string } = input;
    const theBooleans = ['visible', 'app_visibility', 'public_visibility'];
    if (theBooleans.includes(name)) {
      value = value === 'true';
    }

    setQuestion({
      ...question,
      [name]: value,
    });
  };

  const boolToStr = (v: boolean) => (v ? 'true' : 'false');

  return (
    <div className="card">
      <div className="card-header">Ajouter Nouvelle Question</div>
      <div className="card-body">
        <div className="row">
          <div className="col">
            {error && <ErrorMessage text={error} />}
            <form onSubmit={handleOnSubmitForm}>
              <div className="form-row">
                <label className="col-md-3 mt-3" htmlFor="category">
                  Catégorie
                </label>
                <select
                  className="form-control col-md-3"
                  name="main_category"
                  id="category"
                  onChange={handleCategoryChange}
                  value={question.main_category}
                >
                  {categories &&
                    categories.map((cat: IFaqCategory) => (
                      <option key={cat.id} value={cat.id}>
                        {cat.title}
                      </option>
                    ))}
                </select>
                <label className="col-md-3 mt-3" htmlFor="sub-category">
                  Sous-Catégorie
                </label>
                <select
                  className="form-control col-md-3 mb-3"
                  name="faq_category_id"
                  id="sub-category"
                  value={question.faq_category_id}
                  onChange={handleInputChange}
                >
                  {subCategories &&
                    subCategories.map((cat: IFaqCategory) => {
                      const id = cat.id;
                      return (
                        <option key={id} value={id}>
                          {cat.title}
                        </option>
                      );
                    })}
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="question">Question</label>
                <div className="input-group">
                  <input
                    type="text"
                    id="question"
                    name="question"
                    className="form-control"
                    value={question.question}
                    onChange={handleInputChange}
                  />
                  <div className="input-group-append mt-2 pt-1">
                    <div className="input-group-text">?</div>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="answer">Réponse</label>
                <textarea
                  id="answer"
                  name="answer"
                  className=" form-control"
                  rows={3}
                  value={question.answer}
                  onChange={handleInputChange}
                />
              </div>

              <div className="form-group row">
                <label className="col-md-3 mt-3" htmlFor="accessible-public">
                  Accessible sur le site Public
                </label>
                <div className="col-md-3">
                  <select
                    className="form-control"
                    id="accessible-public"
                    name="public_visibility"
                    value={boolToStr(question.public_visibility)}
                    onChange={handleInputChange}
                  >
                    <option value="true">Oui</option>
                    <option value="false">Non</option>
                  </select>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-md-3 mt-3" htmlFor="accessible-app">
                  Accessible sur Application
                </label>
                <div className="col-md-3">
                  <select
                    className="form-control"
                    id="accessible-app"
                    name="app_visibility"
                    value={boolToStr(question.app_visibility)}
                    onChange={handleInputChange}
                  >
                    <option value="true">Oui</option>
                    <option value="false">Non</option>
                  </select>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-md-3 mt-3" htmlFor="visible">
                  Visible
                </label>
                <div className="col-md-3">
                  <select
                    className="form-control"
                    id="visible"
                    name="visible"
                    value={boolToStr(question.visible)}
                    onChange={handleInputChange}
                  >
                    <option value="true">Oui</option>
                    <option value="false">Non</option>
                  </select>
                </div>
              </div>

              <div className="row justify-content-md-center mt-4">
                <div className="col-lg-6">
                  <button type="submit" className="btn btn-primary">
                    Modifier
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditFAQForm;
