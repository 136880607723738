import React, { useEffect, useState } from 'react';

const PaymentError: React.FC<{
  user: IUser;
  activeOffer?: any;
  pendingOffer?: any;
}> = ({ user, activeOffer, pendingOffer }) => {
  const [error, setError] = useState<string>('');

  useEffect(() => {
    let litigationOffer = undefined;
    if (pendingOffer && pendingOffer.payment_code) {
      litigationOffer = pendingOffer;
    } else if (activeOffer && activeOffer.payment_code) {
      litigationOffer = activeOffer;
    }

    if (litigationOffer) {
      const paymentErrorCode = litigationOffer.payment_code;
      const paymentDeclineCode = litigationOffer.payment_decline_code;

      if ((user.profile === 0 && user.role?.role === 'manager') || user.profile === 3) {
        if (paymentErrorCode === 'expired_card') {
          setError('La carte de l’utilisateur est périmée');
        }
        if (paymentErrorCode === 'card_declined' && paymentDeclineCode === 'insufficient_funds') {
          setError('Manque de fonds');
        }
        if (
          paymentErrorCode === 'card_declined' &&
          (paymentDeclineCode === 'generic_decline' ||
            paymentDeclineCode === 'lost_card' ||
            paymentDeclineCode === 'stolen_card')
        ) {
          setError('La banque a empêché la transaction');
        }
        if (
          (paymentErrorCode === 'payment_intent_payment_attempt_failed' &&
            paymentDeclineCode === 'generic_decline') ||
          paymentErrorCode === 'fraudulent'
        ) {
          setError('Paiement contesté');
        }
      } else {
        if (paymentErrorCode !== '') {
          setError('Le manager de cet utilisateur n’a pas payé l’abonnement.');
        }
      }
    }
  }, [activeOffer, pendingOffer]);

  return (
    <>
      {error !== '' && (
        <div className="row mb-3">
          <div className="col-12">
            <div className="card">
              <div className="card-header">Erreur de paiement</div>
              <div className="card-body">
                <h6>Erreur</h6>
                <div className="alert alert-danger mt-2 mb-0">
                  <b>Abonnement bloqué:</b> {error}
                </div>
                {user && user.managers.length > 0 && user.role?.role !== 'manager' && (
                  <div className="mt-2 mb-0">
                    <a
                      href={`/admin/utilisateurs/${user.managers[0].id}`}
                      className="mt-2"
                      style={{ textTransform: 'none' }}
                    >
                      Voir la fiche du manager
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentError;
