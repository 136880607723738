import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import ErrorBox from '../../common/errors/ErrorBox';
import ErrorMessage from '../../common/errors/ErrorMessage';
import CircularLoader from '../../common/navigation/CircularLoader';
import Success from '../navigation/Success';
import { useParams } from 'react-router';
import { IAosProject } from '../../interfaces/IAosProject';
import Modal from '../../common/Modal';
import { ToastContext } from '../../common/providers/ToastProvider';
import { AOSOps } from '../../constants/AOSOps';
import { IAOSOps } from '../../interfaces/IAOSOps';
import departments from '../../components/settings/preferences/departments.json';
import { BACKEND_URL } from '../../config/environment';
import moment from 'moment-timezone';
import 'moment/locale/fr';

/**
 * Load companies and show them in a responsive table.
 */
const Project: React.FC = () => {
  const [project, setProject] = useState<IProject>(null);
  const [aosProject, setAosProject] = useState<IAosProject>(null);
  const [aosProjectTrades, setAosProjectTrades] = useState<string & Array<ITrade>>();

  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<string>(null);
  const [errorMessage, setErrorMessage] = useState<string>(null);
  const [isModifying, setIsModifying] = useState<boolean>(false);
  const [constructionTypes, setConstructionTypes] = useState<Array<string>>([]);
  const [selectAOSFieldsModal, setSelectAOSFieldsModal] = useState<boolean>(false);

  const [aosProjectId, setAosProjectId] = useState<string>('');
  const [imagePath, setImagePath] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [landingDescription, setLandingDescription] = useState<string>('');
  const [beginOfOfferingDate, setBeginOfOfferingDate] = useState<string>('');
  const [endOfOfferingDate, setEndOfOfferingDate] = useState<string>('');
  const [buildingSiteStartDate, setBuildingSiteStartDate] = useState<string>('');
  const [buildingSiteEndDate, setBuildingSiteEndDate] = useState<string>('');
  const [constructionType, setConstructionType] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [zipCode, setZipCode] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [purchaserName, setPurchaserName] = useState<string>('');
  const [purchaserMail, setPurchaserMail] = useState<string>('');
  const [projectPublics, setProjectPublics] = useState<Array<IProjectPublic>>([]);
  const [trades, setTrades] = useState<Array<ITrade>>([]);
  const [external, setExternal] = useState<boolean>(false);
  const [flash, setFlash] = useState<boolean>(false);
  const [coming, setComing] = useState<boolean>(false);
  const [opsId, setOpsId] = useState<string>('0');
  const [longitude, setLongitude] = useState<number>(0);
  const [latitude, setLatitude] = useState<number>(0);
  const [statesBroadcast, setStatesBroadcast] = useState<Array<string>>([]);

  // Users found by searching a new one.
  const [searchUserField, setSearchUserField] = useState<string>('');
  const [users, setUsers] = useState<Array<IUser>>([]);

  // Fields to add/remove a new trade.
  const [tradeIdField, setTradeIdField] = useState<string>('');
  const [tradeNameField, setTradeNameField] = useState<string>('');
  const [projectRequests, setProjectRequests] = useState<Array<IProjectRequest>>([]);
  const [tradesLoading, setTradesLoading] = useState<boolean>(false);

  // SELECTS OPTIONS
  interface selectOptions {
    key: string;
    value: string;
  }
  const [departmentValues, setDepartmentValues] = useState<selectOptions[]>();

  const { displayToast } = useContext(ToastContext);

  const [fieldsToRefresh, setFieldsToRefresh] = useState([
    {
      key: 'image_path',
      text: 'Image',
      checked: true,
      update: setImagePath,
    },
    {
      key: 'name',
      text: 'Nom du projet',
      checked: false,
      update: setName,
    },
    {
      key: 'construction_type',
      text: 'Type de construction',
      checked: false,
      update: setConstructionType,
    },
    {
      key: 'description',
      text: 'Description',
      checked: false,
      update: setDescription,
    },
    {
      key: 'landing description',
      text: 'Description Landing',
      checked: false,
      update: setLandingDescription,
    },
    {
      key: 'purchaser_name',
      text: "Nom donneur d'ordre",
      checked: false,
      update: setPurchaserName,
    },
    {
      key: 'purchaser_mail',
      text: "Mail donneur d'ordre",
      checked: false,
      update: setPurchaserMail,
    },
    {
      key: 'begin_of_offering_date',
      text: "Date de début d'appel d'offres",
      checked: false,
      update: setBeginOfOfferingDate,
    },
    {
      key: 'end_of_offering_date',
      text: "Date de fin d'appel d'offres",
      checked: false,
      update: setEndOfOfferingDate,
    },
    {
      key: 'building_site_start_date',
      text: 'Date de début des travaux',
      checked: false,
      update: setBuildingSiteStartDate,
    },
    {
      key: 'building_site_end_date',
      text: 'Date de fin des travaux',
      checked: false,
      update: setBuildingSiteEndDate,
    },
    {
      key: 'address',
      text: 'Adresse',
      checked: false,
      update: setAddress,
    },
    {
      key: 'zip_code',
      text: 'Code postal',
      checked: false,
      update: setZipCode,
    },
    {
      key: 'city',
      text: 'Ville',
      checked: false,
      update: setCity,
    },
    {
      key: 'state',
      text: 'Numéro de département',
      checked: false,
      update: setState,
    },
  ]);

  const { projectId }: { projectId: string } = useParams();

  /**
   * Load the project.
   */
  const loadProject = () => {
    axios
      .get(`${BACKEND_URL}/api/admin/projects/${projectId}`)
      .then(({ data }) => {
        setProject(data.project);
        setImagePath(data.project.image_path);
        setAosProjectId(data.project.aos_project_id);
        setName(data.project.name);
        setDescription(data.project.description);
        setLandingDescription(data.project.landing_description);
        setConstructionType(data.project.construction_type);
        setBeginOfOfferingDate(inputFormattedDate(data.project.begin_of_offering_date));
        setEndOfOfferingDate(inputFormattedDate(data.project.end_of_offering_date));
        setBuildingSiteStartDate(inputFormattedDate(data.project.building_site_start_date));
        setBuildingSiteEndDate(inputFormattedDate(data.project.building_site_end_date));
        setAddress(data.project.address);
        setZipCode(data.project.zip_code);
        setCity(data.project.city);
        setState(data.project.state);
        setPurchaserName(data.project.purchaser_name);
        setPurchaserMail(data.project.purchaser_mail);
        setProjectPublics(data.project.project_publics);
        setTrades(data.project.trades);
        setExternal(data.project.is_external);
        setFlash(data.project.is_flash);
        setComing(data.project.is_coming);
        setOpsId(data.project.ops_id);
        setConstructionTypes(data.construction_types);
        setProjectRequests(data.project.project_requests);
        setLongitude(data.project.longitude);
        setLatitude(data.project.latitude);
        setStatesBroadcast(data.project.states_broadcast);
      })
      .catch(() => {
        setError(true);
      });
  };

  useEffect(() => {
    loadProject();

    axios.get(`${BACKEND_URL}/api/admin/filters?filters[]=states`).then(({ data }) => {
      constructSelectStateOptions(data);
    });
  }, []);

  const constructSelectStateOptions = (data: any) => {
    if (data.states) {
      const dep: selectOptions[] = data.states.map((el: any) => {
        return { key: el.id, value: el.name } as selectOptions;
      });
      setDepartmentValues(dep);
    }
  };

  const resetInformation = () => {
    loadProject();

    setIsModifying(false);
  };

  /**
   * Edit project information.
   */
  const editInformation = () => {
    setSuccess(null);
    setErrorMessage(null);

    if (isModifying) {
      axios
        .post(`${BACKEND_URL}/api/admin/projects/${projectId}`, {
          image_path: imagePath,
          aos_project_id: aosProjectId,
          name: name,
          description: description,
          landing_description: landingDescription,
          purchaser_name: purchaserName,
          purchaser_mail: purchaserMail,
          construction_type: constructionType,
          ops_id: opsId,
          address: address,
          zip_code: zipCode,
          city: city,
          state: state,
          is_external: external,
          is_flash: flash,
          is_coming: coming,
          states_broadcast: statesBroadcast,
        })
        .then(({ data }) => {
          setSuccess(`Le projet a bien été modifié.`);
          setIsModifying(!isModifying);
          setProject(data.project);
        })
        .catch((err) => {
          const error = err?.response?.data?.errorMessage;
          setErrorMessage(error ? error : 'Une erreur est survenue.');
        });
    } else {
      setIsModifying(!isModifying);
    }
  };

  /**
   * Search an user to add its account in the "public" field.
   * @param query User to find.
   */
  const searchUser = (query: string) => {
    setSearchUserField(query);

    if (query && query.length > 0) {
      axios
        .post(`${BACKEND_URL}/api/admin/search-user`, { query })
        .then(({ data }) => setUsers(data.users));
    } else {
      setUsers([]);
    }
  };

  /**
   * Create a public project for the specified account
   * @param accountId ID of the account.
   */
  const createProjectPublic = (accountId: string) => {
    setUsers([]); // Hide the option to click on an user.
    setSearchUserField('');

    axios
      .post(`${BACKEND_URL}/api/admin/project-publics`, {
        account_id: accountId,
        project_id: projectId,
      })
      .then(({ data }) => {
        if (!projectPublics.find((p) => p.account_id === accountId && p.project_id && projectId)) {
          setProjectPublics([data.project_public, ...projectPublics]);
        }
      });
  };

  /**
   * Delete a public project
   */
  const deleteProjectPublic = (publicProjectId: string) => {
    if (
      !confirm(
        'Êtes vous sûr(e) de rendre "non public" ce projet pour cet utilisateur et tous ceux qui partagent le même compte ?'
      )
    ) {
      return;
    }

    axios.delete(`${BACKEND_URL}/api/admin/project-publics/${publicProjectId}`).then(() => {
      setProjectPublics(
        projectPublics.filter((projectPublic) => projectPublic.id !== publicProjectId)
      );
    });
  };

  /**
   * Get the trades
   * @returns void
   */
  const getTrades = () => {
    return axios.get(`${BACKEND_URL}/api/admin/projects/${projectId}/trades`).then(({ data }) => {
      setTrades(data.trades);
    });
  };

  /**
   * Hide a trade
   *
   * @param id ID of the trade to hide.
   * @param askConfirm Show a modal to confirm to hide the trade if the value is true.
   */
  const hideTrade = (id: string, askConfirm: boolean) => {
    if (
      askConfirm &&
      !confirm(
        'Ce lot ne pourra plus être demandé par les entreprises. Êtes vous sûr(e) de vouloir masquer ce lot ?'
      )
    ) {
      return;
    }

    return axios.delete(`${BACKEND_URL}/api/admin/trades/${id}`).then(() => {
      getTrades();
    });
  };

  /**
   * Restore a trade
   *
   * @param id ID of the trade to restore.
   * @param askConfirm Show a modal to confirm to restore the trade if the value is true.
   */
  const restoreHideTrade = (id: string, askConfirm: boolean) => {
    if (
      askConfirm &&
      !confirm(
        'Ce lot  pourra à nouveau être demandé par les entreprises. Êtes vous sûr(e) de vouloir démasquer ce lot ?'
      )
    ) {
      return;
    }

    return axios.post(`${BACKEND_URL}/api/admin/trades/${id}/restore`).then(() => {
      getTrades();
    });
  };

  /**
   * Delete a project by adding a flag "deleted" in the db.
   * @param projectId Project to delete.
   */
  const deleteProject = (projectId: string) => {
    if (
      !confirm(
        'Attention : Êtes vous sûr(e) de vouloir archiver ce projet ?\nLes demandes des entreprises en attente vont être automatiquement refusées.'
      )
    ) {
      return;
    }

    axios.delete(`${BACKEND_URL}/api/admin/projects/${projectId}`).then(() => {
      loadProject();
    });
  };

  /**
   * Delete a project by adding a flag "deleted" in the db.
   * @param projectId Project to delete.
   */
  const unrachiveProject = (projectId: string) => {
    if (
      !confirm(
        'Attention : Êtes vous sûr(e) de vouloir désarchiver ce projet ?\nLes demandes de DCE refusées vont être automatiquement déplacées vers le statut en attente de validation.'
      )
    ) {
      return;
    }

    axios.post(`${BACKEND_URL}/api/admin/projects/${projectId}/restore`).then(() => {
      loadProject();
    });
  };

  /**
   * Return an HTML string to show the current status with "friendly" showing.
   * @param status Status (enum).
   */
  const getStatus = (projectRequest: IProjectRequest) => {
    const validatedRequested = projectRequest.request_trades.filter(
      (requestTrade) => requestTrade.status === 'validated'
    );

    const refusedRequested = projectRequest.request_trades.filter(
      (requestTrade) => requestTrade.status === 'refused'
    );

    if (projectRequest.status === 'validated')
      return (
        <span className="badge badge-success">Validé le {validatedRequested[0].updated_at}</span>
      );

    if (projectRequest.status === 'refused')
      return <span className="badge badge-danger">Refusé le {refusedRequested[0].updated_at}</span>;

    if (projectRequest.status === 'requested')
      return <span className="badge badge-info">En attente de validation</span>;
  };

  const updateFromAos = () => {
    axios
      .get(`${BACKEND_URL}/api/admin/projects/aos/${project.aos_project_id}`)
      .then(({ data }) => {
        setSelectAOSFieldsModal(true);
        setAosProject(data.project);
      })
      .catch(() => {
        setErrorMessage('Projet introuvable.');
      });
  };

  const updateFieldsToRefresh = (key: string) => {
    const fieldsToRefreshCopy = fieldsToRefresh.slice();
    const index = fieldsToRefreshCopy.findIndex((el) => el.key === key);
    fieldsToRefreshCopy[index].checked = !fieldsToRefreshCopy[index].checked;
    setFieldsToRefresh(fieldsToRefreshCopy);
  };

  const refreshProject = () => {
    const fields = fieldsToRefresh.filter((el) => el.checked === true);
    const trade = fieldsToRefresh.filter((el) => el.key === 'trades')[0];

    const values = Object.values(aosProject);
    //Update checked fields
    Object.keys(aosProject).forEach((key, index) => {
      const fieldIndex = fields.findIndex((field) => field.key === key);

      if (fieldIndex >= 0 && values[index] && values[index].length > 0) {
        fields[fieldIndex].update(values[index]);
      }
    });

    //Updated checked trades
    if (trade && trade.checked) {
      trade.update(aosProjectTrades);
    }

    setIsModifying(true);
    setSelectAOSFieldsModal(false);
  };

  const getAosProjectValue = (fieldKey: string): null | string => {
    let value = null;
    if (aosProject) {
      const values = Object.values(aosProject);
      Object.keys(aosProject).forEach((key, index) => {
        if (fieldKey === key) {
          if (values[index] && values[index].length !== 0) {
            value = values[index];
          }
        }
      });
    }
    return value;
  };

  const checkAll = () => {
    const fieldsToRefreshCopy = fieldsToRefresh.slice();

    fieldsToRefreshCopy.forEach((el) => {
      el.checked = true;
    });
    setFieldsToRefresh(fieldsToRefreshCopy);
  };

  const inputFormattedDate = (date: string): string => {
    if (!date) {
      return '';
    }

    const timezone: string = moment.tz.guess();
    return moment(date).tz(timezone).format('YYYY-MM-DD');
  };

  const formattedDate = (date: string): string => {
    if (!date) {
      return '';
    }

    moment.locale(navigator.language);
    return moment(date).format('L');
  };

  return (
    <>
      {!error && !project && <CircularLoader />}
      {error && (
        <ErrorBox text="Désolé, ce projet n'existe pas ou est archivé." link="/admin/projets" />
      )}
      {success && <Success text={success} />}

      <Modal
        hidden={selectAOSFieldsModal}
        maxWidth={500}
        closeModal={() => setSelectAOSFieldsModal(false)}
      >
        <div className="card card-body" style={{ height: '100%', overflow: 'auto' }}>
          <h1>Selectionnez les champs à rafraichir</h1>
          <div>
            {fieldsToRefresh.map((field, index) => (
              <div key={index} className="pt-0 pb-0">
                <input
                  type="checkbox"
                  name={field.key}
                  className="mt-1"
                  checked={field.checked}
                  onClick={() => updateFieldsToRefresh(field.key)}
                />
                <label htmlFor="project_name" className="ml-2 mb-0">
                  <b>{field.text}</b>
                </label>
                <span> ({getAosProjectValue(field.key) ?? 'Pas de valeur'})</span>
              </div>
            ))}
          </div>
          <div>
            <button onClick={() => checkAll()} className="btn btn-primary-outline no-block m-0">
              Tout cocher
            </button>
            <button onClick={() => refreshProject()} className="btn btn-primary no-block m-0 ml-2">
              Valider et rafraîchir le projet
            </button>
          </div>
        </div>
      </Modal>
      {project && (
        <>
          {errorMessage && <ErrorMessage text={errorMessage} />}

          {project.is_external && (
            <div className="alert alert-info">
              Ce projet est public et peut être partagé sur les réseaux sociaux via l&apos;adresse{' '}
              <b>https://app.chantierprive.fr/?open_bid={project.external_token}</b>
            </div>
          )}

          <div className="card">
            <div className="card-header">
              Profil du projet <b>{project.name}</b>
              <span style={{ color: 'red' }}>
                {project.archived_at
                  ? ' (Archivé le ' + formattedDate(project.archived_at.toString()) + ')'
                  : ''}
              </span>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    Image
                    <br />
                    <img src={imagePath} style={{ maxWidth: 250 }} />
                  </div>

                  <div className="form-group">
                    Chargé de clientèle
                    <br />
                    <select
                      value={opsId ?? 'Aucun'}
                      disabled={!isModifying}
                      name="ops_id"
                      onChange={(e) =>
                        e.target.value === 'Aucun' ? setOpsId('0') : setOpsId(e.target.value)
                      }
                      className="m-0 pl-2 col-12 form-control"
                    >
                      <option value="Aucun">Aucun</option>
                      {AOSOps.map((op: IAOSOps) => (
                        <option value={op.id} key={op.id}>
                          {op.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group">
                    Identifiant AOS**
                    <br />
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control"
                      value={aosProjectId}
                      disabled={true}
                    />
                  </div>

                  <div className="form-group">
                    Nom du projet*
                    <br />
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      disabled={!!aosProjectId}
                    />
                  </div>

                  <div className="form-group">
                    Type de construction* :<br />
                    <select
                      onChange={(e) => setConstructionType(e.target.value)}
                      className="form-control"
                      value={constructionType}
                      disabled={!isModifying}
                    >
                      {constructionTypes.map((type, index) => (
                        <option key={index} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group">
                    Description :<br />
                    <textarea
                      className="form-control"
                      style={{ height: 115 }}
                      onChange={(e) => setDescription(e.target.value)}
                      value={description || ''}
                      disabled={!!aosProjectId}
                    ></textarea>
                  </div>

                  <div className="form-group">
                    Description Landing :<br />
                    <textarea
                      className="form-control"
                      style={{ height: 115 }}
                      onChange={(e) => setLandingDescription(e.target.value)}
                      value={landingDescription || ''}
                      disabled={!isModifying}
                    ></textarea>
                  </div>

                  <div className="form-group">
                    Nom donneur d&apos;ordre
                    <br />
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control"
                      onChange={(e) => setPurchaserName(e.target.value)}
                      value={purchaserName}
                      disabled={!!aosProjectId}
                    />
                  </div>

                  <div className="form-group">
                    Mail donneur d&apos;ordre**
                    <br />
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control"
                      onChange={(e) => setPurchaserMail(e.target.value)}
                      value={purchaserMail}
                      disabled={!isModifying}
                    />
                  </div>

                  <div className="form-group">
                    {projectRequests && projectRequests.length === 0 && (
                      <div className="alert alert-info">
                        Aucun entreprise n&apos;a demandé l&apos;accès à ce projet
                      </div>
                    )}

                    {projectRequests && projectRequests.length > 0 && (
                      <div className="alert alert-info">
                        {projectRequests.length}{' '}
                        {projectRequests.length > 1 ? 'entreprises ont' : 'entreprise a'} demandé
                        l&apos;accès à ce projet
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        Début d&apos;appel d&apos;offres :<br />
                        <input
                          type="date"
                          autoComplete="off"
                          className="form-control"
                          value={beginOfOfferingDate}
                          disabled={true}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        Fin d&apos;appel d&apos;offres :<br />
                        <input
                          type="date"
                          autoComplete="off"
                          className="form-control"
                          value={endOfOfferingDate}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        Début de début des travaux :<br />
                        <input
                          type="date"
                          autoComplete="off"
                          className="form-control"
                          value={buildingSiteStartDate}
                          disabled={true}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        Début de fin des travaux :<br />
                        <input
                          type="date"
                          autoComplete="off"
                          className="form-control"
                          value={buildingSiteEndDate}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        Longitude :<br />
                        <input
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          value={longitude}
                          disabled={true}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        Latitude :<br />
                        <input
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          value={latitude}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    Adresse
                    <br />
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control"
                      onChange={(e) => setAddress(e.target.value)}
                      value={address}
                      disabled={!isModifying}
                    />
                  </div>

                  <div className="form-group">
                    <div className="row">
                      <div className="col-lg-4">
                        Code postal
                        <br />
                        <input
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          onChange={(e) => setZipCode(e.target.value)}
                          value={zipCode}
                          disabled={!isModifying}
                        />
                      </div>
                      <div className="col-lg-8">
                        Ville
                        <br />
                        <input
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          onChange={(e) => setCity(e.target.value)}
                          value={city}
                          disabled={!isModifying}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-4">
                      <div className="form-group">
                        Numéro de Département
                        <br />
                        <select
                          className="form-control"
                          disabled={!isModifying}
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                        >
                          <option value="">Aucun</option>
                          {departments.map((department, index) => (
                            <option key={index} value={department.number}>
                              {department.number}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="form-group">
                        Disponible publiquement*
                        <br />
                        <select
                          className="form-control"
                          disabled={!isModifying}
                          value={external ? '1' : '0'}
                          onChange={(e) => setExternal(e.target.value === '1' ? true : false)}
                        >
                          <option value="1">Oui</option>
                          <option value="0">Non</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="form-group">
                        Flash*
                        <br />
                        <select
                          className="form-control"
                          disabled={!isModifying}
                          value={flash ? '1' : '0'}
                          onChange={(e) => setFlash(e.target.value === '1' ? true : false)}
                        >
                          <option value="1">Oui</option>
                          <option value="0">Non</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-4">
                      <div className="form-group">
                        Projet à venir*
                        <br />
                        <select
                          className="form-control"
                          disabled={!isModifying}
                          value={coming ? '1' : '0'}
                          onChange={(e) => setComing(e.target.value === '1' ? true : false)}
                        >
                          <option value="1">Oui</option>
                          <option value="0">Non</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="form-group">
                        <label>Départements de diffusion</label>
                        <select
                          name="states_broadcast"
                          data-cy="states-broadcast-id"
                          value={statesBroadcast}
                          multiple
                          onChange={(e) => {
                            setStatesBroadcast(
                              Array.from(e.target.selectedOptions, (o) => o.value)
                            );
                          }}
                          className="form-control mt-2"
                          style={{
                            color: departments ? '#495057' : '#A9AEB6',
                            backgroundColor: '#EEF1F6',
                          }}
                          disabled={!isModifying}
                        >
                          <option value="" selected disabled hidden>
                            Choisir les départements
                          </option>
                          {departmentValues &&
                            departmentValues.map((element) => (
                              <option
                                key={element.key}
                                value={element.key}
                                style={{ color: '#495057' }}
                              >
                                ({element.key}) {element.value}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <i>* champ obligatoire</i>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <i>** champ obligatoire sous conditions</i>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <div>
                  <button
                    disabled={!!project.archived_at}
                    id={isModifying ? 'button-save' : 'button-edit'}
                    data-cy={isModifying ? 'button-save' : 'button-edit'}
                    className="btn btn-primary no-block"
                    onClick={() => editInformation()}
                  >
                    {isModifying ? 'Enregistrer' : 'Modifier'}
                  </button>

                  <button
                    disabled={!aosProjectId || !!project.archived_at}
                    id={
                      !aosProjectId || !!project.archived_at
                        ? 'button-refresh-project-from-aos-disabled'
                        : 'button-refresh-project-from-aos'
                    }
                    data-cy={
                      !aosProjectId || !!project.archived_at
                        ? 'button-refresh-project-from-aos-disabled'
                        : 'button-refresh-project-from-aos'
                    }
                    className="btn btn-primary no-block ml-2"
                    onClick={() => updateFromAos()}
                  >
                    Mettre à jour depuis AOS
                  </button>
                </div>

                <div>
                  <button
                    hidden={!isModifying}
                    className="btn btn-outline-primary no-block mr-2"
                    id="btn-cancel"
                    data-cy="btn-cancel"
                    onClick={() => resetInformation()}
                  >
                    Annuler
                  </button>
                  <button
                    disabled={!!project.archived_at}
                    className="btn btn-danger no-block"
                    id="btn-delete"
                    data-cy="btn-delete"
                    onClick={() => deleteProject(project.id)}
                  >
                    Archiver
                  </button>
                  <button
                    disabled={!project.archived_at}
                    className="btn btn-danger no-block ml-2"
                    id="btn-delete"
                    data-cy="btn-delete"
                    onClick={() => unrachiveProject(project.id)}
                  >
                    Désarchiver
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="card mt-4">
            <div className="card-header">
              Lots pour le projet <b>{project.name}</b>
            </div>
            <div className="card-body">
              {tradesLoading ? (
                <div className="py-5">
                  <CircularLoader />
                </div>
              ) : (
                <div className="table-responsive p-2" style={{ maxHeight: 450, overflow: 'auto' }}>
                  <table className="table table-bordered table-hover">
                    <thead>
                      <tr>
                        <th
                          className={'border-right-0 font-weight-bolder'}
                          style={{ minWidth: '300px' }}
                        >
                          Nom du lot
                        </th>
                        <th
                          className={'border-right-0 border-left-0 font-weight-bolder'}
                          style={{ minWidth: '160px' }}
                        >
                          Tranche
                        </th>
                        <th
                          className={'border-right-0 border-left-0 font-weight-bolder'}
                          style={{ minWidth: '160px' }}
                        >
                          ID du DPGF
                        </th>
                        <th
                          className={'border-right-0 border-left-0 font-weight-bolder'}
                          style={{ minWidth: '160px' }}
                        >
                          Date Cloture AOS
                        </th>
                        <th
                          className={'border-left-0 font-weight-bolder'}
                          style={{ minWidth: '240px' }}
                        >
                          Affichage sur CP
                        </th>
                      </tr>
                    </thead>
                    {trades &&
                      trades.map((trade) => (
                        <tr key={trade.id}>
                          <td className={'border-right-0'}>
                            <span
                              className={'font-weight-bold'}
                              style={{ color: '161925', fontWeight: 700 }}
                            >
                              {trade.name}
                            </span>
                          </td>
                          <td className={'border-right-0 border-left-0'}>
                            <span>{trade.slice ?? ''}</span>
                          </td>
                          <td className={'border-right-0 border-left-0'}>
                            <span>{trade.dpgf_id}</span>
                          </td>
                          <td className={'border-right-0 border-left-0'}>
                            <span>{trade.closing_trade_at}</span>
                          </td>
                          <td className={'border-left-0'}>
                            {trade.hidden_at || project.archived_at ? (
                              <div>
                                <span
                                  className="badge p-2 mx-3"
                                  style={{
                                    border: '1px solid #D09191',
                                    background: '#EDD6D6',
                                    color: '#704040',
                                    fontFamily: 'Lato',
                                    fontSize: '14px',
                                    fontWeight: 700,
                                    lineHeight: '16px',
                                    letterSpacing: '0em',
                                    textAlign: 'left',
                                  }}
                                >
                                  Masqué
                                </span>
                                <a
                                  id="link-restore-trade"
                                  data-cy="link-restore-trade"
                                  style={{
                                    color: '#213EDB',
                                    fontFamily: 'Lato',
                                    fontSize: '14px',
                                    fontWeight: 700,
                                    lineHeight: '16px',
                                    letterSpacing: '0em',
                                    textAlign: 'left',
                                    textDecoration: 'underline #213EDB',
                                    textUnderlineOffset: '3px',
                                    cursor: 'pointer',
                                    textTransform: 'none',
                                  }}
                                  onClick={() => restoreHideTrade(trade.id, true)}
                                >
                                  Démasquer le lot
                                </a>
                              </div>
                            ) : (
                              <div>
                                <span
                                  className="badge p-2 mx-3"
                                  style={{
                                    border: '1px solid #75C493',
                                    background: '#CBE9D6',
                                    color: '#2E6B45',
                                    fontFamily: 'Lato',
                                    fontSize: '14px',
                                    fontWeight: 400,
                                    lineHeight: '16px',
                                    letterSpacing: '0em',
                                    textAlign: 'left',
                                  }}
                                >
                                  Affiché
                                </span>
                                {!trade.closing_trade_at && (
                                  <a
                                    id="link-delete-trade"
                                    data-cy="link-delete-trade"
                                    style={{
                                      color: '#213EDB',
                                      fontFamily: 'Lato',
                                      fontSize: '14px',
                                      fontWeight: 700,
                                      lineHeight: '16px',
                                      letterSpacing: '0em',
                                      textAlign: 'left',
                                      textDecoration: 'underline #213EDB',
                                      textUnderlineOffset: '3px',
                                      cursor: 'pointer',
                                      textTransform: 'none',
                                    }}
                                    onClick={() => hideTrade(trade.id, true)}
                                  >
                                    Masquer le lot
                                  </a>
                                )}
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                  </table>
                </div>
              )}
            </div>
          </div>

          <div className="card mt-4">
            <div className="card-header">
              Utilisateurs voyant le projet <b>{project.name}</b> comme <b>projet public</b>
            </div>
            <div className="card-body" style={{ maxHeight: 250, overflow: 'auto' }}>
              <div className="form-group">
                Ajouter un utilisateur
                <br />
                <input
                  disabled={!!project.archived_at}
                  type="text"
                  className="form-control"
                  value={searchUserField}
                  onChange={(e) => searchUser(e.target.value)}
                />
                {users.length > 0 && (
                  <div className="list-group">
                    {users.map((user, index) => (
                      <a
                        key={index}
                        className="list-group-item list-group-item-action"
                        onClick={() => createProjectPublic(user.account_id)}
                      >
                        {user.first_name} {user.last_name} ({user.mail})
                      </a>
                    ))}
                  </div>
                )}
              </div>

              <ul className="list-group">
                {projectPublics &&
                  projectPublics.map((projectPublic: IProjectPublic) => (
                    <li
                      key={`public-user-${projectPublic.id}`}
                      className="list-group-item d-flex justify-content-between"
                    >
                      <span>
                        {projectPublic.account.main_user.first_name}{' '}
                        {projectPublic.account.main_user.last_name} (
                        {projectPublic.account.main_user.mail})
                      </span>
                      <span
                        className="badge badge-danger"
                        onClick={() => deleteProjectPublic(projectPublic.id)}
                      >
                        Supprimer
                      </span>
                    </li>
                  ))}
              </ul>
            </div>
          </div>

          <div className="card mt-4 mb-4">
            <div className="card-header">Utilisateurs ayant demandé l&apos;accès au DCE</div>
            <div className="card-body">
              <ul className="list-group">
                {projectRequests.map((projectRequest: IProjectRequest) => (
                  <li
                    key={`project-request-${projectRequest.id}`}
                    className="list-group-item d-flex justify-content-between"
                  >
                    <ul>
                      {projectRequest.account.users.map((user: IUser) => (
                        <li key={`project-request-user-${user.id}`}>
                          <a href={`/admin/utilisateurs/${user.id}`}>{user.mail}</a> a demandé le{' '}
                          <b>{projectRequest.created_at}</b>
                        </li>
                      ))}
                    </ul>
                    {getStatus(projectRequest)}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Project;
