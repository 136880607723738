import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Pagination from '../../common/navigation/Pagination';
import CircularLoader from '../../common/navigation/CircularLoader';
import Info from '../navigation/Info';
import { IAosProject } from '../../interfaces/IAosProject';
import Modal from '../../common/Modal';
import ErrorMessage from '../../common/errors/ErrorMessage';
import { useHistory } from 'react-router-dom';
import { AOSOps } from '../../constants/AOSOps';
import { IAOSOps } from '../../interfaces/IAOSOps';
import departments from '../../components/settings/preferences/departments.json';

import { PROJECTS_SEARCH_QUERY_KEY } from '../global-keys';
import { BACKEND_URL } from '../../config//environment';
import moment from 'moment-timezone';

/**
 * Load projects and show them in a responsive table.
 */
const ProjectsList: React.FC = () => {
  const projectSearchQueryValue = JSON.parse(localStorage.getItem(PROJECTS_SEARCH_QUERY_KEY));

  const [projects, setProjects] = useState<Array<IProject>>();
  const [lastPage, setLastPage] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useState<string>(
    projectSearchQueryValue?.searchQuery || ''
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [addProjectModal, setAddProjectModal] = useState<boolean>(false);
  const [aosProjectId, setAosProjectId] = useState<string>();
  const [isProjectFromAos, setIsProjectFromAos] = useState(false);
  const [project, setProject] = useState<IAosProject>({
    is_flash: false,
    is_coming: false,
    construction_type: 'Logements',
    has_valid_image: true,
    states_broadcast: [],
  });
  // SELECTS OPTIONS
  interface selectOptions {
    key: string;
    value: string;
  }
  const [departmentValues, setDepartmentValues] = useState<selectOptions[]>();
  
  const [error, setError] = useState<string>(null);
  const [currentPage, setCurrentPage] = useState<number>(projectSearchQueryValue?.currentPage || 1);
  const [existingProjectId, setExistingProjectId] = useState<string>();
  const history = useHistory();


  useEffect(() => {
    if (!projects) {
    /**
     * Load projects first time.
     */
      changePage(currentPage);
    }

    axios
      .get(`${BACKEND_URL}/api/admin/filters?filters[]=states`)
      .then(({ data }) => {
        constructSelectStateOptions(data);
      });
  }, []);

  const constructSelectStateOptions = (data: any) => {
    if (data.states) {
      const dep: selectOptions[] = data.states.map((el: any) => {
        return { key: el.id, value: el.name } as selectOptions;
      });
      setDepartmentValues(dep);
    }
  };

  /**
   * Load projects in a specific page with a query string filter.
   * @param page Current page.
   */
  const changePage = (page: number) => {
    setLoading(true);

    axios
      .get<IPaginateProject>(
        `${BACKEND_URL}/api/admin/projects?query=${encodeURIComponent(searchQuery)}&page=${page}`
      )
      .then(({ data }) => {
        setProjects(data.projects.data);
        setLastPage(data.projects.last_page);
        setCurrentPage(data.projects.current_page);
        const newSearchQueryValue = JSON.stringify({
          currentPage: data.projects.current_page,
          searchQuery,
        });
        localStorage.setItem(PROJECTS_SEARCH_QUERY_KEY, newSearchQueryValue);
        setTotal(data.projects.total);
        setLoading(false);
      });
  };
  /**
   * Export all projects or projects in a specific page with a query string filter.
   * @param page Current page.
   */
  const exportProjects = (selectedProjects: boolean | null) => {
    setExportLoading(true);
    let endpoint = `${BACKEND_URL}/api/admin/projects/export`;
    if (selectedProjects) endpoint += `?query=${encodeURIComponent(searchQuery)}`;

    axios.get<IExportProjects>(endpoint).then(() => {
      setExportLoading(false);
      history.push(`/admin/exports`);
    });
  };

  /**
   * Triggered when the user press a touch to detect if it is "Enter". It it is, is starts a search.
   * @param key Key pressed.
   */
  const searchProject = (key: string) => {
    if (key === 'Enter' || searchQuery === '') {
      setLastPage(1);
      changePage(1);
    }
  };

  /**
   * Open modal to add a new project
   * @param open Is modal open.
   */
  const openAddProjectModal = () => {
    setAddProjectModal(true);
  };

  /**
   * Get project from AOS
   */
  const getProjectFromAos = () => {
    if (aosProjectId) {
      setError('');
      axios
        .get(`${BACKEND_URL}/api/admin/projects/aos/${aosProjectId}`)
        .then(({ data }) => {
          setProject(data.project);
          setIsProjectFromAos(true);
        })
        .catch(() => {
          setProject({
            is_flash: false,
            is_coming: false,
            construction_type: 'Logements',
            has_valid_image: true,
            states_broadcast: [],
          });
          setError("L'identifiant AOS est invalide.");
        });
    }
  };

  const handleProjectUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setProject({ ...project, [name]: e.target.type === 'date' ? fromDateInput(value) : value });
  };

  const handleSelectProjectUpdate = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name } = e.target;

    const value =
      name === 'is_coming' || name === 'is_flash' || name === 'is_external'
        ? e.target.value === '1'
        : e.target.value;

    setProject({ ...project, [name]: value });
  };

  const handleDescriptionUpdate = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setProject({ ...project, description: e.target.value });
  };

  const handleLandingDescriptionUpdate = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setProject({ ...project, landing_description: e.target.value });
  };

  const addProject = () => {
    setError('');
    axios
      .post(`${BACKEND_URL}/api/admin/projects/`, project)
      .then(({ data }) => {
        setAddProjectModal(false);
        history.push(`/admin/projets/${data.project.id}`);
      })
      .catch((err) => {
        const dataErr = err.response.data;

        console.log(
            dataErr?.errors,
            dataErr?.errors?.aos_project_id
        );
        if (dataErr?.errors?.aos_project_id) {
          const uniqueError = dataErr.errors.aos_project_id
              .filter((projectErr: any) => projectErr.type === 'unique')[0];

          if (uniqueError) {
            setExistingProjectId(uniqueError.id);

            return;
          }
        }

        const error = err?.response?.data?.errorMessage;
        setError(error ? error : 'Une erreur est survenue.');
      });
  };

  const toDateInput = (date: string): string => (date ? date.split('/').reverse().join('-') : '');
  const fromDateInput = (date: string): string => date.split('-').reverse().join('/');

  //Set search value
  const onSetSearchQuery = (value: string) => {
    setSearchQuery(value);
    const newSearchQueryValue = JSON.stringify({ searchQuery: value, currentPage });
    localStorage.setItem(PROJECTS_SEARCH_QUERY_KEY, newSearchQueryValue);
  };

  const inputFormattedDate = (date: string): string => {
    if (!date) {
      return '';
    }

    const timezone: string = moment.tz.guess();
    return moment(date).tz(timezone).format('YYYY-MM-DD');
  };

  return (
    <>
      <Modal hidden={addProjectModal} maxWidth={600} closeModal={() => setAddProjectModal(false)}>
        <div className="card card-body" style={{ height: '100%', overflow: 'auto', padding: 30 }}>
          <h1 className="mb-3">Ajouter un nouveau projet</h1>
          {error && <ErrorMessage text={error} />}
          {existingProjectId && (
            <div className="alert alert-warning p-2 mt-5 text-center">
              <div>Ce projet existe déjà sur Chantier Privé.</div>
              <Link to={`/admin/projets/${existingProjectId}`}>
                Voir le projet
              </Link>
            </div>
          )}
          <div className="d-flex align-items-center pl-0 pr-0 py-3">
            <input
              className="w-75 m-0 form-control"
              type="text"
              id="text-aos-project-id"
              data-cy="text-aos-project-id"
              placeholder="Identifiant du projet AOS"
              onChange={(e) => setAosProjectId(e.target.value)}
            />
            <button
              className="w-25 btn btn-primary p-1 m-1 ml-2"
              id="btn-import"
              onClick={() => getProjectFromAos()}
            >
              Importer
            </button>
          </div>

          <div className="alert alert-warning mt-1 mb-1" hidden={project.has_valid_image}>
            L&apos;image du projet importée n&apos;est pas valide. Elle sera remplacée par une image
            par défaut.
          </div>

          <div className="pb-3 pl-0 pr-0 pt-0">
            <div className="row pb-0 pt-0 mt-2 mb-1">
              <label className="p-0 col-3 font-weight-bold m-0 ">Chargé de clientèle</label>
              <select
                name="ops_id"
                data-cy="ops-id"
                defaultValue=""
                value={project.ops_id || ''}
                onChange={handleSelectProjectUpdate}
                className="m-0 pl-2 col-9 form-control"
              >
                <option value="">Aucun</option>
                {AOSOps.map((op: IAOSOps) => (
                  <option value={op.id} key={op.id}>
                    {op.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="row pb-0 pt-0 mt-2 mb-1">
              <label className="p-0 col-3 font-weight-bold m-0 ">Flash*</label>
              <select
                name="is_flash"
                data-cy="is-flash"
                className="m-0 pl-2 col-9 form-control"
                value={project.is_flash ? '1' : '0'}
                defaultValue="0"
                onChange={handleSelectProjectUpdate}
              >
                <option value="1">Oui</option>
                <option value="0">Non</option>
              </select>
            </div>

            <div className="row pb-0 pt-0 mt-2 mb-1">
              <label className="p-0 col-3 font-weight-bold m-0 ">Disponible publiquement*</label>
              <select
                name="is_external"
                data-cy="is-external"
                className="m-0 pl-2 col-9 form-control"
                value={project.is_external ? '1' : '0'}
                defaultValue="0"
                onChange={handleSelectProjectUpdate}
              >
                <option value="1">Oui</option>
                <option value="0">Non</option>
              </select>
            </div>

            <div className="row pb-0 pt-0 mt-2 mb-1">
              <label className="p-0 col-3 font-weight-bold m-0">Départements de diffusion</label>
              <select
                name="states_broadcast"
                data-cy="states-broadcast-id"
                value={project.states_broadcast}
                multiple
                onChange={(e) => setProject({
                    ...project,
                    ['states_broadcast']: Array.from(e.target.selectedOptions, (o) => o.value)
                  })
                }
                className="m-0 pl-2 col-9 form-control"
              >
                <option value="" selected disabled hidden>
                  Choisir les départements
                </option>
                {departmentValues &&
                  departmentValues.map((element) => (
                    <option
                      key={element.key}
                      value={element.key}
                    >
                      ({element.key}) {element.value}
                    </option>
                  ))}
              </select>
            </div>

            <div className="row pb-0 pt-0 mt-2 mb-1">
              <label className="p-0 col-3 font-weight-bold m-0 ">Projet à venir*</label>
              <select
                name="is_coming"
                data-cy="is-coming"
                className="m-0 pl-2 col-9 form-control"
                value={project.is_coming ? '1' : '0'}
                onChange={handleSelectProjectUpdate}
              >
                <option value="1">Oui</option>
                <option value="0">Non</option>
              </select>
            </div>

            <div className="row pb-0 pt-0 mt-2 mb-1">
              <label className="p-0 col-3 font-weight-bold m-0 ">Email DO**</label>
              <input
                type="text"
                name="purchaser_mail"
                data-cy="purchaser-mail"
                className="m-0 pl-2 col-9 form-control"
                value={project.purchaser_mail ?? ''}
                onChange={handleProjectUpdate}
              />
            </div>

            <div className="row pb-0 pt-0 mt-2 mb-1">
              <label className="p-0 col-3 font-weight-bold m-0 ">Nom DO</label>
              <input
                type="text"
                name="purchaser_name"
                data-cy="purchaser-name"
                className="m-0 pl-2 col-9 form-control"
                value={project.purchaser_name ?? ''}
                onChange={handleProjectUpdate}
                disabled={isProjectFromAos}
              />
            </div>

            <div className="row pb-0 pt-0 mt-2 mb-1">
              <label className="p-0 col-3 font-weight-bold m-0 ">Nom du projet*</label>
              <input
                type="text"
                name="name"
                data-cy="name"
                className="m-0 pl-2 col-9 form-control"
                value={project.name ?? ''}
                onChange={handleProjectUpdate}
                disabled={isProjectFromAos}
              />
            </div>

            <div className="row pb-0 pt-0 mt-2 mb-1">
              <label className="p-0 col-3 font-weight-bold m-0 ">Description</label>
              <textarea
                style={{ height: 115 }}
                name="description"
                data-cy="description"
                className="m-0 pl-2 col-9 form-control"
                value={project.description ?? ''}
                onChange={handleDescriptionUpdate}
                disabled={isProjectFromAos}
              />
            </div>

            <div className="row pb-0 pt-0 mt-2 mb-1">
              <label className="p-0 col-3 font-weight-bold m-0 ">Description Landing</label>
              <textarea
                style={{ height: 115 }}
                name="landing_description"
                data-cy="landing_description"
                className="m-0 pl-2 col-9 form-control"
                value={project.landing_description ?? project.description}
                onChange={handleLandingDescriptionUpdate}
              />
            </div>

            <div className="row pb-0 pt-0 mt-2 mb-1">
              <label className="p-0 col-3 font-weight-bold m-0 ">Code postal</label>
              <input
                type="text"
                name="zip_code"
                data-cy="zip-code"
                className="m-0 pl-2 col-9 form-control"
                value={project.zip_code ?? ''}
                onChange={handleProjectUpdate}
              />
            </div>

            <div className="row pb-0 pt-0 mt-2 mb-1">
              <label className="p-0 col-3 font-weight-bold m-0 ">Numéro de Département</label>
              <select
                className="m-0 pl-2 col-9 form-control"
                name="state"
                value={project.state ?? ''}
                data-cy="state"
                onChange={handleSelectProjectUpdate}
              >
                <option value="">Aucun</option>
                {departments.map((department, index) => (
                  <option key={index} value={department.number}>
                    {department.number}
                  </option>
                ))}
              </select>
            </div>

            <div className="row pb-0 pt-0 mt-2 mb-1">
              <label className="p-0 col-3 font-weight-bold m-0 ">Ville</label>
              <input
                type="text"
                data-cy="city"
                name="city"
                className="m-0 pl-2 col-9 form-control"
                value={project.city ?? ''}
                onChange={handleProjectUpdate}
              />
            </div>

            <div className="row pb-0 pt-0 mt-2 mb-1">
              <label className="p-0 col-3 font-weight-bold m-0 ">Adresse</label>
              <input
                type="text"
                name="address"
                data-cy="address"
                className="m-0 pl-2 col-9 form-control"
                value={project.address ?? ''}
                onChange={handleProjectUpdate}
              />
            </div>

            <div className="row pb-0 pt-0 mt-2 mb-1">
              <label className="p-0 col-3 font-weight-bold m-0 ">
                Début d&apos;appel d&apos;offres
              </label>
              <input
                type="date"
                name="begin_of_offering_date"
                className="m-0 pl-2 col-9 form-control"
                value={inputFormattedDate(project.begin_of_offering_date)}
                disabled={true}
              />
            </div>

            <div className="row pb-0 pt-0 mt-2 mb-1">
              <label className="p-0 col-3 font-weight-bold m-0 ">
                Fin d&apos;appel d&apos;offres
              </label>
              <input
                type="date"
                name="end_of_offering_date"
                className="m-0 pl-2 col-9 form-control"
                value={inputFormattedDate(project.end_of_offering_date)}
                disabled={true}
              />
            </div>

            <div className="row pb-0 pt-0 mt-2 mb-1">
              <label className="p-0 col-3 font-weight-bold m-0 ">Début des travaux</label>
              <input
                type="date"
                name="building_site_start_date"
                className="m-0 pl-2 col-9 form-control"
                value={inputFormattedDate(project.building_site_start_date)}
                disabled={true}
              />
            </div>

            <div className="row pb-0 pt-0 mt-2 mb-1">
              <label className="p-0 col-3 font-weight-bold m-0 ">Fin des travaux</label>
              <input
                type="date"
                name="building_site_end_date"
                className="m-0 pl-2 col-9 form-control"
                value={inputFormattedDate(project.building_site_end_date)}
                disabled={true}
              />
            </div>

            <div className="row pb-0 pt-0 mt-2 mb-1">
              <label className="p-0 col-3 font-weight-bold m-0 ">Longitude</label>
              <input
                type="text"
                name="longitude"
                className="m-0 pl-2 col-9 form-control"
                value={project.longitude}
                onChange={handleProjectUpdate}
              />
            </div>

            <div className="row pb-0 pt-0 mt-2 mb-1">
              <label className="p-0 col-3 font-weight-bold m-0 ">Latitude</label>
              <input
                type="text"
                name="latitude"
                className="m-0 pl-2 col-9 form-control"
                value={project.latitude}
                onChange={handleProjectUpdate}
              />
            </div>

            <div className="row pb-0 pt-0 mt-2 mb-1">
              <label className="p-0 col-3 font-weight-bold m-0 ">Type de construction*</label>
              <select
                value={project.construction_type ?? ''}
                name="construction_type"
                data-cy="construction-type"
                onChange={handleSelectProjectUpdate}
                className="m-0 pl-2 col-9 form-control"
              >
                <option value="Logements">Logements</option>
                <option value="Grandes surfaces">Grandes surfaces</option>
                <option value="Établissements de santé">Établissements de santé</option>
                <option value="Tertiaire">Tertiaire</option>
                <option value="Autre">Autre</option>
              </select>
            </div>

            <button
              id="button-add-project"
              data-cy="button-add-project"
              className="w-100 btn btn-primary mt-4"
              onClick={() => addProject()}
            >
              Ajouter à chantier privé
            </button>

            <div>
              <i>* champ obligatoire</i>
              <br />
              <i>** champ obligatoire sous conditions</i>
            </div>
          </div>
        </div>
      </Modal>

      <div className="alert alert-info d-flex justify-content-between">
        Retrouvez ici la liste de tous les projets étant disponibles sur Chantier Privé.
        <div className="dropdown">
          {!exportLoading && (
            <button
              className="btn-sm btn-primary dropdown-btn dropdown-toggle"
              type="button"
              id="exportDropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Exporter
            </button>
          )}

          {exportLoading && (
            <button className="btn btn-primary">
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </button>
          )}
          <div className="dropdown-menu" aria-labelledby="exportDropdownMenuButton">
            <a className="dropdown-item" href="#" onClick={() => exportProjects(true)}>
              Exporter la sélection
            </a>
            <a className="dropdown-item" href="#" onClick={() => exportProjects(null)}>
              Exporter tous les projets
            </a>
          </div>
        </div>
      </div>

      <div className="card card-body">
        <div className="row">
          <div className="col-sm-5 col-lg-7">
            <input
              type="text"
              id="text-field-search"
              data-cy="text-field-search"
              className="form-control auth-input mt-0"
              placeholder="Rechercher un projet par nom, email DO ou par lot avec @nom du lot"
              autoComplete="off"
              value={searchQuery}
              onChange={(e) => onSetSearchQuery(e.target.value)}
              onKeyUp={(e) => searchProject(e.key)}
            />
          </div>

          <div className="col-sm-3 col-lg-2 px-1 py-1">
            <button className="btn btn-primary" onClick={() => changePage(1)}>
              Rechercher
            </button>
          </div>

          <div className="col-sm-4 col-lg-3 px-1 py-1">
            <button className="btn btn-primary" id="btn-add" onClick={() => openAddProjectModal()}>
              Ajouter un projet
            </button>
          </div>
        </div>

        {loading && <CircularLoader />}

        {!loading && projects && projects.length === 0 && (
          <Info text="Aucun projet n'a été trouvé." />
        )}

        {!loading && projects && projects.length > 0 && (
          <div className="table-responsive">
            <div className="alert alert-info mt-1 mb-1">
              {total} {total > 1 ? 'projects trouvés.' : 'projet trouvé.'}
            </div>
            <table className="table table-hover table-bordered mt-2">
              <thead>
                <tr>
                  <th scope="col">Nom du projet</th>
                  <th scope="col">DO</th>
                  <th scope="col">Demandes DCE</th>
                  <th scope="col">Localisation</th>
                  <th scope="col">Type de construction</th>
                  <th scope="col">Lots</th>
                  <th scope="col">Gérer</th>
                </tr>
              </thead>
              <tbody style={{ verticalAlign: 'top' }}>
                {projects &&
                  projects.length > 0 &&
                  projects.map((project: IProject) => (
                    <tr key={project.id}>
                      <td style={{ color: project.archived_at ? 'red' : 'black' }}>
                        {project.name}
                        <span>{project.archived_at ? ' (Archivé)' : ''}</span>
                      </td>
                      <td>{project.purchaser_name}</td>
                      <td>{project.project_requests.length} demande(s)</td>
                      <td>
                        {project.zip_code} {project.city}
                      </td>
                      <td>{project.construction_type}</td>
                      <td>
                        <ul>
                          {project.trades &&
                            project.trades.map((lot) => <li key={lot.dpgf_id}>{lot.name}</li>)}
                        </ul>
                      </td>
                      <td>
                        <Link
                          id={
                            project.archived_at ? 'button-display-project' : 'button-edit-project'
                          }
                          data-cy={
                            project.archived_at ? 'button-display-project' : 'button-edit-project'
                          }
                          to={`/admin/projets/${project.id}`}
                          className="selector-link-manage"
                        >
                          {project.archived_at ? 'Afficher' : 'Gérer'}
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {lastPage > 0 && (
        <Pagination lastPage={lastPage} currentPage={currentPage} toCall={changePage} />
      )}
    </>
  );
};

export default ProjectsList;
