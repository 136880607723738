import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router';
import ErrorBox from '../../../common/errors/ErrorBox';
import ErrorMessage from '../../../common/errors/ErrorMessage';

import CircularLoader from '../../../common/navigation/CircularLoader';
import Success from '../../navigation/Success';
import { Link } from 'react-router-dom';
import PhoneInput from '../../../common/navigation/PhoneInput';
import { Sales } from '../../../constants/Sales';
import { ISales } from '../../../interfaces/ISales';
import { BACKEND_URL } from '../../../config/environment';
import UserManagementV2 from './UserManagement_V2';
import PricingFormV2 from './PricingForm_V2';
import PaymentError from './PaymentError';
import {
  findPositionTechnicalValueByLabel,
  findPositionValueByTechnicalLabel,
  formatDate,
  positionValues,
} from '../../../constants';
import Info from '../../navigation/Info';

/**
 * Load companies and show them in a responsive table.
 */
const UserV2: React.FC = () => {
  const [user, setUser] = useState<IUser>(null);

  const [projectRequests, setProjectRequests] = useState<Array<IProjectRequest>>(null);

  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<string>(null);
  const [errorMessage, setErrorMessage] = useState<string>(null);
  const [isModifying, setIsModifying] = useState<boolean>(false);

  const [first_name, setFirstName] = useState<string>('');
  const [last_name, setLastName] = useState<string>('');
  const [mail, setMailAddress] = useState<string>('');
  const [contactAos, setContactAos] = useState<string>('');
  const [position, setPosition] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [secondaryPhone, setSecondaryPhone] = useState<string>('');
  const [phoneCode, setPhoneCode] = useState<string>(null);
  const [secondaryPhoneCode, setSecondaryPhoneCode] = useState<string>(null);
  const [stripeCustomerId, setStripeCustomerId] = useState<string>('');
  const [customerCredit, setCustomerCredit] = useState<number>(0);
  const [password, setPassword] = useState<string>('');
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');
  const [salesId, setSalesId] = useState<string>(null);

  const { userId }: { userId: string } = useParams();

  const [displayPrices, setDisplayPrices] = useState<boolean>(false);
  const [price, setPrice] = useState<number>(0);
  const [priceAfterCoupon, setPriceAfterCoupon] = useState<number>(0);
  const [frequency, setFrequency] = useState<string>('');
  const [renewalPrice, setRenewalPrice] = useState<number>(0);
  const [renewalpriceAfterCoupon, setRenewalPriceAfterCoupon] = useState<number>(0);
  const [renewalFrequency, setRenewalFrequency] = useState<string>('');

  const [offer, setOffer] = useState(undefined);
  const [activeOffer, setActiveOffer] = useState(undefined);
  const [isPendingCreated, setIsPendingCreated] = useState(false);
  /**
   * Load the company.
   */
  useEffect(() => {
    if (!user) {
      loadUser();
      fetchOffer();
    }
  }, [user]);

  const processOffers = (data: any) => {
    let offer = data.find((e: any) => e.status === 'payment_coming' && e.offer_version === 2);
    if (!offer) {
      offer = data.find((e: any) => e.status === 'pending' && e.offer_version === 2);
    }
    const activeOffer = data.find((e: any) => e.status === 'active');

    if (!offer) setOffer(undefined);
    else {
      setOffer(offer);
    }
    if (!activeOffer) setActiveOffer(undefined);
    else {
      setActiveOffer(activeOffer);
    }
  };

  const pricingOffer = () => {
    if (offer) return offer;
    else if (activeOffer && activeOffer.offer_version === 2) return activeOffer;
    else return undefined;
  };

  const fetchOffer = () => {
    axios.get(`${BACKEND_URL}/api/admin/users/${userId}/formulas`).then(({ data }) => {
      processOffers(data.offers);
    });
  };

  const loadUser = () => {
    axios.get(`${BACKEND_URL}/api/admin/users/${userId}`).then(({ data }) => {
      if (!data.user) {
        setError(true);
        return;
      }

      data.user.managers = data.managers;
      setUser(data.user);
      setProjectRequests(data.projectRequests);
      setFirstName(data.user.first_name);
      setLastName(data.user.last_name);
      setMailAddress(data.user.mail);
      setContactAos(data.user.contact_aos);
      setPhone(data.user.phone);
      setPhoneCode(data.user.phone_country_code);
      setSecondaryPhone(data.user.secondary_phone);
      setSecondaryPhoneCode(data.user.phone_country_code);
      setPosition(data.user.position && findPositionValueByTechnicalLabel(data.user.position));
      setStripeCustomerId(data.user.account?.stripe_id);
      setCustomerCredit(data.credit);
      setSalesId(data.user.account.sales_id);
    });
  };

  const editInformation = () => {
    setSuccess(null);
    setErrorMessage(null);

    if (isModifying) {
      axios
        .post(`${BACKEND_URL}/api/admin/users/${userId}`, {
          first_name,
          last_name,
          mail,
          phone,
          phone_country_code: phoneCode,
          stripe_customer_id: stripeCustomerId,
          credit: customerCredit,
          sales_id: salesId,
          secondary_phone: secondaryPhone,
          position: findPositionTechnicalValueByLabel(position),
          contact_aos: contactAos,
        })
        .then(() => {
          loadUser();
          setSuccess(`L'utilisateur a bien été modifié.`);
          setIsModifying(!isModifying);
        })
        .catch((err) => {
          const error = err?.response?.data?.errorMessage;
          setErrorMessage(error ? error : 'Une erreur est survenue.');
        });
    } else {
      setIsModifying(!isModifying);
    }
  };

  const resetInformation = () => {
    setFirstName(user.first_name);
    setLastName(user.last_name);
    setMailAddress(user.mail);
    setContactAos(user.contact_aos);
    setPhone(user.phone);
    setPhoneCode(user.phone_country_code);
    setStripeCustomerId(user?.account?.stripe_id);
    setSecondaryPhone(user.secondary_phone);
    setSecondaryPhoneCode(user.phone_country_code);
    setPosition(user.position && findPositionValueByTechnicalLabel(user.position));

    setIsModifying(false);
  };

  /**
   * Activate the email address of the user.
   */
  const activateEmail = () => {
    if (
      confirm(
        'Attention: Cette action est irréversible. Êtes vous sûr(e) de vouloir confirmer cette adresse e-mail ?'
      )
    ) {
      axios.post(`${BACKEND_URL}/api/admin/users/${user.id}/activate-email`).then(({ data }) => {
        loadUser();
      });
    }
  };

  /**
   * Add or remove the admin profile.
   */
  const editAdminStatus = () => {
    setErrorMessage(null);

    if (
      confirm(
        `Attention : Cette opération touche à l'accès aux données de toute la plateforme. Êtes vous sûr(e) ?`
      )
    ) {
      axios
        .post(`${BACKEND_URL}/api/admin/users/${user.id}/set-is-admin`)
        .then(() => {
          loadUser();
        })
        .catch((err) => {
          const error = err?.response?.data?.errorMessage;
          setErrorMessage(error ? error : 'Une erreur est survenue.');
        });
    }
  };

  /**
   * Edit the user password.
   */
  const editPassword = () => {
    if (password && passwordConfirmation && password === passwordConfirmation) {
      axios
        .post(`${BACKEND_URL}/api/admin/users/${user.id}/password`, {
          password: password,
          password_confirmation: passwordConfirmation,
        })
        .then(() => {
          setSuccess('Le mot de passe a bien été modifié.');
          setPassword('');
          setPasswordConfirmation('');
        })
        .catch((err) => {
          const error = err?.response?.data?.errorMessage;
          setErrorMessage(error ? error : 'Une erreur est survenue.');
        });
    }
  };

  const removePendingOffer = () => {
    if (confirm(`Attention : Cette opération va supprimer l'offre proposée. Êtes vous sûr(e) ?`)) {
      axios
        .delete(`${BACKEND_URL}/api/admin/users/${user.id}/offer`)
        .then(() => {
          setSuccess("L'offre proposée a bien été supprimée");
          fetchOffer();
        })
        .catch(() => setErrorMessage('Une erreur est survenue.'));
    }
  };

  const isCollaborator = () => {
    return user.profile === 0 && user.role?.role === 'collaborator';
  };

  const activateOffer = () => {
    axios
      .post(`${BACKEND_URL}/api/admin/users/${user.id}/offers/${offer.id}/activate`)
      .then(() => {
        setSuccess("L'abonnement a bien été activé.");
        loadUser();
        fetchOffer();
      })
      .catch(() => setErrorMessage("Une erreur est survenue lors de l'activation de l'offre."));
  };

  const calculatePrices = (
    price: number,
    priceAfterCoupon: number,
    frequency: string,
    renewalPrice: number,
    renewalPriceAfterCoupon: number,
    renewalFrequency: string,
    isPendingCreated: boolean
  ) => {
    setPrice(price);
    setPriceAfterCoupon(priceAfterCoupon);
    setFrequency(frequency);
    setRenewalPrice(renewalPrice);
    setRenewalPriceAfterCoupon(renewalPriceAfterCoupon);
    setRenewalFrequency(renewalFrequency);
    setIsPendingCreated(isPendingCreated);
  };

  const displayPrice = (display: boolean) => {
    setDisplayPrices(display);
  };

  /**
   * Resend subscription mail to the user.
   * @param id ID of the user.
   */
  const resendEmail = () => {
    axios.post(`${BACKEND_URL}/api/admin/users/${user.id}/choose-password`).then(({ data }) => {
      loadUser();
    });
  };

  const defaultSubscription = !activeOffer && user?.account?.subscriptions?.find((sub: { name: string; }) => sub.name === 'default');
  const shouldShowCanceledMessage = defaultSubscription && defaultSubscription.ends_at !== null;

  return (
    <>
      {!error && !user && <CircularLoader />}
      {error && <ErrorBox text="Désolé, cet utilisateur n'existe pas" link="/admin/utilisateurs" />}
      {success && <Success text={success} onHide={() => setSuccess(null)} />}

      {user && (
        <>
          <PaymentError user={user} activeOffer={activeOffer} pendingOffer={offer} />
          <div className="row">
            <div className="col-lg-6">
              <div className="card">
                <div className="card-header">
                  Profil CP V2 de l&apos;utilisateur{' '}
                  <b>
                    {first_name} {last_name}
                  </b>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    {user.invitation_token && (
                      <div className="alert alert-danger mb-0">Non inscrit</div>
                    )}
                    <div className="row">
                      <div className="col-lg-6">
                        <input
                          type="text"
                          data-cy="first-name-admin"
                          placeholder="Prénom"
                          className="form-control"
                          autoComplete="off"
                          value={first_name ?? ''}
                          onChange={(e) => setFirstName(e.target.value)}
                          disabled={!isModifying}
                        />
                      </div>

                      <div className="col-lg-6">
                        <input
                          type="text"
                          data-cy="last-name-admin"
                          placeholder="Nom"
                          className="form-control"
                          autoComplete="off"
                          value={last_name ?? ''}
                          onChange={(e) => setLastName(e.target.value)}
                          disabled={!isModifying}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <input
                      type="email"
                      data-cy="mail-admin"
                      placeholder="Email"
                      className="form-control"
                      autoComplete="off"
                      value={mail}
                      onChange={(e) => setMailAddress(e.target.value)}
                      disabled={!isModifying}
                    />
                  </div>

                  <div className="form-group">
                    <label>Adresse e-mail AOS</label>
                    <input
                      type="email"
                      data-cy="mail-admin"
                      placeholder="Email"
                      className="form-control"
                      autoComplete="off"
                      value={contactAos}
                      onChange={(e) => setContactAos(e.target.value)}
                      disabled={!isModifying}
                    />
                  </div>

                  <div className="form-group">
                    <PhoneInput
                      value={{ phone: phone, code: phoneCode }}
                      onPhoneChange={(e) => setPhone(e.target.value)}
                      onCodeClick={(e) => setPhoneCode(e.currentTarget.value)}
                      disabled={!isModifying}
                    />
                  </div>

                  <div className="form-group">
                    <label>Téléphone secondaire</label>
                    <PhoneInput
                      value={{ phone: secondaryPhone, code: secondaryPhoneCode }}
                      onPhoneChange={(e) => setSecondaryPhone(e.target.value)}
                      onCodeClick={(e) => setSecondaryPhoneCode(e.currentTarget.value)}
                      disabled={!isModifying}
                    />
                  </div>

                  <div className="form-group">
                    <label>Poste au sein de l&apos;entreprise</label>
                    <select
                      value={position}
                      onChange={(e) => setPosition(e.target.value)}
                      className="form-control"
                      disabled={!isModifying}
                    >
                      <option value="" selected disabled hidden>
                        Poste au sein de l&apos;entreprise
                      </option>
                      {positionValues.map((element) => (
                        <option
                          key={element.value}
                          value={element.value}
                          style={{ color: '#495057' }}
                        >
                          {element.value}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group">
                    <div className="alert alert-danger">
                      Attention : Cette information peut empecher le renouvellement
                      d&apos;abonnements. Merci de ne modifier que si vous êtes sûr(e).
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      autoComplete="off"
                      placeholder="Stripe customer ID"
                      value={stripeCustomerId ?? ''}
                      onChange={(e) => setStripeCustomerId(e.target.value)}
                      disabled={!isModifying}
                    />
                  </div>

                  <div className="form-group">
                    <label>Crédit en euros</label>
                    <input
                      type="number"
                      step="0.01"
                      className="form-control"
                      autoComplete="off"
                      placeholder="Crédit"
                      value={customerCredit ?? 0}
                      onChange={(e) => setCustomerCredit(Number.parseFloat(e.target.value))}
                      disabled={!isModifying}
                    />
                  </div>
                  <div className="form-group">
                    <label>Support contacté</label>
                    <select
                      name="sales_id"
                      data-cy="sales-id"
                      defaultValue=""
                      value={salesId || ''}
                      onChange={(e) =>
                        e.target.value === 'Aucun' ? setSalesId(null) : setSalesId(e.target.value)
                      }
                      disabled={!isModifying}
                      className="form-control"
                    >
                      <option value="0">Aucun</option>
                      {Sales.map((op: ISales) => (
                        <option value={op.id} key={op.id}>
                          {op.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  {errorMessage && <ErrorMessage text={errorMessage} />}

                  <div className="d-flex justify-content-between">
                    <button
                      className="btn btn-primary no-block mr-0"
                      onClick={() => editInformation()}
                      id="btn-save"
                      data-cy="btn-save"
                    >
                      {isModifying ? 'Enregistrer' : 'Modifier'}
                    </button>
                    <button
                      className="btn btn-outline-primary no-block"
                      hidden={!isModifying}
                      onClick={() => resetInformation()}
                      id="btn-cancel"
                      data-cy="btn-cancel"
                    >
                      Annuler
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="card">
                <div className="card-header">Informations supplémentaires</div>
                <div className="card-body">
                  {!user.preference ? (
                    <div className="alert alert-info">Aucune information récoltée</div>
                  ) : (
                    <>
                      <p>Utilisateur AOS: {user.account.mongo_id ? 'Oui' : 'Non'}</p>
                      <div className="alert alert-info">
                        Ces informations constituent les critères de recherche de
                        l&apos;utilisateur.
                      </div>
                      <p style={{ fontWeight: 'bold' }}>Type de construction :</p>
                      <p>{user.preference.construction_types.join(', ')}</p>
                      <p style={{ fontWeight: 'bold' }}>Zone géographique :</p>
                      <p>
                        {user.preference.states
                          .map((e) => {
                            return `(${e.number}) ${e.name}`;
                          })
                          .join(', ')}
                      </p>
                      <p style={{ fontWeight: 'bold' }}>Lots / CET :</p>
                      <p>
                        {user.preference.trade_category_references
                          .map((e) => {
                            return e.name;
                          })
                          .join(', ')}
                      </p>
                    </>
                  )}

                  <div style={{ height: '1px', backgroundColor: '#D0D1D3' }} className="mt-3"></div>
                  <h5 className="mt-3 mb-2">Entreprise</h5>
                  <Link
                    to={`/admin/entreprises/${user.account.company_id}`}
                    style={{ textTransform: 'none' }}
                  >
                    Accéder à la fiche de l&apos;entreprise
                  </Link>
                </div>
              </div>

              <div className="card mt-3">
                <div className="card-header">Vérifications, accès et permissions</div>
                <div className="card-body">
                  <h5 className="mt-2 mb-2">Adresse e-mail</h5>
                  L&apos;adresse e-mail <b>{user.mail}</b> est{' '}
                  <b>
                    {(user.is_mail_confirmed && 'validée') || (
                      <span className="text-danger">non validée</span>
                    )}
                  </b>
                  {!user.is_mail_confirmed && (
                    <>
                      <br />
                      <div className="form-group" style={{ marginTop: '5px' }}>
                        <Link
                          to="#"
                          className="selector-link-validate-email"
                          onClick={() => activateEmail()}
                        >
                          Valider l&apos;adresse e-mail
                        </Link>
                      </div>
                      <div className="form-group">
                        <button
                          className="btn btn-primary no-block"
                          id="btn-edit-password"
                          data-cy="btn-edit-password"
                          onClick={() => resendEmail()}
                        >
                          Envoyer le mail de finalisation
                        </button>

                        {user.invitation_sent_at && (
                          <div className="mt-2">
                            <Info
                              text={
                                'Le dernier e-mail de finalisation a été envoyé le ' +
                                new Date(user.invitation_sent_at).toLocaleString('fr') +
                                '.'
                              }
                            />
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  <h5 className="mt-5 mb-2">Mot de passe</h5>
                  Remplissez les champs ci-dessous pour modifier le mot de passe de
                  l&apos;utilisateur.
                  <div className="form-group">
                    <div className="row">
                      <div className="col-lg-6">
                        <input
                          type="password"
                          data-cy="password-admin"
                          className="form-control"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="Nouveau mot de passe"
                        />
                      </div>

                      <div className="col-lg-6">
                        <input
                          type="password"
                          data-cy="password-confirmation-admin"
                          className="form-control"
                          value={passwordConfirmation}
                          onChange={(e) => setPasswordConfirmation(e.target.value)}
                          placeholder="Confirmer le nouveau mot de passe"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <button
                      className="btn btn-primary no-block"
                      id="btn-edit-password"
                      data-cy="btn-edit-password"
                      onClick={() => editPassword()}
                    >
                      Modifier le mot de passe
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-lg-6">
              <PricingFormV2
                user={user}
                calculatePrices={calculatePrices}
                displayPrice={displayPrice}
                offer={pricingOffer()}
                fetchOffer={fetchOffer}
                loadUser={loadUser}
              />
            </div>
            <div className="col-lg-6">
              <div className="card mt-2">
                <div className="card-header">Résumé d’abonnement</div>
                <div className="card-body">
                  <h5 className="mb-2">Abonnement</h5>
                  {user.is_subscriber && activeOffer && (
                    <div className="alert alert-success">
                      <p>L&apos;utilisateur est actuellement abonné.</p>
                      <p>
                        Status de l&apos;abonnement : <b>{activeOffer.status}</b>
                      </p>
                      <p>
                        Période actuelle d&apos;abonnement :{' '}
                        <b>{formatDate(new Date(activeOffer.starts_at), 'DD/MM/YYYY')}</b> -{' '}
                        <b>{formatDate(new Date(activeOffer.ends_at), 'DD/MM/YYYY')}</b>
                      </p>
                      { shouldShowCanceledMessage && (
                          <p>
                            <div className="text-alert">
                              L&apos;abonnement a été annulé (fin le {defaultSubscription.ends_at})
                            </div>
                          </p>
                      )}
                </div>
                  )}
                  {!user.is_subscriber && (
                    <div className="alert alert-danger">
                      L&apos;utilisateur n&apos;est actuellement pas abonné.
                    </div>
                  )}
                  {activeOffer && !offer && (
                    <p>
                      Offre activée le:{' '}
                      {formatDate(new Date(activeOffer.starts_at), 'DD/MM/YYYY HH:mm:ss')}
                    </p>
                  )}
                  {offer && offer.status === 'pending' && (
                    <p>
                      Offre envoyée le:{' '}
                      {formatDate(new Date(offer.updated_at), 'DD/MM/YYYY HH:mm:ss')}
                    </p>
                  )}
                  {(displayPrices ||
                    (activeOffer &&
                      activeOffer.payment_type === 'stripe' &&
                      activeOffer.offer_version === 2 &&
                      !isPendingCreated)) && (
                    <>
                      <h5 className="mb-2">
                        Résumé de la formule {offer && offer.status === 'pending' ? 'proposée' : ''}
                      </h5>
                      <h6 className="mb-2 ">
                        <u>Période 1</u>
                      </h6>
                      <p>
                        Total avant remise : {Math.round(price * 1.2 * 100) / 100} € TTC /{' '}
                        {frequency} (soit {Math.round(price * 100) / 100} € HT)
                      </p>
                      <p>
                        Remise : {Math.round((price - priceAfterCoupon) * 1.2 * 100) / 100} € TTC /{' '}
                        {frequency} (soit {Math.round((price - priceAfterCoupon) * 100) / 100} € HT)
                      </p>
                      <div className="d-flex" style={{ gap: '5px' }}>
                        <h5 className="font-weight-bold d-flex">
                          Total : {Math.round(priceAfterCoupon * 1.2 * 100) / 100} € TTC /{' '}
                          {frequency}
                        </h5>
                        <h6 className="font-weight-bold d-flex">
                          (soit {Math.round(priceAfterCoupon * 100) / 100} € HT)
                        </h6>
                      </div>
                      <h6 className="mb-2 mt-5">
                        <u>Après renouvellement</u>
                      </h6>
                      <p>
                        Total avant remise : {Math.round(renewalPrice * 1.2 * 100) / 100} € TTC /{' '}
                        {renewalFrequency} (soit {Math.round(renewalPrice * 100) / 100} € HT)
                      </p>
                      <p>
                        Remise :{' '}
                        {Math.round((renewalPrice - renewalpriceAfterCoupon) * 1.2 * 100) / 100} €
                        TTC / {renewalFrequency} (soit{' '}
                        {Math.round((renewalPrice - renewalpriceAfterCoupon) * 100) / 100} € HT)
                      </p>
                      <div className="d-flex" style={{ gap: '5px' }}>
                        <h5 className="font-weight-bold d-flex">
                          Total : {Math.round(renewalpriceAfterCoupon * 1.2 * 100) / 100} € TTC /{' '}
                          {renewalFrequency}
                        </h5>
                        <h6 className="font-weight-bold d-flex">
                          (soit {Math.round(renewalpriceAfterCoupon * 100) / 100} € HT)
                        </h6>
                      </div>
                    </>
                  )}
                  {offer && offer.status === 'pending' && offer.payment_type === 'transfer' && (
                    <div>
                      <button
                        className="btn btn-primary no-block mr-0"
                        onClick={() => activateOffer()}
                        id="btn-activate-offer"
                        data-cy="btn-activate-offer"
                      >
                        Activer l&apos;offre (Paiement validé)
                      </button>
                    </div>
                  )}
                  {offer && offer.status === 'pending' && !isCollaborator() && (
                    <div>
                      <br />
                      <p>
                        Le client ne souhaite pas changer d&apos;offre? vous pouvez supprimer
                        l&apos;offre proposée:
                      </p>
                      <button
                        className="btn btn-primary no-block mr-0"
                        onClick={() => removePendingOffer()}
                        id="btn-cancel-pending"
                        data-cy="btn-cancel-pending"
                      >
                        Supprimer l&apos;offre proposée
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  Projets demandés par <b>{user.mail}</b>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    {projectRequests && projectRequests.length > 0 ? (
                      <table className="table table-hover table-bordered mt-2">
                        <thead>
                          <tr>
                            <th scope="col">Nom du projet</th>
                            <th scope="col">Status</th>
                            <th scope="col">Date de demande</th>
                          </tr>
                        </thead>
                        <tbody>
                          {projectRequests.map((project: IProjectRequest) => (
                            <tr key={project.id}>
                              <td>
                                {project.request_trades ? (
                                  <Link to={`/admin/projets/${project.project.id}`}>
                                    {project.project.name}
                                  </Link>
                                ) : (
                                  <p>Ce projet n&apos;existe plus.</p>
                                )}
                              </td>
                              <td>{project.status}</td>
                              <td>{project.created_at}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <p>Pas de projet demandés</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <UserManagementV2 manager={user} refresh={() => loadUser()} />

          <div className="row my-4">
            <div className="col-12">
              <div className="card">
                <div className="card-header">Administration</div>
                <div className="card-body">
                  L&apos;utilisateur{' '}
                  <b>
                    {user.first_name} {user.last_name}
                  </b>{' '}
                  <b>
                    {(user.profile === 1 && (
                      <span className="text-success">est administrateur</span>
                    )) || <span className="text-danger">n&apos;est pas administrateur </span>}
                  </b>
                  -{' '}
                  <Link
                    to="#"
                    style={{ textTransform: 'none' }}
                    className="selector-link-admin-permission"
                    onClick={() => editAdminStatus()}
                  >
                    {(user.profile === 1 && 'Supprimer le droit administrateur') ||
                      'Définir comme administrateur'}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default UserV2;
