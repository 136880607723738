import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import Success from '../../navigation/Success';
import ErrorBox from '../../../common/errors/ErrorBox';
import { BACKEND_URL } from '../../../config/environment';
import PdfViewerModal from '../../viewer/PdfViewer';

const PricingFormV2: React.FC<{
  user: IUser;
  offer: any;
  calculatePrices: (
    price: number,
    priceAfterCoupon: number,
    frequency: string,
    renewalPrice: number,
    priceRenewalAfterCoupon: number,
    renewalFrequency: string,
    isPendingCreated: boolean
  ) => void;
  displayPrice: (display: boolean) => void;
  fetchOffer: () => void;
  loadUser: () => void;
}> = ({ user, calculatePrices, displayPrice, offer, fetchOffer, loadUser }) => {
  // DATAS
  const [availablePrices, setAvailablePrices] = useState<any>();

  // FIELDS
  const [paymentType, setPaymentType] = useState<string>('CB');
  const [stripeFormula, setStripeFormula] = useState<string>('');
  const [formulaCoupon, setFormulaCoupon] = useState<string>('');
  const [stripeRenewalFormula, setStripeRenewalFormula] = useState<string>('');
  const [stripeRenewalCoupon, setStripeRenewalCoupon] = useState<string>('');
  const [numberRegions, setNumberRegions] = useState<number>(1);
  const [regions, setRegions] = useState<string[]>([]);
  const [departments, setDepartments] = useState<string[]>([]);
  const [trades, setTrades] = useState<string[]>([]);
  const [numberUser, setNumberUser] = useState<number>(1);
  const [moreUser, setMoreUser] = useState<number>(0);
  const [userCoupon, setUserCoupon] = useState<string>('');
  const [support, setSupport] = useState<number>(0);
  const [supportCoupon, setSupportCoupon] = useState<string>('');
  const [saqaraModule, setSaqaraModule] = useState<number>(0);
  const [saqaraModuleCoupon, setSaqaraModuleCoupon] = useState<string>('');
  const [interlocutor, setInterlocutor] = useState<number>(0);
  const [interlocutorCoupon, setInterlocutorCoupon] = useState<string>('');
  const [commissioning, setCommissioning] = useState<number>(0);
  const [commissioningCoupon, setCommissioningCoupon] = useState<string>('');
  const [isSepaAllowed, setIsSepaAllowed] = useState<boolean>(false);
  const [openSettings, setOpenSettings] = useState<boolean>(false);
  const [estimation, setEstimation] = useState<string>('');
  const [estimationContent, setEstimationContent] = useState(null);
  const [estimationFile, setEstimationFile] = useState<File>();
  const [displayPdf, setDisplayPdf] = useState(false);

  // TOTAL PRICE
  const [price, setPrice] = useState<number>(0);
  const [priceAfterCoupon, setPriceAfterCoupon] = useState<number>(0);
  const [renewalPrice, setRenewalPrice] = useState<number>(0);
  const [renewalPriceAfterCoupon, setRenewalPriceAfterCoupon] = useState<number>(0);
  const [cbFrequency, setCbFrequency] = useState<string>('');
  const [cbRenewalFrequency, setCbRenewalFrequency] = useState<string>('');

  const [offerType, setOfferType] = useState<string>('');
  const [canUpdate, setCanUpdate] = useState<boolean>(false);

  // SELECTS OPTIONS
  interface selectOptions {
    key: string;
    value: string;
  }

  const [regionValues, setRegionValues] = useState<selectOptions[]>();
  const [departmentValues, setDepartmentValues] = useState<selectOptions[]>();
  const [tradesValues, setTradesValues] = useState<selectOptions[]>();
  const [tradesRef, setTradesRef] = useState<any[]>();

  const [successMessage, setSuccessMessage] = useState<string>(null);
  const [error, setError] = useState<boolean>(false);

  const couponValues = [
    { key: 0, value: '0%' },
    { key: 5, value: '5%' },
    { key: 10, value: '10%' },
    { key: 15, value: '15%' },
    { key: 20, value: '20%' },
    { key: 25, value: '25%' },
    { key: 30, value: '30%' },
    { key: 40, value: '40%' },
    { key: 50, value: '50%' },
    { key: 100, value: '100%' },
  ];

  const formulaValues = [
    {
      key: 'A',
      value: 'Annuelle',
      getTechnical: 'OFFER_ANNUAL',
      postTechnical: 'ANNUAL',
      displayed: 'an',
      id: 1,
    },
    {
      key: 'T',
      value: 'Trimestrielle',
      getTechnical: 'OFFER_QUARTER',
      postTechnical: 'QUARTER',
      displayed: 'trimestre',
      id: 3,
    },
    {
      key: 'S',
      value: 'Semestrielle',
      getTechnical: 'OFFER_SEMESTER',
      postTechnical: 'SEMESTER',
      displayed: 'semestre',
      id: 2,
    },
  ];

  const getRenewFormulaValues = () => {
    const formulaValueId = findFormulaId(stripeFormula);

    return formulaValues.filter((e) => e.id <= formulaValueId);
  };

  const isFieldDisabled = () => {
    return isPaymentComing() || isCollaborator();
  };

  const isPaymentComing = () => {
    return offer && offer.status === 'payment_coming';
  };

  const isCollaborator = () => {
    return user.profile === 0 && user.role?.role === 'collaborator';
  };

  const constructSelectOptions = (data: any) => {
    if (data.regions) {
      const reg: selectOptions[] = data.regions.map((el: any) => {
        return { key: el.id, value: el.name } as selectOptions;
      });
      setRegionValues(reg);
    }
    if (data.states) {
      const dep: selectOptions[] = data.states.map((el: any) => {
        return { key: el.id, value: el.name } as selectOptions;
      });
      setDepartmentValues(dep);
    }
    if (data.trades) {
      setTradesRef(data.trades);
      const trades: selectOptions[] = data.trades
          .filter((trade: IMappedTradeCategory) => trade.level === 2)
          .map((trade: IMappedTradeCategory) => {
              return { key: trade.id.toString(), value: trade.name } as selectOptions;
          });
      const sortedTrades = trades.sort((a: selectOptions, b: selectOptions) => a.value.localeCompare(b.value));
      setTradesValues(sortedTrades);  
    }



  };

  const selectAllTrades = () => {
    const tradesArray = [];
    for (let i = 0; i < tradesValues.length; i++) {
      const trade = tradesValues[i];
      tradesArray.push(trade.value);
    }
    setTrades(tradesArray);
  };

  const getPrice = (item: string, frequency: string) => {
    if (availablePrices) {
      const frequencyOption = availablePrices.prices.find((e: any) => e.name === frequency);
      if (frequencyOption && frequencyOption.items) {
        const priceItem = frequencyOption.items.find((e: any) => e.name === item);
        return priceItem && priceItem.price ? priceItem.price : 0;
      }
    }
    return 0;
  };

  useEffect(() => {
    axios
      .get(`${BACKEND_URL}/api/admin/filters?filters[]=trades&filters[]=regions&filters[]=states`)
      .then(({ data }) => {
        constructSelectOptions(data);
      });

    axios.get(`${BACKEND_URL}/api/admin/available-prices`).then(({ data }) => {
      setAvailablePrices(data);
    });
  }, []);

  useEffect(() => {
    constructGetOffer();
  }, [offer, tradesValues]);

  const constructGetOffer = () => {
    if (offer) {
      setStripeFormula(findFormulaByTechnicalLabel(offer.offer.name));
      if (offer.offer.discount) setFormulaCoupon(findCouponLabel(offer.offer.discount.value));
      setStripeRenewalFormula(findFormulaByTechnicalLabel(offer.renew.name));
      if (offer.renew.discount) setStripeRenewalCoupon(findCouponLabel(offer.renew.discount.value));
      setPaymentType(offer.payment_type === 'stripe' ? 'CB' : 'Virement');
      setNumberRegions(offer.offer.quantity);

      // Options
      const statesOption = offer.options.find((e: any) => e.type === 'states');
      if (statesOption && statesOption.items) {
        setDepartments(
          statesOption.items.map((e: any) => {
            return e.name;
          })
        );
      }

      const regionsOption = offer.options.find((e: any) => e.type === 'regions');
      if (regionsOption && regionsOption.items) {
        setRegions(
          regionsOption.items.map((e: any) => {
            return e.name;
          })
        );
      }

      const tradesOption = offer.options.find((e: any) => e.type === 'trades');

      const level3Values: any[] = [];
      const level2Values: any[] = [];

      if(tradesRef && tradesOption) {
        tradesOption.items.forEach((to: any) => {
          let level3 = tradesRef.find((tr: any) => tr.id.toString() === to.id.toString());
          if(level3) {
            level3Values.push(level3);
          }
        });
        level3Values.forEach((level3: any) => {
          let level2 = tradesRef.find((tr: any) => level3.parent_id && tr.id === level3.parent_id);
          if(level2Values.indexOf(level2) === -1) {
            level2Values.push(level2);
          }
        });
        setTrades(
          level2Values.map((e: any) => {
            return e.name;
        })
        );
      }

      // Addons
      if (offer.addons) {
        const usersOption = offer.addons.find((e: any) => e.name.includes('USER'));
        if (usersOption && usersOption.quantity) {
          setMoreUser(usersOption.quantity - 1);
          if (usersOption.discount) setUserCoupon(findCouponLabel(usersOption.discount.value));
        }

        const supportOption = offer.addons.find((e: any) => e.name.includes('SUPPORT'));
        if (supportOption && supportOption.quantity) {
          setSupport(supportOption.quantity);
          if (supportOption.discount)
            setSupportCoupon(findCouponLabel(supportOption.discount.value));
        }

        const trainingOption = offer.addons.find((e: any) => e.name.includes('TRAINING'));
        if (trainingOption && trainingOption.quantity) {
          setSaqaraModule(trainingOption.quantity);
          if (trainingOption.discount)
            setSaqaraModuleCoupon(findCouponLabel(trainingOption.discount.value));
        }

        const commissioningOption = offer.addons.find((e: any) => e.name.includes('COMMISSIONING'));
        if (commissioningOption && commissioningOption.quantity) {
          setCommissioning(commissioningOption.quantity);
          if (commissioningOption.discount)
            setCommissioningCoupon(findCouponLabel(commissioningOption.discount.value));
        }

        const interlocutorOption = offer.addons.find((e: any) => e.name.includes('INTERLOCUTOR'));
        if (interlocutorOption && interlocutorOption.quantity) {
          setInterlocutor(interlocutorOption.quantity);
          if (interlocutorOption.discount)
            setInterlocutorCoupon(findCouponLabel(interlocutorOption.discount.value));
        }
      }

      setIsSepaAllowed(offer.is_sepa_allowed);
      setEstimationContent(offer.quotation);
      if (offer.quotation) {
        try {
          const quotation = offer.quotation as string;
          const filename = quotation.substring(
            quotation.lastIndexOf('/') + 1,
            quotation.indexOf('?')
          );
          setEstimation(filename);
        } catch {
          console.log('Erreur Fichier');
        }
      }
      setOfferType(offer.status);
      setOpenSettings(true);
      setDisplayPrice(offer.payment_type === 'stripe');
    } else {
      // reset interface
      resetCoupons();
      resetFields();
    }
  };

  const resetCoupons = () => {
    setFormulaCoupon('');
    setStripeRenewalCoupon('');
    setUserCoupon('');
    setSupportCoupon('');
    setSaqaraModuleCoupon('');
    setInterlocutorCoupon('');
    setCommissioningCoupon('');
    setMoreUser(0);
  };

  const resetFields = () => {
    setStripeFormula('');
    setStripeRenewalFormula('');
    setNumberRegions(1);
    setRegions([]);
    setDepartments([]);
    if (tradesValues) {
      const tradesArray = [];
      for (let i = 0; i < tradesValues.length; i++) {
        const trade = tradesValues[i];
        tradesArray.push(trade.value);
      }
      setTrades(tradesArray);
    } else {
      setTrades([]);
    }
    setMoreUser(0);
    setNumberUser(1);
    setSupport(0);
    setSaqaraModule(0);
    setInterlocutor(0);
    setCommissioning(0);
    setEstimation('');
    setEstimationContent(undefined);
    setEstimationFile(undefined);
    setCanUpdate(false);
    setDisplayPrice(false);
    setOpenSettings(false);
  };

  const handleChangeStripeFormula = (event: React.ChangeEvent<{ value: unknown }>) => {
    handleTouch();
    setStripeFormula(event.target.value as string);
    setStripeRenewalFormula('');
  };

  const handleChangePaymentType = (event: React.ChangeEvent<{ value: unknown }>) => {
    handleTouch();
    setPaymentType(event.target.value as string);
    setDisplayPrice(event.target.value === 'CB' || false);
    resetCoupons();
    resetFields();
  };

  const handleSetRegions = (input: any) => {
    const regionArray = [];
    for (let i = 0; i < input.length; i++) {
      const reg = input[i];

      if (reg.selected && i <= numberRegions - 1) {
        regionArray.push(reg.value);
      }
    }
    setRegions(regionArray);
  };

  const handleSetDepartments = (input: any) => {
    const departmentArray = [];
    for (let i = 0; i < input.length; i++) {
      const reg = input[i];

      if (reg.selected && i <= 7) {
        departmentArray.push(reg.value);
      }
    }
    setDepartments(departmentArray);
  };

  const handleSetTrades = (input: any) => {
    const tradesArray = [];
    for (let i = 0; i < input.length; i++) {
      const reg = input[i];

      if (reg.selected) {
        tradesArray.push(reg.value);
      }
    }
    setTrades(tradesArray);
  };

  const isNewOffer = () => {
    return canUpdate;
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEstimationFile(e.target.files[0]);
    setEstimationFile((file) => {
      if (isNewOffer()) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        setEstimationContent(URL.createObjectURL(file));
        setEstimation(file.name);
      } else {
        handleUploadClick(file);
      }
      return file;
    });
  };

  const handleUploadClick = (file: File) => {
    const formData = new FormData();
    formData.append('quotation', file);
    axios
      .post(`${BACKEND_URL}/api/admin/users/${user.id}/${offer.id}/quotation`, formData)
      .then(() => {
        setSuccessMessage('Le devis a été mis à jour.');
        setError(false);
        fetchOffer();
        setCanUpdate(false);
      })
      .catch(() => setError(true));
  };

  const constructPostOffer = () => {
    const offerJson = {
      offer: {
        name: findFormulaTechnicalLabel(stripeFormula),
        discount:
          formulaCoupon !== '' ? { value: findCouponKey(formulaCoupon), type: 'PERCENT' } : null,
        quantity: numberRegions,
      },
      renew: {
        name: findFormulaTechnicalLabel(
          paymentType === 'CB' ? stripeRenewalFormula : stripeFormula
        ),
        discount:
          stripeRenewalCoupon !== ''
            ? { value: findCouponKey(stripeRenewalCoupon), type: 'PERCENT' }
            : null,
        quantity: numberRegions,
      },
      options: [
        {
          type: 'states',
          items: departments.map((e: any) => {
            const department = findDepartmentByLabel(e);
            return { id: department.key, name: department.value };
          }),
        },
        {
          type: 'regions',
          items: regions.map((e: any) => {
            const region = findRegionByLabel(e);
            return { id: region.key, name: region.value };
          }),
        },
        {
          type: 'trades',
          items: trades.map((e: any) => {
            const trade = findTradeByLabel(e);
            return { id: trade.key, name: trade.value };
          }),
        },
      ],
      addons: [
        {
          name: 'USER_' + findFormulaTechnicalLabel(stripeFormula),
          discount:
            userCoupon !== '' ? { value: findCouponKey(userCoupon), type: 'PERCENT' } : null,
          quantity: numberUser + moreUser,
        },
        {
          name: 'SUPPORT_' + findFormulaTechnicalLabel(stripeFormula),
          discount:
            supportCoupon !== '' ? { value: findCouponKey(supportCoupon), type: 'PERCENT' } : null,
          quantity: support,
        },
        {
          name: 'TRAINING_' + findFormulaTechnicalLabel(stripeFormula),
          discount:
            saqaraModuleCoupon !== ''
              ? { value: findCouponKey(saqaraModuleCoupon), type: 'PERCENT' }
              : null,
          quantity: saqaraModule,
        },
        {
          name: 'COMMISSIONING_' + findFormulaTechnicalLabel(stripeFormula),
          discount:
            commissioningCoupon !== ''
              ? { value: findCouponKey(commissioningCoupon), type: 'PERCENT' }
              : null,
          quantity: commissioning,
        },
        {
          name: 'INTERLOCUTOR_' + findFormulaTechnicalLabel(stripeFormula),
          discount:
            interlocutorCoupon !== ''
              ? { value: findCouponKey(interlocutorCoupon), type: 'PERCENT' }
              : null,
          quantity: interlocutor,
        },
      ],
      payment_type: paymentType === 'CB' ? 'stripe' : 'transfer',
      is_sepa_allowed: isSepaAllowed,
    };

    return offerJson;
  };

  const sendOffer = () => {
    const offerJson = constructPostOffer();

    const formData = new FormData();
    formData.append('offer', JSON.stringify(offerJson));
    if (estimationFile) {
      formData.append('quotation', estimationFile);
    }
    axios
      .post(`${BACKEND_URL}/api/admin/users/${user.id}/formulas`, formData)
      .then(() => {
        setSuccessMessage("Les offres de ce compte viennent d'être mises à jour.");
        setError(false);
        fetchOffer();
        setCanUpdate(false);
        loadUser();
      })
      .catch(() => setError(true));
  };

  const setDisplayPrice = (display: boolean) => {
    displayPrice(display);
  };

  const findDepartmentByLabel = (label: any) => {
    const department = departmentValues.find((e) => e.value === label);
    return department;
  };

  const findRegionByLabel = (label: any) => {
    const region = regionValues.find((e) => e.value === label);
    return region;
  };

  const findTradeByLabel = (label: any) => {
    const trade = tradesValues.find((e) => e.value === label);
    return trade;
  };

  const findCouponKey = (coupon: any) => {
    const couponValue = couponValues.find((e) => e.value === coupon);
    return couponValue && couponValue.key ? couponValue.key : 0;
  };

  const findCouponLabel = (coupon: any) => {
    const couponValue = couponValues.find((e) => e.key === coupon);
    return couponValue && couponValue.value ? couponValue.value : '';
  };

  const findFrequency = (frequency: any) => {
    const formulaValue = formulaValues.find((e) => e.value === frequency);
    return formulaValue && formulaValue.displayed ? formulaValue.displayed : '';
  };

  const findFormulaByTechnicalLabel = (label: any) => {
    const formulaValue = formulaValues.find((e) => e.getTechnical === label);
    return formulaValue && formulaValue.value ? formulaValue.value : '';
  };

  const findFormulaTechnicalLabel = (label: any) => {
    const formulaValue = formulaValues.find((e) => e.value === label);
    return formulaValue.postTechnical;
  };

  const findFormulaId = (label: any) => {
    const formulaValue = formulaValues.find((e) => e.value === label);
    return formulaValue.id;
  };

  const handleTouch = () => {
    setOfferType('pending');
    setCanUpdate(true);
  };

  useMemo(() => {
    calculatePrices(
      price,
      priceAfterCoupon,
      cbFrequency,
      renewalPrice,
      renewalPriceAfterCoupon,
      cbRenewalFrequency,
      offerType === 'pending'
    );
  }, [
    price,
    priceAfterCoupon,
    cbFrequency,
    renewalPrice,
    renewalPriceAfterCoupon,
    cbRenewalFrequency,
    offerType,
  ]);

  useMemo(() => {
    let price = 0;
    let priceAfterCoupon = 0;
    let renewalprice = 0;
    let renewalpriceAfterCoupon = 0;

    if (stripeFormula) {
      price =
        Math.round(
          (numberRegions * getPrice('OFFER', findFormulaTechnicalLabel(stripeFormula)) +
            moreUser * getPrice('USER', findFormulaTechnicalLabel(stripeFormula)) +
            saqaraModule * getPrice('TRAINING', findFormulaTechnicalLabel(stripeFormula)) +
            interlocutor * getPrice('INTERLOCUTOR', findFormulaTechnicalLabel(stripeFormula)) +
            commissioning * getPrice('COMMISSIONING', findFormulaTechnicalLabel(stripeFormula)) +
            support * getPrice('SUPPORT', findFormulaTechnicalLabel(stripeFormula))) *
            100
        ) / 100;
      priceAfterCoupon =
        Math.round(
          (numberRegions *
            getPrice('OFFER', findFormulaTechnicalLabel(stripeFormula)) *
            (1 - findCouponKey(formulaCoupon) / 100) +
            moreUser *
              getPrice('USER', findFormulaTechnicalLabel(stripeFormula)) *
              (1 - findCouponKey(userCoupon) / 100) +
            saqaraModule *
              getPrice('TRAINING', findFormulaTechnicalLabel(stripeFormula)) *
              (1 - findCouponKey(saqaraModuleCoupon) / 100) +
            commissioning *
              getPrice('COMMISSIONING', findFormulaTechnicalLabel(stripeFormula)) *
              (1 - findCouponKey(commissioningCoupon) / 100) +
            interlocutor *
              getPrice('INTERLOCUTOR', findFormulaTechnicalLabel(stripeFormula)) *
              (1 - findCouponKey(interlocutorCoupon) / 100) +
            support *
              getPrice('SUPPORT', findFormulaTechnicalLabel(stripeFormula)) *
              (1 - findCouponKey(supportCoupon) / 100)) *
            100
        ) / 100;
    }

    if (stripeRenewalFormula) {
      renewalprice =
        Math.round(
          (numberRegions * getPrice('OFFER', findFormulaTechnicalLabel(stripeRenewalFormula)) +
            moreUser * getPrice('USER', findFormulaTechnicalLabel(stripeRenewalFormula)) +
            saqaraModule * getPrice('TRAINING', findFormulaTechnicalLabel(stripeRenewalFormula)) +
            interlocutor *
              getPrice('INTERLOCUTOR', findFormulaTechnicalLabel(stripeRenewalFormula)) +
            commissioning *
              getPrice('COMMISSIONING', findFormulaTechnicalLabel(stripeRenewalFormula)) +
            support * getPrice('SUPPORT', findFormulaTechnicalLabel(stripeRenewalFormula))) *
            100
        ) / 100;
      renewalpriceAfterCoupon =
        Math.round(
          (numberRegions *
            getPrice('OFFER', findFormulaTechnicalLabel(stripeRenewalFormula)) *
            (1 - findCouponKey(stripeRenewalCoupon) / 100) +
            moreUser *
              getPrice('USER', findFormulaTechnicalLabel(stripeRenewalFormula)) *
              (1 - findCouponKey(userCoupon) / 100) +
            saqaraModule *
              getPrice('TRAINING', findFormulaTechnicalLabel(stripeRenewalFormula)) *
              (1 - findCouponKey(saqaraModuleCoupon) / 100) +
            commissioning *
              getPrice('COMMISSIONING', findFormulaTechnicalLabel(stripeRenewalFormula)) *
              (1 - findCouponKey(commissioningCoupon) / 100) +
            interlocutor *
              getPrice('INTERLOCUTOR', findFormulaTechnicalLabel(stripeRenewalFormula)) *
              (1 - findCouponKey(interlocutorCoupon) / 100) +
            support *
              getPrice('SUPPORT', findFormulaTechnicalLabel(stripeRenewalFormula)) *
              (1 - findCouponKey(supportCoupon) / 100)) *
            100
        ) / 100;
    }

    const frequency = findFrequency(stripeFormula);
    const renewalFrequency = findFrequency(stripeRenewalFormula);
    setPrice(price);
    setPriceAfterCoupon(priceAfterCoupon);
    setRenewalPrice(renewalprice);
    setRenewalPriceAfterCoupon(renewalpriceAfterCoupon);
    setCbFrequency(frequency);
    setCbRenewalFrequency(renewalFrequency);
  }, [
    numberRegions,
    moreUser,
    saqaraModule,
    formulaCoupon,
    userCoupon,
    saqaraModuleCoupon,
    interlocutor,
    interlocutorCoupon,
    commissioning,
    commissioningCoupon,
    stripeFormula,
    stripeRenewalFormula,
    supportCoupon,
    support,
    stripeRenewalCoupon,
    availablePrices,
  ]);

  const isEnabled = () => {
    if (paymentType === 'CB') {
      return (
        (regions.length === numberRegions || departments.length === 8) &&
        stripeRenewalFormula &&
        trades &&
        canUpdate &&
        !isPaymentComing() &&
        !isCollaborator()
      );
    } else {
      return (
        (regions.length === numberRegions || departments.length === 8) &&
        stripeFormula &&
        trades &&
        estimationFile &&
        canUpdate &&
        !isPaymentComing() &&
        !isCollaborator()
      );
    }
  };

  return (
    <>
      <PdfViewerModal
        content={estimationContent}
        hidden={displayPdf}
        closeModal={() => setDisplayPdf(false)}
      />

      <div className="card mt-2">
        <div className="card-header">Offres {offerType}</div>

        <div className="card-body">
          {offerType === 'active' && (
            <div className="alert alert-success mb-3">
              Vous visualisez actuellement une offre active. Vous pouvez proposer une nouvelle offre
              en modifiant un des champs ci-dessous.
            </div>
          )}
          {offerType === 'pending' && (
            <div className="alert alert-warning mb-3">
              Vous visualisez actuellement une offre en cours.
            </div>
          )}
          {isPaymentComing() && (
            <div className="alert alert-danger mb-3">
              Un paiement est actuellement en cours, vous ne pouvez pas modifier cette offre tant
              qu&apos;elle n&apos;est pas active.
            </div>
          )}
          {isCollaborator() && (
            <div className="alert alert-danger mb-3">
              Cet utilisateur est un collaborateur, vous ne pouvez pas lui proposer d&apos;offre.
            </div>
          )}
          <div className="form-group">
            {successMessage && (
              <Success text={successMessage} onHide={() => setSuccessMessage(null)} />
            )}
            {error && <ErrorBox text="Une erreur vient de se produire." />}

            <label className="pr-5">Moyen de paiement</label>
            <select
              value={paymentType}
              disabled={isFieldDisabled()}
              onChange={handleChangePaymentType}
              style={{ gap: '8px', borderRadius: '8px', minWidth: '160px', padding: '0px 12px' }}
            >
              <option value="CB">CB</option>
              <option value="Virement">Virement</option>
            </select>

            {paymentType === 'CB' && (
              <>
                <div className="form-group mt-3">
                  <label>Formule Stripe</label>
                  <select
                    name="stripe-formula_id"
                    data-cy="stripe-formula-id"
                    value={stripeFormula}
                    disabled={isFieldDisabled()}
                    onChange={handleChangeStripeFormula}
                    className="form-control"
                    style={{
                      color: stripeFormula ? '#495057' : '#A9AEB6',
                      backgroundColor: '#EEF1F6',
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Choisir une formule Stripe
                    </option>
                    {formulaValues.map((element) => (
                      <option key={element.key} value={element.value} style={{ color: '#495057' }}>
                        {element.value}
                      </option>
                    ))}
                  </select>
                  {openSettings && (
                    <select
                      name="formula_coupon_id"
                      data-cy="formula-coupon-id"
                      value={formulaCoupon}
                      disabled={isFieldDisabled()}
                      onChange={(e) => {
                        handleTouch();
                        setFormulaCoupon(e.target.value);
                      }}
                      className="form-control mt-2"
                      style={{
                        color: formulaCoupon ? '#495057' : '#A9AEB6',
                        backgroundColor: '#EEF1F6',
                      }}
                    >
                      <option value="" selected disabled hidden>
                        Choisir un coupon de réduction - Formule
                      </option>
                      {couponValues.map((element) => (
                        <option
                          key={element.key}
                          value={element.value}
                          style={{ color: '#495057' }}
                        >
                          {element.value}
                        </option>
                      ))}
                    </select>
                  )}
                </div>

                {!openSettings && (
                  <button
                    className="btn btn-primary"
                    id="btn-update-proposed-prices"
                    onClick={() => {
                      selectAllTrades();
                      setOpenSettings(true);
                      setDisplayPrice(true);
                    }}
                    disabled={stripeFormula === ''}
                  >
                    Paramétrer l&apos;offre
                  </button>
                )}

                {openSettings && (
                  <>
                    <div className="form-group mt-3">
                      <label>Formule Stripe Renouvellement</label>
                      <select
                        name="stripe_formula_renewal_id"
                        data-cy="stripe-formula-renewal-id"
                        value={stripeRenewalFormula}
                        disabled={isFieldDisabled()}
                        onChange={(e) => {
                          handleTouch();
                          setStripeRenewalFormula(e.target.value);
                        }}
                        className="form-control"
                        style={{ backgroundColor: '#EEF1F6' }}
                      >
                        <option value="" selected disabled hidden>
                          Choisir une formule Stripe
                        </option>
                        {getRenewFormulaValues().map((element) => (
                          <option
                            key={element.key}
                            value={element.value}
                            style={{ color: '#495057' }}
                          >
                            {element.value}
                          </option>
                        ))}
                      </select>
                      <select
                        name="stripe_renewal_coupon_id"
                        data-cy="stripe-renewal-coupon-id"
                        value={stripeRenewalCoupon}
                        disabled={isFieldDisabled()}
                        onChange={(e) => {
                          handleTouch();
                          setStripeRenewalCoupon(e.target.value);
                        }}
                        className="form-control mt-2"
                        style={{
                          color: stripeRenewalCoupon ? '#495057' : '#A9AEB6',
                          backgroundColor: '#EEF1F6',
                        }}
                      >
                        <option value="" selected disabled hidden>
                          Choisir un coupon de réduction - Renouvellement
                        </option>
                        {couponValues.map((element) => (
                          <option
                            key={element.key}
                            value={element.value}
                            style={{ color: '#495057' }}
                          >
                            {element.value}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group mt-3">
                      <label>Régions</label>
                      <input
                        type="number"
                        step="1"
                        min="1"
                        className="form-control"
                        autoComplete="off"
                        placeholder="Nombre de régions"
                        disabled={isFieldDisabled()}
                        value={numberRegions ?? 0}
                        style={{ backgroundColor: '#EEF1F6' }}
                        onChange={(e) => {
                          handleTouch();
                          setNumberRegions(Number.parseInt(e.target.value));
                          setRegions([]);
                          setDepartments([]);
                        }}
                      />
                      <select
                        name="region_select_id"
                        data-cy="region-select-id"
                        value={regions}
                        multiple
                        disabled={isFieldDisabled()}
                        onChange={(e) => {
                          handleTouch();
                          handleSetRegions(e.target.selectedOptions);
                          setDepartments([]);
                        }}
                        className="form-control mt-2"
                        style={{
                          color: regions ? '#495057' : '#A9AEB6',
                          backgroundColor: '#EEF1F6',
                        }}
                      >
                        <option value="" selected disabled hidden>
                          Choisir les régions
                        </option>
                        {regionValues &&
                          regionValues.map((element: { key: any; value: any; }) => (
                            <option
                              key={element.key}
                              value={element.value}
                              style={{ color: '#495057' }}
                            >
                              {element.value}
                            </option>
                          ))}
                      </select>
                      <p style={{ marginTop: '5px', fontStyle: 'italic', marginBottom: '5px' }}>
                        {regions.length + ' '} Régions sélectionnées: {regions.join(', ')}
                      </p>
                      <p style={{ fontStyle: 'italic' }}>
                        Vous devez sélectionner {numberRegions} région{numberRegions > 1 ? 's' : ''}
                      </p>
                      {numberRegions === 1 && (
                        <>
                          <select
                            name="department_select_id"
                            data-cy="department-select-id"
                            value={departments}
                            multiple
                            disabled={isFieldDisabled()}
                            onChange={(e) => {
                              handleTouch();
                              handleSetDepartments(e.target.selectedOptions);
                              setRegions([]);
                            }}
                            className="form-control mt-2"
                            style={{
                              color: departments ? '#495057' : '#A9AEB6',
                              backgroundColor: '#EEF1F6',
                            }}
                          >
                            <option value="" selected disabled hidden>
                              Choisir les départements
                            </option>
                            {departmentValues &&
                              departmentValues.map((element: { key: any; value: any; }) => (
                                <option
                                  key={element.key}
                                  value={element.value}
                                  style={{ color: '#495057' }}
                                >
                                  ({element.key}) {element.value}
                                </option>
                              ))}
                          </select>
                          <p style={{ marginTop: '5px', fontStyle: 'italic', marginBottom: '5px' }}>
                            {departments.length + ' '} Départements sélectionnés:{' '}
                            {departments.join(', ')}
                          </p>
                          <p style={{ fontStyle: 'italic' }}>
                            Vous devez sélectionner 8 départements
                          </p>
                        </>
                      )}
                    </div>

                    <div className="form-group mt-3">
                      <label>Lots</label>
                      <select
                        name="trades_id"
                        data-cy="trades-id"
                        value={trades}
                        multiple
                        disabled={isFieldDisabled()}
                        onChange={(e) => {
                          handleTouch();
                          handleSetTrades(e.target.selectedOptions);
                        }}
                        className="form-control mt-2"
                        style={{
                          color: trades ? '#495057' : '#A9AEB6',
                          backgroundColor: '#EEF1F6',
                        }}
                      >
                        <option value="" selected disabled hidden>
                          Choisir les lots
                        </option>
                        {tradesValues &&
                          tradesValues.map((element: { key: any; value: any; }) => (
                            <option
                              key={element.key}
                              value={element.value}
                              style={{ color: '#495057' }}
                            >
                              {element.value}
                            </option>
                          ))}
                      </select>
                    </div>

                    <div className="form-group mt-3">
                      <label>Utilisateurs</label>
                      <p className="mb-0" style={{ fontSize: '13px', fontStyle: 'italic' }}>
                        Utilisateur de base (non modifiable)
                      </p>
                      <input
                        type="number"
                        step="1"
                        className="form-control"
                        autoComplete="off"
                        placeholder="Nombre d'utilisateurs"
                        value={numberUser}
                        style={{ backgroundColor: '#EEF1F6', marginTop: '0px' }}
                        disabled
                      />
                      <p className="mb-0" style={{ fontSize: '13px', fontStyle: 'italic' }}>
                        Utilisateurs supplémentaires
                      </p>
                      <input
                        type="number"
                        step="1"
                        min="0"
                        className="form-control"
                        autoComplete="off"
                        placeholder="Nombre d'utilisateurs"
                        disabled={isFieldDisabled()}
                        value={moreUser}
                        style={{ backgroundColor: '#EEF1F6', marginTop: '0px' }}
                        onChange={(e) => {
                          handleTouch();
                          setMoreUser(Number.parseInt(e.target.value));
                        }}
                      />
                      <select
                        name="more_user_id"
                        data-cy="more-user-id"
                        value={userCoupon}
                        onChange={(e) => {
                          handleTouch();
                          setUserCoupon(e.target.value);
                        }}
                        className="form-control mt-2"
                        style={{
                          color: userCoupon ? '#495057' : '#A9AEB6',
                          backgroundColor: '#EEF1F6',
                        }}
                        disabled={moreUser === 0 || isNaN(moreUser) || isFieldDisabled()}
                      >
                        <option value="" selected disabled hidden>
                          Choisir un coupon de réduction - Utilisateurs
                        </option>
                        {couponValues.map((element) => (
                          <option
                            key={element.key}
                            value={element.value}
                            style={{ color: '#495057' }}
                          >
                            {element.value}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group mt-3">
                      <label>Support et Assistance téléphonique</label>
                      <input
                        type="number"
                        step="1"
                        min="0"
                        className="form-control"
                        disabled={isFieldDisabled()}
                        autoComplete="off"
                        placeholder="Choisir un produit - Support"
                        value={support}
                        style={{ backgroundColor: '#EEF1F6' }}
                        onChange={(e) => {
                          handleTouch();
                          setSupport(Number.parseInt(e.target.value));
                        }}
                      />
                      <select
                        name="support_coupon_id"
                        data-cy="support-coupon-id"
                        value={supportCoupon}
                        onChange={(e) => {
                          handleTouch();
                          setSupportCoupon(e.target.value);
                        }}
                        className="form-control mt-2"
                        style={{
                          color: supportCoupon ? '#495057' : '#A9AEB6',
                          backgroundColor: '#EEF1F6',
                        }}
                        disabled={support === 0 || isNaN(support) || isFieldDisabled()}
                      >
                        <option value="" selected disabled hidden>
                          Choisir un coupon de réduction - Support
                        </option>
                        {couponValues.map((element) => (
                          <option
                            key={element.key}
                            value={element.value}
                            style={{ color: '#495057' }}
                          >
                            {element.value}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group mt-3">
                      <label>Formation au module Saqara</label>
                      <input
                        type="number"
                        step="1"
                        min="0"
                        className="form-control"
                        disabled={isFieldDisabled()}
                        autoComplete="off"
                        placeholder="Choisir un produit - Formation"
                        value={saqaraModule}
                        style={{ backgroundColor: '#EEF1F6' }}
                        onChange={(e) => {
                          handleTouch();
                          setSaqaraModule(Number.parseInt(e.target.value));
                        }}
                      />
                      <select
                        name="saqara_module_coupon_id"
                        data-cy="saqara-module-coupon-id"
                        value={saqaraModuleCoupon}
                        onChange={(e) => {
                          handleTouch();
                          setSaqaraModuleCoupon(e.target.value);
                        }}
                        className="form-control mt-2"
                        style={{
                          color: saqaraModuleCoupon ? '#495057' : '#A9AEB6',
                          backgroundColor: '#EEF1F6',
                        }}
                        disabled={saqaraModule === 0 || isNaN(saqaraModule) || isFieldDisabled()}
                      >
                        <option value="" selected disabled hidden>
                          Choisir un coupon de réduction - Formation
                        </option>
                        {couponValues.map((element) => (
                          <option
                            key={element.key}
                            value={element.value}
                            style={{ color: '#495057' }}
                          >
                            {element.value}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group mt-3">
                      <label>Interlocuteur à l&apos;année</label>
                      <input
                        type="number"
                        step="1"
                        min="0"
                        className="form-control"
                        disabled={isFieldDisabled()}
                        autoComplete="off"
                        placeholder="Choisir un produit - Interlocuteur à l'année"
                        value={interlocutor}
                        style={{ backgroundColor: '#EEF1F6' }}
                        onChange={(e) => {
                          handleTouch();
                          setInterlocutor(Number.parseInt(e.target.value));
                        }}
                      />
                      <select
                        name="interlocutor_coupon_id"
                        data-cy="interlocutor-coupon-id"
                        value={interlocutorCoupon}
                        onChange={(e) => {
                          handleTouch();
                          setInterlocutorCoupon(e.target.value);
                        }}
                        className="form-control mt-2"
                        style={{
                          color: interlocutorCoupon ? '#495057' : '#A9AEB6',
                          backgroundColor: '#EEF1F6',
                        }}
                        disabled={interlocutor === 0 || isNaN(interlocutor) || isFieldDisabled()}
                      >
                        <option value="" selected disabled hidden>
                          Choisir un coupon de réduction - Interlocuteur
                        </option>
                        {couponValues.map((element) => (
                          <option
                            key={element.key}
                            value={element.value}
                            style={{ color: '#495057' }}
                          >
                            {element.value}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group mt-3">
                      <label>Mise en service</label>
                      <input
                        type="number"
                        step="1"
                        min="0"
                        className="form-control"
                        autoComplete="off"
                        disabled={isFieldDisabled()}
                        placeholder="Choisir un produit - Mise en service"
                        value={commissioning}
                        style={{ backgroundColor: '#EEF1F6' }}
                        onChange={(e) => {
                          handleTouch();
                          setCommissioning(Number.parseInt(e.target.value));
                        }}
                      />
                      <select
                        name="commissioning_coupon_id"
                        data-cy="commissioning-coupon-id"
                        value={commissioningCoupon}
                        onChange={(e) => {
                          handleTouch();
                          setCommissioningCoupon(e.target.value);
                        }}
                        className="form-control mt-2"
                        style={{
                          color: commissioningCoupon ? '#495057' : '#A9AEB6',
                          backgroundColor: '#EEF1F6',
                        }}
                        disabled={commissioning === 0 || isNaN(commissioning) || isFieldDisabled()}
                      >
                        <option value="" selected disabled hidden>
                          Choisir un coupon de réduction - Mise en service
                        </option>
                        {couponValues.map((element) => (
                          <option
                            key={element.key}
                            value={element.value}
                            style={{ color: '#495057' }}
                          >
                            {element.value}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group mt-3">
                      <label className="pr-5">Paiement par SEPA autorisé</label>
                      <select
                        id="sepa-is-allowed"
                        name="sepa_is_allowed"
                        disabled={isFieldDisabled()}
                        value={isSepaAllowed ? 'true' : 'false'}
                        onChange={(e: { target: { value: string; }; }) => {
                          handleTouch();
                          setIsSepaAllowed(e.target.value === 'true');
                        }}
                      >
                        <option value="true">Oui</option>
                        <option value="false">Non</option>
                      </select>
                    </div>
                  </>
                )}
              </>
            )}

            {paymentType === 'Virement' && (
              <>
                <div className="form-group mt-3">
                  <label>Fréquence de paiement</label>
                  <select
                    name="payment_frequence_id"
                    data-cy="payment-frequence-id"
                    value={stripeFormula}
                    disabled={isFieldDisabled()}
                    onChange={(e) => {
                      handleTouch();
                      setStripeFormula(e.target.value);
                    }}
                    className="form-control"
                    style={{
                      color: stripeFormula ? '#495057' : '#A9AEB6',
                      backgroundColor: '#EEF1F6',
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Choisir une formule Stripe
                    </option>
                    {formulaValues.map((element) => (
                      <option key={element.key} value={element.value} style={{ color: '#495057' }}>
                        {element.value}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group mt-3">
                  <label>Régions</label>
                  <input
                    type="number"
                    step="1"
                    min="1"
                    className="form-control"
                    disabled={isFieldDisabled()}
                    autoComplete="off"
                    placeholder="Nombre de régions"
                    value={numberRegions ?? 0}
                    style={{ backgroundColor: '#EEF1F6' }}
                    onChange={(e) => {
                      handleTouch();
                      setNumberRegions(Number.parseInt(e.target.value));
                    }}
                  />
                  <select
                    name="region_select_id"
                    data-cy="region-select-id"
                    value={regions}
                    disabled={isFieldDisabled()}
                    multiple
                    onChange={(e) => {
                      handleTouch();
                      handleSetRegions(e.target.selectedOptions);
                      setDepartments([]);
                    }}
                    className="form-control mt-2"
                    style={{
                      color: regions ? '#495057' : '#A9AEB6',
                      backgroundColor: '#EEF1F6',
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Choisir les régions
                    </option>
                    {regionValues &&
                      regionValues.map((element: { key: any; value: any; }) => (
                        <option
                          key={element.key}
                          value={element.value}
                          style={{ color: '#495057' }}
                        >
                          {element.value}
                        </option>
                      ))}
                  </select>
                  <p style={{ marginTop: '5px', fontStyle: 'italic', marginBottom: '5px' }}>
                    Régions sélectionnées: {regions.toString()}
                  </p>
                  <p style={{ fontStyle: 'italic' }}>
                    Vous devez sélectionner {numberRegions} région{numberRegions > 1 ? 's' : ''}
                  </p>
                  {numberRegions === 1 && (
                    <>
                      <select
                        name="department_select_id"
                        data-cy="department-select-id"
                        value={departments}
                        multiple
                        disabled={isFieldDisabled()}
                        onChange={(e) => {
                          handleTouch();
                          handleSetDepartments(e.target.selectedOptions);
                          setRegions([]);
                        }}
                        className="form-control mt-2"
                        style={{
                          color: departments ? '#495057' : '#A9AEB6',
                          backgroundColor: '#EEF1F6',
                        }}
                      >
                        <option value="" selected disabled hidden>
                          Choisir les départements
                        </option>
                        {departmentValues &&
                          departmentValues.map((element: { key: any; value: any; }) => (
                            <option
                              key={element.key}
                              value={element.value}
                              style={{ color: '#495057' }}
                            >
                              {element.value}
                            </option>
                          ))}
                      </select>
                      <p style={{ marginTop: '5px', fontStyle: 'italic', marginBottom: '5px' }}>
                        Départements sélectionnés: {departments.toString()}
                      </p>
                      <p style={{ fontStyle: 'italic' }}>Vous devez sélectionner 8 départements</p>
                    </>
                  )}
                </div>

                <div className="form-group mt-3">
                  <label>Lots</label>
                  <select
                    name="trades_id"
                    data-cy="trades-id"
                    value={trades}
                    disabled={isFieldDisabled()}
                    multiple
                    onChange={(e) => {
                      handleTouch();
                      handleSetTrades(e.target.selectedOptions);
                    }}
                    className="form-control mt-2"
                    style={{ color: trades ? '#495057' : '#A9AEB6', backgroundColor: '#EEF1F6' }}
                  >
                    <option value="" selected disabled hidden>
                      Choisir les lots
                    </option>
                    {tradesValues &&
                      tradesValues.map((element: { key: any; value: any; }) => (
                        <option
                          key={element.key}
                          value={element.value}
                          style={{ color: '#495057' }}
                        >
                          {element.value}
                        </option>
                      ))}
                  </select>
                </div>

                <div className="form-group mt-3">
                  <label>Utilisateurs</label>
                  <p className="mb-0" style={{ fontSize: '13px', fontStyle: 'italic' }}>
                    Utilisateur de base (non modifiable)
                  </p>
                  <input
                    type="number"
                    step="1"
                    className="form-control"
                    autoComplete="off"
                    placeholder="Nombre d'utilisateurs"
                    value={numberUser}
                    style={{ backgroundColor: '#EEF1F6', marginTop: '0px' }}
                    disabled
                  />
                  <p className="mb-0" style={{ fontSize: '13px', fontStyle: 'italic' }}>
                    Utilisateurs supplémentaires
                  </p>
                  <input
                    type="number"
                    step="1"
                    min="0"
                    className="form-control"
                    autoComplete="off"
                    placeholder="Nombre d'utilisateurs"
                    disabled={isFieldDisabled()}
                    value={moreUser}
                    style={{ backgroundColor: '#EEF1F6', marginTop: '0px' }}
                    onChange={(e) => {
                      handleTouch();
                      setMoreUser(Number.parseInt(e.target.value));
                    }}
                  />
                </div>

                <div className="form-group mt-3">
                  <label>Support et Assistance téléphonique</label>
                  <input
                    type="number"
                    step="1"
                    min="0"
                    className="form-control"
                    disabled={isFieldDisabled()}
                    autoComplete="off"
                    placeholder="Choisir un produit - Support"
                    value={support}
                    style={{ backgroundColor: '#EEF1F6' }}
                    onChange={(e) => {
                      handleTouch();
                      setSupport(Number.parseInt(e.target.value));
                    }}
                  />
                </div>

                <div className="form-group mt-3">
                  <label>Formation au module Saqara</label>
                  <input
                    type="number"
                    step="1"
                    min="0"
                    className="form-control"
                    disabled={isFieldDisabled()}
                    autoComplete="off"
                    placeholder="Choisir un produit - Formation"
                    value={saqaraModule}
                    style={{ backgroundColor: '#EEF1F6' }}
                    onChange={(e) => {
                      handleTouch();
                      setSaqaraModule(Number.parseInt(e.target.value));
                    }}
                  />
                </div>

                <div className="form-group mt-3">
                  <label>Interlocuteur à l&apos;année</label>
                  <input
                    type="number"
                    step="1"
                    min="0"
                    className="form-control"
                    disabled={isFieldDisabled()}
                    autoComplete="off"
                    placeholder="Choisir un produit - Interlocuteur à l'année"
                    value={interlocutor}
                    style={{ backgroundColor: '#EEF1F6' }}
                    onChange={(e) => {
                      handleTouch();
                      setInterlocutor(Number.parseInt(e.target.value));
                    }}
                  />
                </div>

                <div className="form-group mt-3">
                  <label>Mise en service</label>
                  <input
                    type="number"
                    step="1"
                    min="0"
                    className="form-control"
                    disabled={isFieldDisabled()}
                    autoComplete="off"
                    placeholder="Choisir un produit - Mise en service"
                    value={commissioning}
                    style={{ backgroundColor: '#EEF1F6' }}
                    onChange={(e) => {
                      handleTouch();
                      setCommissioning(Number.parseInt(e.target.value));
                    }}
                  />
                </div>
              </>
            )}

            {(openSettings || paymentType === 'Virement') && (
              <>
                {!estimation ? (
                  <label style={{ color: '#007bff', cursor: 'pointer' }}>
                    <input
                      type="file"
                      onChange={handleFileChange}
                      style={{ display: 'none' }}
                      disabled={isFieldDisabled()}
                    />
                    Importer un devis{' '}
                    {paymentType === 'CB'
                      ? ' (Non obligatoire pour validation)'
                      : ' (Obligatoire pour validation)'}
                  </label>
                ) : (
                  <div style={{ display: 'flex', gap: '8px' }}>
                    <span>{estimation + ' '}</span>
                    <label
                      style={{ color: '#007bff', cursor: 'pointer' }}
                      onClick={() => setDisplayPdf(true)}
                    >
                      Voir le devis{' '}
                    </label>
                    <label style={{ color: '#007bff', cursor: 'pointer' }}>
                      <input
                        type="file"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                        disabled={isFieldDisabled()}
                      />
                      Remplacer
                    </label>
                  </div>
                )}
                <button
                  className="btn btn-primary mt-2"
                  id="btn-update-proposed-prices"
                  onClick={() => sendOffer()}
                  disabled={!isEnabled()}
                >
                  {offer && offer.id && offer.status === 'pending'
                    ? "Mettre à jour l'offre"
                    : "Valider et envoyer l'offre"}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingFormV2;
