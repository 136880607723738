import React, { useState } from 'react';
import Modal from './index';
import { Link } from 'react-router-dom';

/**
 * This react component is used to swap subscription to annual.
 * @param props
 */
const UserManagerModal: React.FC<{
  email: string;
  hidden: boolean;
  userId: string;
  closeModal: () => void;
}> = ({ hidden, email, userId, closeModal }) => {
  const [mail, setMail] = useState<string | null>('');

  return (
    <Modal hidden={hidden} maxWidth={900} closeModal={closeModal}>
      <div className="modal-body" style={{ padding: '40px 56px 40px 56px' }}>
        <>
          <div className="justify-content-md-center">
            <img src="/Icons/warning-red.svg" alt="Icône danger" />
            <div className="mt-3">
              Un utilisateur de Chantier Privé existe déjà avec l&apos;email : <b>{email}</b>
            </div>
            <br />
            <p>
              <b>
                Cet utilisateur possède déjà un abonnement et/ou un manager, il n&apos;est donc pas
                possible de le lier à cette offre
              </b>
            </p>
            <Link to={`/admin/utilisateurs/${userId}`} target="_blank" rel="noopener noreferrer">
              Voir la fiche de l&apos;utilisateur dans un nouvel onglet
            </Link>
          </div>
          <div className="d-flex justify-content-center">
            <button
              type="button"
              data-cy="button-cancel"
              id="button-cancel"
              className="btn btn-secondary mt-3"
              style={{ maxWidth: 240 }}
              onClick={closeModal}
            >
              Annuler
            </button>
          </div>
        </>
      </div>
    </Modal>
  );
};

export default UserManagerModal;
