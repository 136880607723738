import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Pagination from '../../common/navigation/Pagination';
import CircularLoader from '../../common/navigation/CircularLoader';
import Info from '../navigation/Info';
import { BACKEND_URL } from '../../config//environment';
import { formatDate } from '../../constants';

/**
 * Load deleted users and show them in a responsive table.
 */
const DeletedUsers: React.FC = () => {
  const [deletedUsers, setDeletedUsers] = useState<Array<IDeletedUser>>();
  const [lastPage, setLastPage] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);

  /**
   * Load deleted users.
   */
  useEffect(() => {
    if (!deletedUsers) {
      changePage(1);
    }
  }, []);

  /**
   * Load deleted users in a specific page with a query string filter.
   * @param page Current page.
   */
  const changePage = (page: number) => {
    setLoading(true);
    setCurrentPage(page);

    let url = `${BACKEND_URL}/api/admin/deleted-users?page=${page}`;

    if (searchQuery.length > 0) url += `&searchQuery=${searchQuery}`;

    axios.get<IPaginateDeletedUser>(url).then(({ data }) => {
      setDeletedUsers(data.deleted_users.data);
      setLastPage(data.deleted_users.last_page);
      setLoading(false);
    });
  };

  /**
   * Triggered when the user press a touch to detect if it is "Enter". It it is, is starts a search.
   * @param key Key pressed.
   */
  const searchUser = (key: string) => {
    if (key === 'Enter' || searchQuery === '') {
      changePage(1);
    }
  };

  return (
    <>
      <div className="alert alert-info d-flex justify-content-between">
        Retrouvez ici la liste de tous les désinscrits.
        <a href="/admin/exports">Exporter la liste de tous les désinscrits en Excel</a>
      </div>

      <div className="card card-body">
        <div className="row">
          <div className="col-sm-8 col-lg-10">
            <input
              type="text"
              className="form-control auth-input mt-0"
              id="text-field-search"
              data-cy="text-field-search"
              placeholder="Rechercher un utilisateur..."
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyUp={(e) => searchUser(e.key)}
            />
          </div>

          <div className="col-sm-4 col-lg-2">
            <button className="btn btn-primary" id="btn-search" onClick={() => changePage(1)}>
              Rechercher
            </button>
          </div>
        </div>

        {loading && <CircularLoader />}

        {!loading && deletedUsers && deletedUsers.length === 0 && (
          <Info text="Aucun utilisateur supprimé n'a été trouvé." />
        )}

        {!loading && deletedUsers && deletedUsers.length > 0 && (
          <div className="table-responsive">
            <table className="table table-hover table-bordered mt-2">
              <thead>
                <tr>
                  <th scope="col">Adresse e-mail</th>
                  <th scope="col">Date de suppression</th>
                  <th scope="col">Date d&apos;annulation</th>
                  <th scope="col">Raison</th>
                  <th scope="col">Sous Raison</th>
                  <th scope="col">Autre Commentaire</th>
                  <th scope="col">Date de demande</th>
                </tr>
              </thead>
              <tbody>
                {deletedUsers &&
                  deletedUsers.length > 0 &&
                  deletedUsers.map((deletedUser) => (
                    <tr key={deletedUser.id}>
                      <td style={deletedUser.archived_at ? { color: 'red' } : { color: 'green' }}>
                        {deletedUser.mail}
                      </td>
                      <td>{deletedUser.archived_at ?? 'Pas encore supprimé'}</td>
                      <td>{deletedUser.canceled_at ?? '/'}</td>
                      <td>{deletedUser.reason}</td>
                      <td>{deletedUser.sub_reason}</td>
                      <td>{deletedUser.other_comment}</td>
                      <td>{formatDate(new Date(deletedUser.created_at), 'DD/MM/YYYY HH:mm:ss')}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {lastPage > 0 && (
        <Pagination
          lastPage={lastPage}
          currentPage={currentPage}
          toCall={(page) => changePage(page)}
        />
      )}
    </>
  );
};

export default DeletedUsers;
