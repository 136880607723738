import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import axios from 'axios';
import ErrorMessage from '../../common/errors/ErrorMessage';
import { BACKEND_URL } from '../../config//environment';

const CreateFAQForm: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { cat, subCat } = queryString.parse(location.search);
  const [error, setError] = useState<string>(null);
  const [categories, setCategories] = useState<Array<IFaqCategory> | null>(null);
  const [subCategories, setSubCategories] = useState<Array<IFaqCategory> | null>(null);
  const [question, setQuestion] = useState<IFaqQuestion>({
    id: '',
    question: '',
    answer: '',
    slug: '',
    visible: true,
    public_visibility: false,
    app_visibility: true,
    faq_category_id: subCat,
    main_category: cat,
    created_at: new Date(),
    updated_at: new Date(),
  });

  const getCategories = (mainId: string = null) =>
    axios.get(
      `${BACKEND_URL}/api/admin/faq-categories`,
      mainId && {
        params: {
          id: mainId,
        },
      }
    );

  useEffect(() => {
    if (!categories) {
      getCategories().then(({ data }) => {
        setCategories(data.faq_categories);

        if (cat) {
          setQuestion({ ...question, main_category: cat });
          loadSubCategories(cat);
        }
      });
    }
  });

  const createFaqCategory = () => {
    axios
      .post(`${BACKEND_URL}/api/admin/faq-questions`, {
        question: question.question,
        answer: question.answer,
        faq_category_id: question.faq_category_id,
        visible: question.visible,
        public_visibility: question.public_visibility,
        app_visibility: question.app_visibility,
      })
      .then(() => {
        history.push('/admin/faq');
      })
      .catch((err) => {
        const error = err?.response?.data?.errorMessage;
        setError(error ? error : 'Une erreur est survenue.');
      });
  };

  const handleCategoryChange = (event: React.ChangeEvent) => {
    const target = event.target as HTMLSelectElement;
    const { value } = target;

    loadSubCategories(value);
  };

  const handleInputChange = (event: React.ChangeEvent) => {
    const input = event.target as HTMLInputElement;
    const { name } = input;
    let { value }: { value: boolean | string } = input;
    const theBooleans = ['visible', 'app_visibility', 'public_visibility'];
    if (theBooleans.includes(name)) {
      value = value === 'true';
    }
    setQuestion({
      ...question,
      [name]: value,
    });
  };

  const loadSubCategories = (value: string) => {
    setQuestion({
      ...question,
      main_category: value,
    });
    getCategories(value).then(({ data }) => {
      setSubCategories(data.faq_categories);
    });
  };

  const boolToStr = (v: boolean) => (v ? 'true' : 'false');

  return (
    <div className="card">
      <div className="card-header">Ajouter Nouvelle Question</div>
      <div className="card-body">
        <div className="row">
          <div className="col">
            {error && <ErrorMessage text={error} />}
            <div>
              <div className="form-row">
                <label className="col-md-3 mt-3" htmlFor="category">
                  Catégorie
                </label>
                <select
                  className="form-control col-md-3"
                  name="main_category"
                  data-cy="category"
                  id="category"
                  onChange={handleCategoryChange}
                  value={question.main_category}
                >
                  <option hidden></option>
                  {categories &&
                    categories.map((cat: IFaqCategory) => (
                      <option key={cat.id} value={cat.id}>
                        {cat.title}
                      </option>
                    ))}
                </select>
                <label className="col-md-3 mt-3" htmlFor="faq_category_id">
                  Sous-Catégorie
                </label>
                <select
                  className="form-control col-md-3 mb-3"
                  name="faq_category_id"
                  data-cy="faq_category"
                  id="faq_category_id"
                  value={question.faq_category_id}
                  onChange={handleInputChange}
                >
                  {subCategories &&
                    subCategories.map((cat: IFaqCategory) => {
                      const id = cat.id;
                      return (
                        <option key={id} value={id}>
                          {cat.title}
                        </option>
                      );
                    })}
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="question">Question</label>
                <div className="input-group">
                  <input
                    type="text"
                    id="question"
                    name="question"
                    className="form-control"
                    value={question.question}
                    onChange={handleInputChange}
                  />
                  <div className="input-group-append mt-2 pt-1">
                    <div className="input-group-text">?</div>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="answer">Réponse</label>
                <textarea
                  id="answer"
                  name="answer"
                  className=" form-control"
                  rows={3}
                  value={question.answer}
                  onChange={handleInputChange}
                />
              </div>

              <div className="form-group row">
                <label className="col-md-3 mt-3" htmlFor="public_visibility">
                  Accessible sur le site Public
                </label>
                <div className="col-md-3">
                  <select
                    className="form-control"
                    id="public_visibility"
                    name="public_visibility"
                    value={boolToStr(question.public_visibility)}
                    onChange={handleInputChange}
                  >
                    <option value="true">Oui</option>
                    <option value="false">Non</option>
                  </select>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-md-3 mt-3" htmlFor="app_visibility">
                  Accessible sur Application
                </label>
                <div className="col-md-3">
                  <select
                    className="form-control"
                    id="app_visibilityp"
                    name="app_visibility"
                    value={boolToStr(question.app_visibility)}
                    onChange={handleInputChange}
                  >
                    <option value="true">Oui</option>
                    <option value="false">Non</option>
                  </select>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-md-3 mt-3" htmlFor="visible">
                  Visible
                </label>
                <div className="col-md-3">
                  <select
                    className="form-control"
                    id="visible"
                    name="visible"
                    value={boolToStr(question.visible)}
                    onChange={handleInputChange}
                  >
                    <option value="true">Oui</option>
                    <option value="false">Non</option>
                  </select>
                </div>
              </div>

              <div className="row justify-content-md-center mt-4">
                <div className="col-lg-6">
                  <button onClick={createFaqCategory} className="btn btn-primary">
                    Ajouter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateFAQForm;
