import React, { useState } from 'react';
import Marker from 'react-google-maps/lib/components/Marker';
import InfoWindow from 'react-google-maps/lib/components/InfoWindow';

const ProjectMarkerWithInfoWindow: React.FC<{ project: IProject }> = ({ project }) => {
  const [infoWindowIsOpen, setInfoWindowIsOpen] = useState(false);
  const showInfoWindow = () => {
    setInfoWindowIsOpen(!infoWindowIsOpen);
  };

  return (
    <Marker
      key={project.name}
      title={project.name}
      label={{ text: project.name, color: '#fff' }}
      position={{ lat: project.latitude, lng: project.longitude }}
      animation={window.google.maps.Animation.DROP}
      onClick={showInfoWindow}
      icon="https://cdn.mapmarker.io/api/v1/pin?size=45&background=%230062B1&icon=fa-circle&color=%23FFFFFF&voffset=0&hoffset=0&"
    >
      {infoWindowIsOpen ? (
        <InfoWindow key={project.id + '-info-window'} onCloseClick={showInfoWindow}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '100%',
            }}
          >
            <h1
              style={{
                fontSize: '18px',
                fontWeight: 'bold',
                height: 'fit-content',
                textAlign: 'center',
              }}
            >
              {project.name}
            </h1>
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <h2 style={{ padding: '5%', fontSize: '16px', fontWeight: 'bold', margin: 'auto' }}>
                  Project Info
                </h2>
                <span style={{ wordBreak: 'break-word' }}>
                  <b>{'Address: '}</b>
                  {project.address}
                </span>
                <span style={{ wordBreak: 'break-word' }}>
                  <b>{'Zip Code: '}</b>
                  {project.zip_code}
                </span>
                <span style={{ wordBreak: 'break-word' }}>
                  <b>{'City: '}</b>
                  {project.city}
                </span>
              </div>
            </div>
          </div>
        </InfoWindow>
      ) : null}
    </Marker>
  );
};

export default ProjectMarkerWithInfoWindow;
