import { IAOSOps } from '../interfaces/IAOSOps';

export const AOSOps: Array<IAOSOps> = [
  {
    id: 16,
    name: 'Charlotte Arnould',
  },
  {
    id: 12,
    name: 'Laura Tramier',
  },
  {
    id: 6,
    name: 'Juan Medina',
  },
  {
    id: 17,
    name: 'Thomas Nouet',
  },
  {
    id: 11,
    name: 'Fanny Rambert',
  },
  {
    id: 7,
    name: 'Chloé Monimart',
  },
  {
    id: 28,
    name: 'Nathanael Bouillot',
  },
  {
    id: 40,
    name: 'Salma Slimani',
  },
  {
    id: 74,
    name: 'Hudea Manceau',
  },
];
