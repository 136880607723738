import axios from 'axios';
import React, { useContext } from 'react';
import { UserContext } from '../../common/providers/UserProvider';
import { BACKEND_URL } from '../../config/environment';

interface Props {
  children: React.ReactNode;
}

const LogASTester: React.FC<Props> = ({ children }): any => {
  const { user } = useContext(UserContext);

  const bannerStyle = ({
    width: '450px',
    position: 'absolute',
    margin: 'auto',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    height: '100px',
    textAlign: 'center',
    zIndex: '999',
  } as unknown) as React.CSSProperties;

  const shouldDisplayBanner = () => {
    return user && user.is_testing_as === true;
  };

  const disableLogAs = () => {
    axios.get(`${BACKEND_URL}/api/test-as-user/disable`).then((): void => {
      window.location.reload();
    });
  };

  const showProperBanner = (user: IUser) => {
    return (
      <div className="alert alert-danger" style={bannerStyle}>
        <span>
          Vous êtes en train d&apos;utiliser l&apos;application avec le profil de <b>{user.mail}</b>{' '}
          au lieu de votre propre compte admin.{' '}
        </span>
        <a href="#" onClick={disableLogAs}>
          Désactiver et retourner à l&apos;admin
        </a>
      </div>
    );
  };

  return shouldDisplayBanner() ? showProperBanner(user) : children;
  // // return showProperBanner(user);
  // return children;
};

export default LogASTester;
