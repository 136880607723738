import React, { useContext } from 'react';
import { UserContext } from '../providers/UserProvider';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import Footer from '../navigation/Footer';
import CircularLoader from '../navigation/CircularLoader';
//import { SubscriptionContext } from '../providers/SubscriptionProvider';

interface PrivateRouteProps extends RouteProps {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  component?: any;
  exact?: boolean;
  path: string;
  enableWrapper?: boolean;
}

// Redirect to /login if user is not logged in
const PrivateAdminRoute: React.FC<PrivateRouteProps> = ({
  component,
  path,
  exact = false,
  enableWrapper = true,
}) => {
  const Component = component;
  const { user, fetched } = useContext(UserContext);

  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => {
        if (!fetched) {
          return (
            <>
              <div className="container-fluid">
                <div className="container-center">
                  <div className="content">
                    <div className="content-spinner">
                      <CircularLoader />
                    </div>
                    <Footer />
                  </div>
                </div>
              </div>
            </>
          );
        }
        if (user) {
          return <Component {...props} user={user} />;
        } else {
          return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
        }
      }}
    />
  );
};

export default PrivateAdminRoute;
