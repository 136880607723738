import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Info from '../navigation/Info';
import ApiUserTokensModal from './ApiUserTokensModal';
import CircularLoader from '../../common/navigation/CircularLoader';
import { BACKEND_URL } from '../../config//environment';

const ApiUsersList: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<Array<IApiUser>>([]);
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<IApiUser>(null);

  const handleOnDeleteClick = (e: React.MouseEvent, id: string) => {
    e.preventDefault();

    if (window.confirm('Voulez-vous vraiment supprimer ?')) {
      axios
        .delete(`${BACKEND_URL}/api/admin/api-users/${id}`)
        .then(() => setUsers(users.filter((user: IApiUser) => user.id !== id)));
    }
  };

  const handleOnTokensClick = (e: React.MouseEvent, user: IApiUser) => {
    e.preventDefault();

    setSelectedUser(user);
    setDisplayModal(true);
  };

  const handleOnTokenChange = (updatedUser: IApiUser) => {
    const newUserState = {
      ...selectedUser,
      tokens: updatedUser.tokens,
    };
    setSelectedUser(newUserState);
    setUsers(
      users.map((user) => {
        if (user.id === updatedUser.id) {
          return newUserState;
        }

        return user;
      })
    );
  };

  useEffect(() => {
    setLoading(true);
    axios.get(`${BACKEND_URL}/api/admin/api-users`).then(({ data }) => {
      setUsers(data.users);
      setLoading(false);
    });
  }, []);

  return (
    <>
      <div className="alert alert-info d-flex justify-content-between">
        Retrouvez ici la liste de tous les utilisateurs d&apos;accès API.
      </div>

      <div className="card card-body">
        <div className="row mb-2">
          <div className="col-sm-3">
            <Link to="/admin/api-users/create" className="btn btn-primary" id="add-api-user-link">
              Ajouter un Accès API
            </Link>
          </div>
        </div>

        {users.length === 0 &&
          (loading ? <CircularLoader /> : <Info text="Aucun 'API USER' n'a été trouvé." />)}

        {users && users.length > 0 && (
          <div className="table-responsive">
            <table className="table table-hover table-bordered mt-2">
              <thead>
                <tr>
                  <th scope="col">Identifiant</th>
                  <th scope="col">Dernière Connexion</th>
                  <th scope="col">Tokens</th>
                  <th scope="col">Supprimer</th>
                </tr>
              </thead>

              <tbody>
                {users.map((user: IApiUser, i: number) => (
                  <tr key={`api-user-${i}`}>
                    <td>{user.username}</td>
                    <td>
                      {user.tokens_max_last_used_at
                        ? new Date(user.tokens_max_last_used_at).toLocaleString()
                        : '-'}
                    </td>
                    <td>
                      {user.tokens.length > 0 ? (
                        <Link to="#" onClick={(e) => handleOnTokensClick(e, user)}>
                          Tokens
                        </Link>
                      ) : (
                        <span className="text-danger">N/A</span>
                      )}
                    </td>
                    <td>
                      <Link to="#" onClick={(e) => handleOnDeleteClick(e, user.id)}>
                        Supprimer
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <ApiUserTokensModal
        hidden={displayModal}
        user={selectedUser}
        closeModal={() => setDisplayModal(false)}
        onChange={handleOnTokenChange}
      />
    </>
  );
};

export default ApiUsersList;
