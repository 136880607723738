import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import Pagination from '../../common/navigation/Pagination';
import CircularLoader from '../../common/navigation/CircularLoader';
import Info from '../navigation/Info';
import AddNewUserModal from '../../common/Modal/AddNewUserModal';
import { Link } from 'react-router-dom';
import { ToastContext } from '../../common/providers/ToastProvider';
import { BACKEND_URL } from '../../config//environment';

/**
 * Load invited users and show them in a responsive table.
 */
const InvitedUsers: React.FC = () => {
  const [invitedUsers, setInvitedUsers] = useState<Array<IInvitedUser>>();
  const [lastPage, setLastPage] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const { displayToast } = useContext(ToastContext);

  /**
   * Load invited users.
   */
  useEffect(() => {
    if (!invitedUsers) {
      changePage(1);
    }
  }, []);

  /**
   * Load invited users in a specific page with a query string filter.
   * @param page Current page.
   */
  const changePage = (page: number) => {
    setLoading(true);
    setCurrentPage(page);

    let url = `${BACKEND_URL}/api/admin/invited-users?page=${page}`;

    if (searchQuery.length > 0) url += `&searchQuery=${searchQuery}`;

    axios.get<IPaginateInvitedUser>(url).then(({ data }) => {
      setInvitedUsers(data.invited_users.data);
      setLastPage(data.invited_users.last_page);
      setLoading(false);
    });
  };

  /**
   * Triggered when the user press a touch to detect if it is "Enter". It it is, is starts a search.
   * @param key Key pressed.
   */
  const searchUser = (key: string) => {
    if (key === 'Enter' || searchQuery === '') {
      changePage(1);
    }
  };

  /**
   * Relaunch User.
   * @param id ID of the user.
   */
  const relaunchUser = (id: string) => {
    setLoading(true);
    axios.put(`${BACKEND_URL}/api/admin/relaunch-users/${id}`).then(() => {
      changePage(1);
      setLoading(false);
    });
  };

  const handleCopyLink = (e: React.MouseEvent<HTMLAnchorElement>, mail: string) => {
    e.preventDefault();
    const url = `${process.env.DOMAIN_APP_URL}/registration/complete?email=${mail}`;
    navigator.clipboard.writeText(url);

    displayToast("Lien d'activation a été Copié");
  };

  return (
    <>
      <div className="alert alert-info d-flex justify-content-between">
        Retrouvez ici la liste de tous les utilisateurs invités.
        <a href="/admin/exports">Exporter la liste de tous les utilisateurs invités en Excel</a>
      </div>

      <AddNewUserModal
        hidden={open}
        closeModal={() => setOpen(false)}
        refresh={() => changePage(1)}
      />

      <div className="card card-body">
        <div className="row">
          <div className="col-sm-4 col-lg-7">
            <input
              type="text"
              className="form-control auth-input mt-0"
              id="text-field-search"
              data-cy="text-field-search"
              placeholder="Rechercher un utilisateur..."
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyUp={(e) => searchUser(e.key)}
            />
          </div>

          <div className="col-sm-4 col-lg-2">
            <button className="btn btn-primary" id="btn-search" onClick={() => changePage(1)}>
              Rechercher
            </button>
          </div>

          <div className="col-sm-4 col-lg-3">
            <button className="btn btn-secondary" id="btn-search" onClick={() => setOpen(true)}>
              Ajouter un utilisateur
            </button>
          </div>
        </div>

        {loading && <CircularLoader />}

        {!loading && invitedUsers && invitedUsers.length === 0 && (
          <Info text="Aucun utilisateur invité n'a été trouvé." />
        )}

        {!loading && invitedUsers && invitedUsers.length > 0 && (
          <div className="table-responsive">
            <table className="table table-hover table-bordered mt-2">
              <thead>
                <tr>
                  <th scope="col">Adresse e-mail</th>
                  <th scope="col">Date d’invitation</th>
                  <th scope="col">Date de dernière relance</th>
                  <th scope="col">Lien d’activation</th>
                  <th scope="col">Admin</th>
                  <th scope="col">Relancer</th>
                </tr>
              </thead>
              <tbody>
                {invitedUsers &&
                  invitedUsers.length > 0 &&
                  invitedUsers.map((invitedUser) => (
                    <tr key={invitedUser.id}>
                      <td>{invitedUser.mail}</td>
                      <td>{invitedUser.invitation_sent_at ?? 'Pas encore supprimé'}</td>
                      <td>{invitedUser.relaunch_at ?? '/'}</td>
                      <td>
                        {!invitedUser.deleted_at && (
                          <a href="#" onClick={(e) => handleCopyLink(e, invitedUser.mail)}>
                            Lien
                          </a>
                        )}
                      </td>
                      <td>{invitedUser.cp_admin_mail}</td>
                      <td>
                        {!invitedUser.relaunch_at ||
                          (!invitedUser.deleted_at && (
                            <Link
                              to="#"
                              className="selector-link-activate-email"
                              onClick={() => relaunchUser(invitedUser.id)}
                            >
                              Relancer
                            </Link>
                          ))}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {lastPage > 0 && (
        <Pagination
          lastPage={lastPage}
          currentPage={currentPage}
          toCall={(page) => changePage(page)}
        />
      )}
    </>
  );
};

export default InvitedUsers;
