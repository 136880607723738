import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from './index';
import CircularLoader from '../navigation/CircularLoader';
import { BACKEND_URL } from '../../config/environment';
import { Link } from 'react-router-dom';

/**
 * This react component is used to swap subscription to annual.
 * @param props
 */
const UserRegisteredModal: React.FC<{
  manager: IUser;
  email: string;
  userId: string;
  hidden: boolean;
  closeModal: () => void;
  onSubmit: () => void;
}> = ({ manager, hidden, userId, email, closeModal, onSubmit }) => {
  const [error, setError] = useState<string>('');

  const sendAttachMemberRequest = (managerId: string) => {
    axios
      .post(`${BACKEND_URL}/api/admin/users/${managerId}/role`, { email: email })
      .then((data) => {
        closeModal();
        onSubmit();
      })
      .catch(() => {
        setError('Une erreur est survenue');
      });
  };

  const handleOnClick = () => {
    sendAttachMemberRequest(manager.id);
  };

  return (
    <Modal hidden={hidden} maxWidth={900} closeModal={closeModal}>
      <div className="modal-body" style={{ padding: '40px 56px 40px 56px' }}>
        <>
          <div className="justify-content-md-center">
            <img src="/Icons/infoV2.svg" alt="Icône danger" />
            <p className="mt-3">
              Un utilisateur de Chantier Privé sans abonnement existe déjà avec l&apos;email:{' '}
              <b>{email}</b>
            </p>
            <Link to={`/admin/utilisateurs/${userId}`} target="_blank" rel="noopener noreferrer">
              Voir la fiche de l&apos;utilisateur dans un nouvel onglet
            </Link>
            <br />
            <br />
            <p>Êtes-vous sûr de vouloir le lier à ce manager?</p>
            <span className="text-danger" hidden={!error}>
              {error}
            </span>
          </div>
          <div className="d-flex justify-content-center">
            <button
              type="button"
              data-cy="button-cancel"
              id="button-cancel"
              className="btn btn-secondary mt-3"
              style={{ maxWidth: 240 }}
              onClick={() => {
                setError('');
                closeModal();
              }}
            >
              Annuler
            </button>
            <button
              type="button"
              data-cy="button-edit-password"
              id="button-edit-password"
              className="btn btn-primary mt-3"
              style={{ maxWidth: 240 }}
              onClick={handleOnClick}
            >
              Valider
            </button>
          </div>
        </>
      </div>
    </Modal>
  );
};

export default UserRegisteredModal;
