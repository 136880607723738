import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CircularLoader from '../../common/navigation/CircularLoader';
import { BACKEND_URL } from '../../config//environment';

const Exports: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [displayNotice, setDisplayNotice] = useState<boolean>(false);
  const [exportType, setExportType] = useState<string>('users');
  const [exportedFiles, setExportedFiles] = useState<Array<IExportedFile>>([]);

  useEffect(() => {
    getExportedFiles();
  }, []);

  const getExportedFiles = () => {
    setLoading(true);
    axios
      .get(`${BACKEND_URL}/api/admin/exported-files`)
      .then(({ data }) => {
        setExportedFiles(data.exported_files);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createExportedFiles = () => {
    setLoading(true);
    axios
      .post(`${BACKEND_URL}/api/admin/exported-files`, { type: exportType })
      .then(() => {
        getExportedFiles();
        setDisplayNotice(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getStatusLabel = (exportedFile: IExportedFile) => {
    switch (exportedFile.status) {
      case 0:
        return 'En traitement';
      case 1:
        return 'Disponible';
      case 2:
        return 'Expiré';
    }
  };

  const handleFileLinkClick = (file: IExportedFile) => {
    downloadExportedFile(file.id);
  };

  const downloadExportedFile = (fileID: string) => {
    axios.get(`${BACKEND_URL}/api/admin/exported-files/${fileID}`).then(({ data }) => {
      const newWindow = window.open(data, '_blank', 'noopener,noreferrer');

      if (newWindow) {
        newWindow.opener = null;
      }
    });
  };

  return (
    <>
      <h2 className="mb-3">Exports</h2>

      <div className="alert alert-info d-flex justify-content-between">
        Retrouvez ici l&apos;historique de vos exports de données.
      </div>

      <div className="d-flex align-items-center">
        <div style={{ flexGrow: 1 }} className="d-flex align-items-center">
          <select
            style={{ width: 200 }}
            className="form-control mt-0 mr-2"
            id="export_type"
            name="export_type"
            placeholder="Sélectionner un type d'export"
            onChange={(event) => setExportType(event.currentTarget.value)}
          >
            <option value="users">Utilisateurs</option>
            <option value="subscribed_users">Utilisateurs abonnées</option>
            <option value="deleted_users">Utilisateurs supprimés</option>
            <option value="unsubscribed_users">Utilisateurs désabonnés</option>
            <option value="invited_users">Utilisateurs invités</option>
            <option value="invoices">Factures</option>
            <option value="ended_subscription_users">Utilisateurs abonnements terminés</option>
          </select>
          <button
            disabled={!exportType}
            className="btn btn-primary no-block"
            id="btn-create-exported-users-files"
            data-cy="btn-create-exported-users-files"
            onClick={() => createExportedFiles()}
          >
            Exporter
          </button>
        </div>
        <button
          className="btn btn-primary no-block"
          id="btn-refresh-exported-files"
          data-cy="btn-refresh-exported-files"
          onClick={() => getExportedFiles()}
        >
          Rafraîchir
        </button>
      </div>

      {displayNotice && (
        <div className="mt-3 alert alert-success d-flex justify-content-between">
          La demande a bien été prise en compte, le traitement peut prendre quelques minutes.
        </div>
      )}

      {loading ? (
        <CircularLoader />
      ) : (
        <div className="table-responsive">
          <table className="table table-hover table-bordered mt-2">
            <thead>
              <tr>
                <th scope="col">Jeton</th>
                <th scope="col">Statut</th>
                <th scope="col">Type</th>
                <th scope="col">Date de création</th>
                <th scope="col">Télécharger</th>
              </tr>
            </thead>
            <tbody>
              {exportedFiles &&
                exportedFiles.length > 0 &&
                exportedFiles.map((exportedFile: IExportedFile) => (
                  <tr key={exportedFile.id}>
                    <td>{exportedFile.token}</td>
                    <td>{getStatusLabel(exportedFile)}</td>
                    <td>{exportedFile.type}</td>
                    <td>{exportedFile.created_at}</td>
                    <td>
                      {exportedFile.status === 1 ? (
                        <a href="#" onClick={() => handleFileLinkClick(exportedFile)}>
                          Lien
                        </a>
                      ) : (
                        <span>{getStatusLabel(exportedFile)}</span>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default Exports;
