import React, { useState } from 'react';
import axios from 'axios';
import { BACKEND_URL } from '../../../config/environment';
import UserRegisteredModal from '../../../common/Modal/UserRegisteredModal';
import UserManagerModal from '../../../common/Modal/UserManagerModal';
import { getUserId } from 'rudder-sdk-js';

/**
 * This react component is used to swap subscription to annual.
 * @param props
 */
const AttachUserToManagerV2: React.FC<{
  user: IUser;
  onSubmit: () => void;
}> = ({ user, onSubmit }) => {
  const [isUserRegisteredModalOpen, setIsUserRegisteredModalOpen] = useState<boolean>(false);
  const [isUserManagerModalOpen, setIsUserManagerModalOpen] = useState<boolean>(false);
  const [mail, setMail] = useState<string | null>('');
  const [error, setError] = useState<string>(null);
  const [userId, setUserId] = useState<string>('');

  const sendAttachMemberRequest = (managerId: string) => {
    const url = `${BACKEND_URL}/api/admin/users/${managerId}/status`;

    axios
      .post(url, { email: mail })
      .then((data: any) => {
        if (data && data.data.status) {
          if (data.data.user_id) {
            setUserId(data.data.user_id);
            setUserId((state) => {
              return state;
            });
          }
          if (data.data.status === 'NOT_REGISTERED') {
            axios
              .post(`${BACKEND_URL}/api/admin/users/${managerId}/role`, { email: mail })
              .then(() => {
                onSubmit();
                setMail('');
                setError(null);
              });
          } else if (data.data.status === 'REGISTERED') {
            setIsUserRegisteredModalOpen(true);
          } else {
            setIsUserManagerModalOpen(true);
          }
        }
      })
      .catch((err) => {
        setError('Erreur');
      });
  };

  const handleOnClick = () => {
    sendAttachMemberRequest(user.id);
  };

  return (
    <>
      <UserRegisteredModal
        email={mail}
        hidden={isUserRegisteredModalOpen}
        closeModal={() => setIsUserRegisteredModalOpen(false)}
        onSubmit={() => onSubmit()}
        manager={user}
        userId={userId}
      />
      <UserManagerModal
        email={mail}
        hidden={isUserManagerModalOpen}
        closeModal={() => setIsUserManagerModalOpen(false)}
        userId={userId}
      />
      <div className="flex row mb-2 mt-3">
        <div className="col-lg-10">
          <input
            className="form-control auth-input mt-0"
            style={{ height: '40px' }}
            placeholder="Entrer une adresse e-mail"
            name="new_user"
            onChange={(e) => setMail(e.target.value)}
            value={mail}
          />
          <span className="text-danger" hidden={!error}>
            Veuillez fournir une adresse valide.
          </span>
        </div>
        <div className="col-lg-2">
          <button
            type="button"
            data-cy="button-edit-password"
            id="button-edit-password"
            className="btn btn-secondary"
            disabled={!mail}
            style={{ maxWidth: 240 }}
            onClick={handleOnClick}
          >
            Inviter
          </button>
        </div>
      </div>
    </>
  );
};

export default AttachUserToManagerV2;
