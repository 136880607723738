import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Pagination from '../../../common/navigation/Pagination';
import CircularLoader from '../../../common/navigation/CircularLoader';
import Info from '../../navigation/Info';
import Modal from '../../../common/Modal';
import { BACKEND_URL } from '../../../config/environment';

/**
 * Load companies and show them in a responsive table.
 */
const CompanyListV2: React.FC = () => {
  const [companies, setCompanies] = useState<Array<ICompany>>();
  const [lastPage, setLastPage] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);

  // Modal
  const [companySelected, setCompanySelected] = useState<ICompany>(null);
  const [modalIsVisible, setModalIsVisible] = useState<boolean>(false);

  /**
   * Load companies first time.
   */
  useEffect(() => {
    if (!companies) {
      changePage(1);
    }
  }, []);

  /**
   * Load companies in a specific page with a query string filter.
   * @param page Current page.
   */
  const changePage = (page: number) => {
    setLoading(true);
    axios
      .get<IPaginateCompany>(`${BACKEND_URL}/api/admin/companies?query=${searchQuery}&page=${page}`)
      .then(({ data }) => {
        setCompanies(data.companies.data);
        setLastPage(data.companies.last_page);
        setCurrentPage(data.companies.current_page);
        setLoading(false);
      });
  };

  /**
   * Triggered when the user press a touch to detect if it is "Enter". It it is, is starts a search.
   * @param key Key pressed.
   */
  const searchCompany = (key: string) => {
    if (key === 'Enter' || searchQuery === '') {
      setLastPage(1);
      changePage(1);
    }
  };

  const seeAccountsInCompany = (company: ICompany) => {
    setCompanySelected(company);
    setModalIsVisible(true);
  };

  return (
    <>
      <Modal hidden={modalIsVisible} maxWidth={500} closeModal={() => setModalIsVisible(false)}>
        <div className="card card-body" style={{ height: '100%', overflow: 'auto' }}>
          {companySelected && (
            <h1>Comptes et utilisateurs de l&apos;entreprise {companySelected.name}</h1>
          )}
          <ul className="list-group">
            {companySelected &&
              companySelected.accounts &&
              companySelected.accounts.map((account, index) => (
                <li className="list-group-item" key={index}>
                  <b>Compte #{account.id}</b>
                  <ul>
                    {account.users &&
                      account.users.map((u, i) => (
                        <li key={i}>
                          <Link to={`/admin/utilisateurs/${u.id}`}>
                            {u.first_name} {u.last_name} ({u.mail})
                          </Link>
                        </li>
                      ))}
                  </ul>
                </li>
              ))}
          </ul>
        </div>
      </Modal>

      <div className="alert alert-info">
        Retrouvez ici la liste de toutes les entreprises étant inscrites à Chantier Privé V2.
      </div>

      <div className="card card-body">
        <div className="row">
          <div className="col-sm-8 col-lg-10">
            <input
              type="text"
              className="form-control auth-input mt-0"
              id="text-field-search"
              data-cy="text-field-search"
              placeholder="Rechercher une entreprise..."
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyUp={(e) => searchCompany(e.key)}
            />
          </div>

          <div className="col-sm-4 col-lg-2">
            <button
              className="btn btn-primary"
              onClick={() => searchCompany('Enter')}
              id="btn-search"
              data-cy="btn-search"
            >
              Rechercher
            </button>
          </div>
        </div>

        {loading && <CircularLoader />}

        {!loading && companies && companies.length === 0 && (
          <Info text="Aucune entreprise n'a été trouvée." />
        )}

        {!loading && companies && companies.length > 0 && (
          <div className="table-responsive">
            <table className="table table-hover table-bordered mt-2">
              <thead>
                <tr>
                  <th scope="col">Nom de l&apos;entreprise</th>
                  <th scope="col">SIRET</th>
                  <th scope="col">Adresse</th>
                  <th scope="col">Inscription</th>
                  <th scope="col">Comptes associés</th>
                  <th scope="col">Gérer</th>
                </tr>
              </thead>
              <tbody>
                {companies &&
                  companies.length > 0 &&
                  companies.map((company: ICompany) => (
                    <tr key={company.id}>
                      <td>{company.name}</td>
                      <td>{company.siret}</td>
                      <td>
                        {company.address}, {company.zip_code} {company.city}
                      </td>
                      <td>{company.created_at}</td>
                      <td>
                        <Link
                          to="#"
                          className="selector-link-accounts"
                          onClick={() => seeAccountsInCompany(company)}
                        >
                          {company.accounts.length}{' '}
                          {company.accounts.length > 1 ? 'comptes' : 'compte'}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/admin/entreprises/${company.id}`}
                          className="selector-link-manage"
                        >
                          Gérer
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {lastPage > 0 && (
        <Pagination lastPage={lastPage} currentPage={currentPage} toCall={changePage} />
      )}
    </>
  );
};

export default CompanyListV2;
