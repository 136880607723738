import { ISales } from '../interfaces/ISales';

export const Sales: Array<ISales> = [
  {
    id: 1,
    name: 'Octave Gonet',
  },
  {
    id: 2,
    name: 'Ibrahima Camara',
  },
  {
    id: 3,
    name: 'Marin Raynaud',
  },
  {
    id: 4,
    name: 'Idriss Ben Chagra',
  },
  {
    id: 5,
    name: 'Alma Chebel',
  },
  {
    id: 6,
    name: 'Nathan Schaller',
  },
  {
    id: 7,
    name: 'Emile Sekssaoui',
  },
  {
    id: 8,
    name: 'Bastien Gerard',
  },
  {
    id: 9,
    name: 'Banougon Fidagba',
  },
  {
    id: 10,
    name: 'Wandrille Lacome',
  },
  {
    id: 11,
    name: 'Online',
  },
];
