import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Modal from './../../common/Modal';
import { BACKEND_URL } from '../../config//environment';

const ApiUserTokensModal: React.FC<{
  hidden: boolean;
  user: IApiUser;
  closeModal: () => void;
  onChange: (user: IApiUser) => void;
}> = ({ hidden, user: apiUser, closeModal, onChange }) => {
  const [user, setUser] = useState<IApiUser>(null);

  const handleDeleteClick = (e: React.MouseEvent, id: string) => {
    e.preventDefault();

    if (window.confirm('Voulez-vous vraiment supprimer ?')) {
      axios.delete(`${BACKEND_URL}/api/admin/api-users/${user.id}/tokens/${id}`).then(() => {
        const newTokensState = user.tokens.filter((t) => t.id !== id);

        onChange({
          ...user,
          tokens: newTokensState,
        });

        if (newTokensState.length === 0) {
          closeModal();
        }
      });
    }
  };

  useEffect(() => {
    setUser(apiUser);
  }, [apiUser]);

  return (
    <Modal hidden={hidden} closeModal={closeModal} maxWidth={600}>
      <div className="modal-body">
        <div className="table-responsive">
          {user && user.tokens.length > 0 && (
            <table className="table table-hover table-bordered mt-2">
              <thead>
                <tr>
                  <th scope="col">Nom</th>
                  <th scope="col">Dernière Utilisation</th>
                  <th scope="col">Supprimer</th>
                </tr>
              </thead>
              <tbody>
                {user.tokens.map((token: IToken) => (
                  <tr key={`token-${token.id}`}>
                    <td>{token.name}</td>
                    <td>
                      {token.last_used_at ? new Date(token.last_used_at).toLocaleString() : 'N/A'}
                    </td>
                    <td>
                      <Link to="#" role="button" onClick={(e) => handleDeleteClick(e, token.id)}>
                        Supprimer
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ApiUserTokensModal;
