import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Pagination from '../../../common/navigation/Pagination';
import CircularLoader from '../../../common/navigation/CircularLoader';
import Info from '../../navigation/Info';
import ErrorMessage from '../../../common/errors/ErrorMessage';
import { BACKEND_URL } from '../../../config/environment';
import { formatDate } from '../../../constants';
/**
 * Load users and show them in a responsive table.
 */
const PendingUsers: React.FC = () => {
  const [users, setUsers] = useState<Array<IUser>>();
  const [lastPage, setLastPage] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [error, setError] = useState<string>(null);
  const [open, setOpen] = useState<boolean>(false);

  /**
   * Load companies first time.
   */
  useEffect(() => {
    if (!users) {
      changePage(1);
    }
  }, []);

  /**
   * Load users in a specific page with a query string filter.
   * @param page Current page.
   */
  const changePage = (page: number) => {
    setLoading(true);
    setCurrentPage(page);

    let url = `${BACKEND_URL}/api/admin/users/pending?page=${page}`;

    if (searchQuery.length > 0) url += `&searchQuery=${searchQuery}`;

    axios.get<IPaginateUser>(url).then(({ data }) => {
      setUsers(data.users.data);
      setLastPage(data.users.last_page);
      setLoading(false);
    });
  };

  /**
   * Resend subscription mail to the user.
   * @param id ID of the user.
   */
  const resendEmail = (id: string) => {
    axios.post(`${BACKEND_URL}/api/admin/users/${id}/choose-password`).then(({ data }) => {
      if (data.success) {
        changePage(currentPage);
      }
    });
  };

  /**
   * Triggered when the user press a touch to detect if it is "Enter". It it is, is starts a search.
   * @param key Key pressed.
   */
  const searchUser = (key: string) => {
    if (key === 'Enter' || searchQuery === '') {
      changePage(1);
    }
  };

  return (
    <>
      <div className="alert alert-info d-flex justify-content-between">
        Retrouvez ici la liste de tous les utilisateurs en attente d’inscription à Chantier Privé
        <a href="/admin/pending/exports">Exporter la liste des utilisateurs en Excel</a>
      </div>

      {error && <ErrorMessage text={error} />}

      <div className="card card-body">
        <div className="row">
          <div className="col-sm-4 col-lg-10">
            <input
              type="text"
              className="form-control auth-input mt-0"
              id="text-field-search"
              data-cy="text-field-search"
              placeholder="Rechercher un utilisateur par email, nom, prénom ou par téléphone avec @téléphone..."
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyUp={(e) => searchUser(e.key)}
            />
          </div>

          <div className="col-sm-4 col-lg-2">
            <button className="btn btn-primary" id="btn-search" onClick={() => changePage(1)}>
              Rechercher
            </button>
          </div>
        </div>

        <div className="alert alert-danger mt-4">
          Attention : le mail de finalisation de création de compte sera envoyé automatiquement au
          bout de 48h (jours ouvrés).
        </div>

        {loading && <CircularLoader />}

        {!loading && users && users.length === 0 && (
          <Info text="Aucun utilisateur en attente n'a été trouvé" />
        )}

        {!loading && users && users.length > 0 && (
          <div className="table-responsive">
            <table className="table table-hover table-bordered mt-2">
              <thead>
                <tr>
                  <th scope="col">Nom</th>
                  <th scope="col">Adresse e-mail</th>
                  <th scope="col">Téléphone</th>
                  <th scope="col">Demande</th>
                  <th scope="col">Département</th>
                  <th scope="col">Email inscription</th>
                  <th scope="col">Gérer</th>
                </tr>
              </thead>
              <tbody>
                {users &&
                  users.length > 0 &&
                  users.map((user: IUser) => (
                    <tr key={user.id}>
                      <td>
                        {user.first_name} {user.last_name}
                      </td>
                      <td>{user.mail}</td>
                      <td>
                        <a href={`tel:${user.international_phone}`}>{user.international_phone}</a>
                      </td>
                      <td>{user.created_at ? user.created_at : ''}</td>
                      <td>
                        {user.preference?.states
                          ? user.preference.states
                              .map((dep) => {
                                return dep.number;
                              })
                              .join(' - ')
                          : ''}
                      </td>
                      <td>
                        {
                          <span
                            className={user.invitation_sent_at ? 'text-success' : 'text-danger'}
                          >
                            {user.invitation_sent_at ? 'Oui' : 'Non'}
                          </span>
                        }
                        {user.invitation_sent_at && (
                          <>
                            {' '}
                            -{' '}
                            <Link
                              to="#"
                              className="selector-link-activate-email"
                              onClick={() => resendEmail(user.id)}
                            >
                              Renvoyer
                            </Link>
                          </>
                        )}
                      </td>
                      <td>
                        <Link
                          to={`/admin/utilisateurs/${user.id}`}
                          className="selector-link-manage"
                          data-cy="selector-link-manage"
                        >
                          Gérer
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {lastPage > 0 && (
        <Pagination
          lastPage={lastPage}
          currentPage={currentPage}
          toCall={(page) => changePage(page)}
        />
      )}
    </>
  );
};

export default PendingUsers;
