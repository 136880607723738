import axios from 'axios';
import React, { FormEvent, useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router';
import CircularLoader from '../../common/navigation/CircularLoader';
import { BACKEND_URL } from '../../config//environment';
import BillingPriceForm from './BillingPriceForm';

const EditBillingPrice: React.FC = () => {
  const [stripePrice, setStripePrice] = useState<IStripePrice>(null);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [redirection, setRedirection] = useState<boolean>(false);
  const [errors, setErrors] = useState<{
    [name: string]: Array<string>;
  }>(null);

  const { priceId }: { priceId: string } = useParams();

  /**
   * Send update billing price request.
   *
   * @param form billing form data object
   * @returns Promise
   */
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    axios
      .put(`${BACKEND_URL}/api/admin/stripe-prices/${stripePrice.id}`, stripePrice)
      .then(() => setRedirection(true))
      .catch(({ response }) => setErrors(response.data.errors));
  };

  useEffect(() => {
    axios
      .get(`${BACKEND_URL}/api/admin/stripe-prices/${priceId}`)
      .then(({ data }) => {
        setStripePrice(data.price);
      })
      .finally(() => setLoaded(true));
  }, []);

  if (redirection) {
    return <Redirect to="/admin/prices" />;
  }

  return (
    <>
      {redirection ? (
        <Redirect to="/admin/prices" />
      ) : (
        <div className="card">
          <div className="card-header">Modifier l&apos;offre</div>

          <div className="card-body">
            {errors && (
              <div className="alert alert-danger">
                <ul>
                  {Object.keys(errors).map((key, i) => (
                    <li key={`error-${i}`}>{errors[key][0]}</li>
                  ))}
                </ul>
              </div>
            )}

            {!loaded && <CircularLoader />}

            {loaded && (
              <BillingPriceForm
                id="edit-price-form"
                stripePrice={stripePrice}
                setStripePrice={setStripePrice}
                onSubmit={handleSubmit}
              />
            )}

            <div className="d-flex justify-content-center mt-4">
              <div className="col-lg-6">
                <button type="submit" className="btn btn-primary" form="edit-price-form">
                  Modifier
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditBillingPrice;
