import React, { useState } from 'react';
import axios from 'axios';
import { BACKEND_URL, FRONTEND_URL } from '../../config//environment';

const TestAs: React.FC = () => {
  const [users, setUsers] = useState<Array<IUser>>([]);

  const searchUser = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    if (query && query.length > 0) {
      axios
        .post(`${BACKEND_URL}/api/admin/search-user`, { query })
        .then(({ data }) => setUsers(data.users));
    } else {
      setUsers([]);
    }
  };

  const selectUser = (userId: string) => {
    axios.post(`${BACKEND_URL}/api/admin/test-as-user`, { userId }).then(() => {
      document.location.href = `${FRONTEND_URL}/consultation_open`;
    });
  };

  return (
    <div className="card card-body">
      <h5>Tester Chantier Privé avec &quot;les yeux&quot; de :</h5>
      <input
        type="text"
        className="form-control mt-3"
        placeholder="Saisir le nom ou l'e-mail de l'utilisateur..."
        onChange={(event) => searchUser(event)}
      />

      {users.length > 0 && (
        <div className="list-group">
          {users.map((user, index) => (
            <a
              key={`user-${index}`}
              className="list-group-item list-group-item-action"
              onClick={() => selectUser(user.id)}
            >
              {user.first_name} {user.last_name} ({user.mail})
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

export default TestAs;
