import moment from 'moment';

// @see https://momentjs.com/docs/#/displaying/

export const formatDate = (date: Date, format?: string) => {
  const formatString = format || 'DD/MM/YYYY';

  return moment(date).format(formatString);
};

export const positionValues = [
  { key: '0', value: 'Secrétaire', technicalValue: 'secretaire' },
  { key: '1', value: 'Assistant(e) de direction', technicalValue: 'assistant·e de direction' },
  { key: '2', value: 'Chargé d’affaire', technicalValue: 'charge d’affaires' },
  { key: '3', value: 'Conducteur de travaux', technicalValue: 'conducteur de travaux' },
  { key: '4', value: "Responsable bureau d'étude", technicalValue: "responsable bureau d'etude" },
  { key: '5', value: 'Gérant', technicalValue: 'gerant·e' },
  { key: '6', value: 'Président', technicalValue: 'president·e' },
  { key: '7', value: 'Alternant', technicalValue: 'alternant·e' },
  { key: '8', value: 'Autre', technicalValue: 'autre' },
];

export const findPositionValueByTechnicalLabel = (label: any) => {
  const pos = positionValues.find((e) => e.technicalValue === label);
  return pos?.value;
};

export const findPositionTechnicalValueByLabel = (label: any) => {
  const pos = positionValues.find((e) => e.value === label);
  return pos?.technicalValue;
};
