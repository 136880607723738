import React, { useContext, useEffect, useState } from 'react';
import { NavLink as Link } from 'react-router-dom';
import axios from 'axios';
import { BACKEND_URL, FRONTEND_URL } from '../../config//environment';
import { UserContext } from '../../common/providers/UserProvider';

/**
 * This react component is used  to  display the sidebar of the application.
 * This component able the user to navigate in the different sections of the application.
 * @param props
 * @constructor
 */
const TopBar: React.FC = () => {
  const [keepAliveStarted, setKeepAliveStarted] = useState<boolean>(false);
  const { user, logout } = useContext(UserContext);

  const keepAlive = () => {
    setKeepAliveStarted(true);

    setTimeout(() => {
      axios.post(`${BACKEND_URL}/api/keep-alive`);
      keepAlive();
    }, 1000 * 300); // Each 5 minutes is enough.
  };

  const userLogout = () => {
    axios.post(`${BACKEND_URL}/api/logout`).then(() => {
      logout();
    });
  };

  useEffect(() => {
    if (!keepAliveStarted) {
      keepAlive();
    }
  }, [keepAliveStarted]);

  return (
    user && (
      <nav className="navbar navbar-expand-lg navbar-dark">
        <a className="navbar-brand" href="#">
          Administration CP
        </a>
        <button
          className="navbar-toggler"
          data-cy="navbar-dropdown-admin"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link" href={`${FRONTEND_URL}/consultation_open`}>
                Retour au site <span className="sr-only">(current)</span>
              </a>
            </li>
            {/* <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuUsers"
                data-cy="navbar-dropdown-menu-users"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                CP-V1
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuUsers">
                <Link className="dropdown-item" data-cy="entreprises-v1" to="/admin/v1/entreprises">
                  Entreprises
                </Link>
                <Link
                  className="dropdown-item"
                  data-cy="users-admin-v2"
                  to="/admin/v1/utilisateurs"
                >
                  Utilisateurs actifs
                </Link>
              </div>
            </li> */}
            <li className="nav-item">
              <Link className="nav-link" data-cy="companies-admin" to="/admin/entreprises">
                Entreprises
              </Link>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuUsers"
                data-cy="navbar-dropdown-menu-users"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Utilisateurs
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuUsers">
                <Link className="dropdown-item" data-cy="users-admin" to="/admin/utilisateurs">
                  Utilisateurs actifs
                </Link>
                <Link
                  className="dropdown-item"
                  data-cy="unsubscribers-admin-v2"
                  to="/admin/pending-users"
                >
                  Utilisateurs en attente
                </Link>
                <Link
                  className="dropdown-item"
                  data-cy="unsubscribers-admin"
                  to="/admin/deleted-users"
                >
                  Utilisateurs supprimés
                </Link>
                <Link className="dropdown-item" data-cy="invited-admin" to="/admin/invited-users">
                  Utilisateurs invités
                </Link>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuProjects"
                data-cy="navbar-dropdown-menu-projects"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Projets &amp; DO
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuProjects">
                <Link className="dropdown-item" to="/admin/projets">
                  Projets
                </Link>
                <Link className="dropdown-item" to="/admin/liens-do">
                  Liens de validation DO
                </Link>
                {/* <Link className="dropdown-item" to="/admin/trades">
                  Lots
                </Link> */}
              </div>
            </li>
            <li className="nav-item">
              <Link className="nav-link" data-cy="api-users-admin" to="/admin/api-users">
                Accès API
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" data-cy="invoices-admin" to="/admin/invoices">
                Factures
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link className="nav-link" data-cy="prices-admin" to="/admin/prices">
                Facturation
              </Link>
            </li> */}
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                data-cy="navbar-dropdown-menu-link"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Outils tech.
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <Link className="dropdown-item" data-cy="admin-exports" to="/admin/exports">
                  Exports
                </Link>
              </div>
            </li>
            <li className="nav-item">
              <a className="nav-link" onClick={userLogout} href="#">
                Se déconnecter <span className="sr-only">(current)</span>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    )
  );
};

export default TopBar;
