import React from 'react';
import { Link } from 'react-router-dom';

const Admin: React.FC = () => {
  return (
    <>
      <h1>Administration de Chantier Privé</h1>

      <div className="row justify-content-center mt-5">
        <div className="col-md-4">
          <div className="card bg-light mb-3">
            <div className="card-header">Entreprises</div>

            <div className="card-body text-center">
              <img
                src="/Icons/noun_filter_3212581.svg"
                style={{ maxHeight: 163, maxWidth: 163, width: '100%', height: '100%', opacity: 1 }}
              />
            </div>

            <div className="card-footer text-right">
              <Link to="/admin/entreprises" className="green">
                Gérer les entreprises
              </Link>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card bg-light mb-3">
            <div className="card-header">Utilisateurs</div>

            <div className="card-body text-center">
              <img
                src="/Icons/noun_update_activated.svg"
                style={{ maxHeight: 150, maxWidth: 150, width: '100%', height: '100%', opacity: 1 }}
              />
            </div>

            <div className="card-footer text-right">
              <Link to="/admin/utilisateurs" className="green">
                Gérer les utilisateurs
              </Link>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card bg-light mb-3">
            <div className="card-header">Projets</div>

            <div className="card-body text-center">
              <img
                src="/Icons/noun_complete_activated.svg"
                style={{ maxHeight: 150, maxWidth: 150, width: '100%', height: '100%', opacity: 1 }}
              />
            </div>

            <div className="card-footer text-right">
              <Link to="/admin/projets" className="green">
                Gérer les projets
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Admin;
