import React, { useContext, useEffect } from 'react';
import axios from 'axios';

import ReactDOM from 'react-dom';
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom';
import Admin from './Admin';
import Login from './authentication/Login';
import User from './users/User';
import Company from './company/Company';
import TopBar from './navigation/TopBar';
import Project from './projects/Project';
import UsersList from './users/UsersList';
import Logs from './technical-tools/Logs';
import TestAs from './technical-tools/TestAs';
import NotFound from '../components/NotFound';
import CompaniesList from './company/CompaniesList';
import ProjectsList from './projects/ProjectsList';
import PurchaserLinks from './purchaser-links/PurchaserLinks';
import FAQList from './faq/FAQList';
import CreateFAQForm from './faq/CreateFAQForm';
import EditFAQForm from './faq/EditFAQForm';
import Invoices from './invoices/Index';
import ExportedFiles from './exportedFiles';
import TradesList from './trades/TradesList';
import Trade from './trades/Trade';
import LogASTester from './authentication/LogASTester';

import ApiUsersList from './api-users/ApiUsersList';
import CreateApiUser from './api-users/CreateApiUser';
import DeletedUsers from './users/DeletedUsers';
import { ToastProvider } from '../common/providers/ToastProvider';
import Billings from './billings/Billings';
import CreateBillingPrice from './billings/CreateBillingPrice';
import EditBillingPrice from './billings/EditBillingPrice';
import GoogleMap from './maps';
import InvitedUsers from './users/InvitedUsers';

import { PROJECTS_SEARCH_QUERY_KEY } from './global-keys';
import { UserContext, UserProvider } from '../common/providers/UserProvider';
import PrivateAdminRoute from '../common/routes/PrivateAdminRoute';
import { BACKEND_URL } from '../config/environment';
import Home from './authentication/Home';
import PendingUsers from './users/v2/PendingUsers_V2';
import UserV2 from './users/v2/User_V2';
import UsersListV2 from './users/v2/UsersList_V2';
import CompanyListV2 from './company/v2/CompanyList_V2';
import CompanyV2 from './company/v2/Company_V2';

const AdminRoutePath: React.FC = () => {
  const { user, login, loginFailed } = useContext(UserContext);
  const location = window.location.href;

  useEffect(() => {
    if (!user) {
      axios
        .get(`${BACKEND_URL}/api/user`)
        .then(({ data }) => {
          if (data.user && data.user.mail) {
            login(data.user);
          } else {
            loginFailed();
          }
        })
        .catch(() => {
          loginFailed();
        });
    }
  }, []);

  useEffect(() => {
    if (!location.includes('/admin/projets')) {
      localStorage.removeItem(PROJECTS_SEARCH_QUERY_KEY);
    }
  }, [location]);

  return (
    <BrowserRouter>
      {/* Main page */}
      <TopBar />
      <div className="container mt-5 pt-4">
        <Switch>
          <Route exact path="/login" component={Login} />
          <PrivateAdminRoute exact path="/" component={Home} />
          <PrivateAdminRoute exact path="/admin" component={Admin} />
          {/* <PrivateAdminRoute exact path="/admin/v1/entreprises" component={CompaniesList} />
          <PrivateAdminRoute exact path="/admin/v1/entreprises/:enterpriseId" component={Company} />
          <PrivateAdminRoute exact path="/admin/v1/utilisateurs" component={UsersList} />
          <PrivateAdminRoute exact path="/admin/v1/utilisateurs/:userId" component={User} /> */}
          <PrivateAdminRoute exact path="/admin/projets" component={ProjectsList} />
          <PrivateAdminRoute exact path="/admin/projets/:projectId" component={Project} />
          {/* <PrivateAdminRoute exact path="/admin/trades" component={TradesList} />
          <PrivateAdminRoute exact path="/admin/trades/:tradeId" component={Trade} /> */}
          <PrivateAdminRoute exact path="/admin/liens-do" component={PurchaserLinks} />
          <PrivateAdminRoute exact path="/admin/tester-en-tant-que" component={TestAs} />
          <PrivateAdminRoute exact path="/admin/logs" component={Logs} />
          <PrivateAdminRoute exact path="/admin/exports" component={ExportedFiles} />
          <PrivateAdminRoute exact path="/admin/faq" component={FAQList} />
          <PrivateAdminRoute exact path="/admin/faq/create" component={CreateFAQForm} />
          <PrivateAdminRoute exact path="/admin/faq/:faqId/edit" component={EditFAQForm} />
          <PrivateAdminRoute exact path="/admin/invoices" component={Invoices} />
          <PrivateAdminRoute exact path="/admin/prices" component={Billings} />
          <PrivateAdminRoute exact path="/admin/prices/create" component={CreateBillingPrice} />
          <PrivateAdminRoute
            exact
            path="/admin/prices/:priceId/edit"
            component={EditBillingPrice}
          />
          <PrivateAdminRoute exact path="/admin/api-users" component={ApiUsersList} />
          <PrivateAdminRoute exact path="/admin/api-users/create" component={CreateApiUser} />
          <PrivateAdminRoute exact path="/admin/deleted-users" component={DeletedUsers} />
          <PrivateAdminRoute exact path="/admin/invited-users" component={InvitedUsers} />
          <PrivateAdminRoute exact path="/admin/map" component={GoogleMap} />

          {/* V2 */}
          <PrivateAdminRoute exact path="/admin/utilisateurs" component={UsersListV2} />
          <PrivateAdminRoute exact path="/admin/utilisateurs/:userId" component={UserV2} />
          <PrivateAdminRoute exact path="/admin/pending-users" component={PendingUsers} />
          <PrivateAdminRoute exact path="/admin/entreprises" component={CompanyListV2} />
          <PrivateAdminRoute exact path="/admin/entreprises/:enterpriseId" component={CompanyV2} />

          <Route component={NotFound} />
        </Switch>
      </div>
    </BrowserRouter>
  );
};

ReactDOM.render(
  <UserProvider>
    <ToastProvider>
      <LogASTester>
        <AdminRoutePath />
      </LogASTester>
    </ToastProvider>
  </UserProvider>,
  document.getElementById('app_admin')
);

export default AdminRoutePath;
