import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CircularLoader from '../../common/navigation/CircularLoader';
import { Link } from 'react-router-dom';
import Pagination from '../../common/navigation/Pagination';
import { BACKEND_URL } from '../../config//environment';
/**
 * Load Invoices and "Credit side" (avoir in French).
 */
const Index: React.FC = () => {
  const [invoices, setInvoices] = useState<Array<IInvoice>>(null);
  const [sumCreditNotes, setSumCreditNotes] = useState<number>(0);
  const [sumInvoices, setSumInvoices] = useState<number>(0);
  const [financialBalance, setFinancialBalance] = useState<number>(0);
  const [lastPage, setLastPage] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    if (!invoices) {
      changePage(1);
    }
  }, []);

  /**
   * Load invoices in a specific page with a query string filter.
   * @param page Current page.
   */
  const changePage = (page: number) => {
    setLoading(true);
    axios
      .get<IPaginateInvoice>(`${BACKEND_URL}/api/admin/invoices?query=${searchQuery}&page=${page}`)
      .then(({ data }) => {
        setInvoices(data.invoices.data);
        setSumCreditNotes(Math.round((data.sum_credit_notes + Number.EPSILON) * 100) / 100);
        setSumInvoices(Math.round((data.sum_invoices + Number.EPSILON) * 100) / 100);
        setFinancialBalance(Math.round((data.financial_balance + Number.EPSILON) * 100) / 100);
        setLastPage(data.invoices.last_page);
        setCurrentPage(data.invoices.current_page);
        setLoading(false);
      });
  };

  /**
   * Triggered when the user press a touch to detect if it is "Enter". It it is, is starts a search.
   * @param key Key pressed.
   */
  const searchCompany = (key: string) => {
    if (key === 'Enter' || searchQuery === '') {
      setLastPage(1);
      changePage(1);
    }
  };

  /**
   * Validate the invoice.
   * @param invoiceId Invoice ID to validate.
   */
  const validateInvoice = (invoiceId: string) => {
    if (
      confirm(
        'Vous certifiez avoir vérifié la corrélation entre le prélèvement Stripe et cette facture ?'
      )
    ) {
      axios
        .post(`${BACKEND_URL}/api/admin/invoices/${invoiceId}`, { action: 'validate' })
        .then(() => {
          changePage(1);
        });
    }
  };

  /**
   * Create a credit note for the current invoice.
   * @param invoiceId Invoice ID that must have a credit note.
   */
  const createCreditNote = (invoiceId: string) => {
    const amount = prompt(`Merci d'entrer le prix de l'avoir en TTC.
      (les décimales peuvent séparées d'un point  Par ex "100" ou "100.10")`);

    if (
      amount &&
      confirm(`Un avoir de ${amount} € TTC va être créé pour ce client. Êtes vous sûr(e) ?`)
    ) {
      axios
        .post(`${BACKEND_URL}/api/admin/invoices/${invoiceId}`, { action: 'credit-note', amount })
        .then(() => {
          changePage(1);
        });
    }
  };

  /**
   * Resolve Invoice.
   * @param id ID of invoice.
   */
  const resolveInvoice = (id: string) => {
    if (confirm('Attention: Cette action est irréversible.')) {
      axios.post(`${BACKEND_URL}/api/admin/invoices/${id}/resolve-invoice`).then(() => {
        changePage(1);
      });
    }
  };

  const downloadPDF = (id: string) => {
    axios.get(`${BACKEND_URL}/api/admin/invoices/${id}`).then(({ data }) => {
      // force download
      const url = data;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
  };

  const downloadPDFStripe = (id: string) => {
    axios
      .get(`${BACKEND_URL}/api/admin/v2/invoices/${id}/download`, { responseType: 'blob' })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', response.headers['filename']);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      });
  };

  return (
    <>
      <h2 className="mb-3">Liste des factures et avoirs</h2>

      {loading && <CircularLoader />}

      {invoices && (
        <div className="alert alert-info">
          Le solde sur toute la période est de <b>{financialBalance} &euro; TTC</b> avec{' '}
          <b className="text-success">{sumInvoices} &euro; de factures</b> et{' '}
          <b className="text-danger">{sumCreditNotes} &euro; d&apos;avoirs</b>.
        </div>
      )}

      <div className="card mt-3">
        <div className="card-header">Factures &amp; Avoirs</div>
        <div className="card-body">
          <div className="row mb-3">
            <div className="col-sm-8 col-lg-10">
              <input
                type="text"
                className="form-control auth-input mt-0"
                data-cy="text-field-search"
                id="text-field-search"
                placeholder="Rechercher une facture ou un avoir..."
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyUp={(e) => searchCompany(e.key)}
              />
            </div>

            <div className="col-sm-4 col-lg-2">
              <button
                className="btn btn-primary"
                onClick={() => searchCompany('Enter')}
                id="btn-search"
                data-cy="btn-search"
              >
                Rechercher
              </button>
            </div>
          </div>
          {!loading && invoices && invoices.length > 0 && (
            <table className="table table-bordered">
              <tr>
                <td>Numéro</td>
                <td>Type de document</td>
                <th>Montant TTC</th>
                <td>Client</td>
                <td>Comptablement vérifié</td>
                <th style={{ width: '15%' }}>Avoir ?</th>
                <td style={{ width: '20%' }}>Action</td>
              </tr>
              {invoices.map((invoice: IInvoice, index: number) => (
                <tr
                  key={`invoice-${index}`}
                  style={invoice.checked_by_admin ? { backgroundColor: 'lightGreen' } : {}}
                >
                  <td>{invoice.number}</td>
                  <td>{invoice.is_credit_note ? 'Avoir' : 'Facture'}</td>
                  <td>
                    {(invoice.is_credit_note && (
                      <span className="text-danger">- {invoice.amount_incl_tax} &euro;</span>
                    )) || <span className="text-success">{invoice.amount_incl_tax} &euro;</span>}
                  </td>
                  <td>
                    {invoice.user?.name} ({invoice.user?.mail})
                  </td>
                  <td>
                    {(invoice.checked_by_admin && 'Oui') || (
                      <Link to="#" onClick={() => validateInvoice(invoice.id)}>
                        Valider
                      </Link>
                    )}
                  </td>
                  <td>
                    <Link to="#" onClick={() => createCreditNote(invoice.id)}>
                      Créer un avoir
                    </Link>
                  </td>
                  <td>
                    {invoice.is_disputed && (
                      <>
                        <Link
                          to="#"
                          className="selector-link-activate-email"
                          onClick={() => resolveInvoice(invoice.id)}
                        >
                          Resolve invoice
                        </Link>
                      </>
                    )}
                    <a href="#" onClick={() => downloadPDF(invoice.id)}>
                      <img src="/Icons/download.png" className="mr-2" />
                    </a>
                    {invoice.stripe_invoice_id && (
                      <a href="#" onClick={() => downloadPDFStripe(invoice.id)}>
                        Stripe
                      </a>
                    )}
                  </td>
                </tr>
              ))}
            </table>
          )}
        </div>
      </div>

      {lastPage > 0 && (
        <Pagination lastPage={lastPage} currentPage={currentPage} toCall={changePage} />
      )}
    </>
  );
};

export default Index;
