import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Pagination from '../../../common/navigation/Pagination';
import CircularLoader from '../../../common/navigation/CircularLoader';
import Info from '../../navigation/Info';
import ErrorMessage from '../../../common/errors/ErrorMessage';
import AddNewUserModal from '../../../common/Modal/AddNewUserModal';
import { BACKEND_URL } from '../../../config/environment';
/**
 * Load users and show them in a responsive table.
 */
const UsersListV2: React.FC = () => {
  const [users, setUsers] = useState<Array<IUser>>();
  const [lastPage, setLastPage] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [error, setError] = useState<string>(null);
  const [open, setOpen] = useState<boolean>(false);

  /**
   * Load companies first time.
   */
  useEffect(() => {
    if (!users) {
      changePage(1);
    }
  }, []);

  /**
   * Load users in a specific page with a query string filter.
   * @param page Current page.
   */
  const changePage = (page: number) => {
    setLoading(true);
    setCurrentPage(page);

    let url = `${BACKEND_URL}/api/admin/users?page=${page}`;

    if (searchQuery.length > 0) url += `&searchQuery=${searchQuery}`;

    axios.get<IPaginateUser>(url).then(({ data }) => {
      setUsers(data.users.data);
      setLastPage(data.users.last_page);
      setLoading(false);
    });
  };

  /**
   * Triggered when the user press a touch to detect if it is "Enter". It it is, is starts a search.
   * @param key Key pressed.
   */
  const searchUser = (key: string) => {
    if (key === 'Enter' || searchQuery === '') {
      changePage(1);
    }
  };

  /**
   * Activate the email address of the user.
   * @param id ID of the user.
   */
  const activateEmail = (id: string) => {
    if (
      confirm(
        'Attention: Cette action est irréversible. Êtes vous sûr(e) de vouloir confirmer cette adresse e-mail ?'
      )
    ) {
      axios.post(`${BACKEND_URL}/api/admin/users/${id}/activate-email`).then(({ data }) => {
        if (data.success) {
          changePage(currentPage);
        }
      });
    }
  };

  /**
   * Add or remove the admin profile.
   * @param id ID of the user.
   */
  const editAdminStatus = (id: string) => {
    setError(null);

    if (
      confirm(
        `Attention : Cette opération touche à l'accès aux données de toute la plateforme. Êtes vous sûr(e) ?`
      )
    ) {
      axios
        .post(`${BACKEND_URL}/api/admin/users/${id}/set-is-admin`)
        .then(() => {
          changePage(currentPage);
        })
        .catch((err) => {
          const error = err?.response?.data?.errorMessage;
          setError(error ? error : 'Une erreur est survenue.');
        });
    }
  };

  /**
   * Add or remove the manager profile.
   * @param id ID of the user.
   */
  const editManagerStatus = (id: string) => {
    setError(null);

    if (
      confirm(
        `Attention : Cette opération touche à l'accès aux données de toute la plateforme. Êtes vous sûr(e) ?`
      )
    ) {
      axios
        .post(`${BACKEND_URL}/api/admin/users/${id}/set-as-manager`)
        .then(() => {
          changePage(currentPage);
        })
        .catch((err) => {
          const error = err?.response?.data?.errorMessage;
          setError(error ? error : 'Une erreur est survenue.');
        });
    }
  };

  return (
    <>
      <div className="alert alert-info d-flex justify-content-between">
        Retrouvez ici la liste de tous les inscrits à Chantier Privé V2.
        <a href="/admin/exports">Exporter la liste des utilisateurs en Excel</a>
      </div>

      {error && <ErrorMessage text={error} />}

      <AddNewUserModal
        hidden={open}
        closeModal={() => setOpen(false)}
        refresh={() => changePage(1)}
      />

      <div className="card card-body">
        <div className="row">
          <div className="col-sm-4 col-lg-7">
            <input
              type="text"
              className="form-control auth-input mt-0"
              id="text-field-search"
              data-cy="text-field-search"
              placeholder="Rechercher un utilisateur par email, nom, prénom ou par téléphone avec @téléphone..."
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyUp={(e) => searchUser(e.key)}
            />
          </div>

          <div className="col-sm-4 col-lg-2">
            <button className="btn btn-primary" id="btn-search" onClick={() => changePage(1)}>
              Rechercher
            </button>
          </div>

          <div className="col-sm-4 col-lg-3">
            <button className="btn btn-secondary" id="btn-search" onClick={() => setOpen(true)}>
              Ajouter un utilisateur
            </button>
          </div>
        </div>

        {loading && <CircularLoader />}

        {!loading && users && users.length === 0 && <Info text="Aucun inscrit n'a été trouvé." />}

        {!loading && users && users.length > 0 && (
          <div className="table-responsive">
            <table className="table table-hover table-bordered mt-2">
              <thead>
                <tr>
                  <th scope="col">Nom</th>
                  <th scope="col">Adresse e-mail</th>
                  <th scope="col">Téléphone</th>
                  <th scope="col">Inscription</th>
                  <th scope="col">E-mail confirmé</th>
                  <th scope="col">Administrateur</th>
                  <th scope="col">Manager</th>
                  <th scope="col">Gérer</th>
                </tr>
              </thead>
              <tbody>
                {users &&
                  users.length > 0 &&
                  users.map((user: IUser) => (
                    <tr key={user.id}>
                      <td>
                        {user.first_name} {user.last_name}
                      </td>
                      <td>{user.mail}</td>
                      <td>
                        <a href={`tel:${user.international_phone}`}>{user.international_phone}</a>
                      </td>
                      <td>{user.created_at + ''}</td>
                      <td>
                        {user.is_mail_confirmed && <span className="text-success">Oui</span>}
                        {!user.is_mail_confirmed && (
                          <>
                            <span className="text-danger">Non</span> -{' '}
                            <Link
                              to="#"
                              className="selector-link-activate-email"
                              onClick={() => activateEmail(user.id)}
                            >
                              Activer l&apos;adresse e-mail
                            </Link>
                          </>
                        )}
                      </td>
                      <td>
                        {user.profile === 1 && (
                          <>
                            <span className="text-success">Oui</span> -{' '}
                            <Link
                              to="#"
                              className="selector-link-delete"
                              onClick={() => editAdminStatus(user.id)}
                            >
                              Supprimer
                            </Link>
                          </>
                        )}
                        {user.profile != 1 && (
                          <>
                            <span className="text-danger">Non</span> -{' '}
                            <Link
                              to="#"
                              className="selector-link-admin-permission"
                              onClick={() => editAdminStatus(user.id)}
                            >
                              Définir administrateur
                            </Link>
                          </>
                        )}
                      </td>

                      <td>
                        <span className="text-success">
                          {user.profile === 0 && user.role?.role === 'manager' ? (
                            <span className="text-success">Oui</span>
                          ) : (
                            <span className="text-danger">Non</span>
                          )}
                        </span>
                      </td>
                      <td>
                        <Link
                          to={`/admin/utilisateurs/${user.id}`}
                          className="selector-link-manage"
                          data-cy="selector-link-manage"
                        >
                          Gérer
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {lastPage > 0 && (
        <Pagination
          lastPage={lastPage}
          currentPage={currentPage}
          toCall={(page) => changePage(page)}
        />
      )}
    </>
  );
};

export default UsersListV2;
