import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { BACKEND_URL } from '../../../config/environment';
import AttachUserToManagerV2 from './AttachUserToManager_V2';
import { findPositionValueByTechnicalLabel } from '../../../constants';

/**
 * Load members and show them in a responsive table.
 */
const UserManagementV2: React.FC<{ manager: IUser; refresh: () => void }> = ({
  manager,
  refresh,
}) => {
  const [members, setMembers] = useState<Array<IManagedUser>>();

  useEffect(() => {
    if (!members) {
      getMembers(manager.id);
    }
  }, [members]);

  /**
   * Load users in a specific page with a query string filter.
   * @param page Current page.
   */
  const getMembers = (userId: string) => {
    const url = `${BACKEND_URL}/api/admin/users/${userId}/collaborators`;

    axios.post(url).then(({ data }) => {
      setMembers(data);
    });
  };

  const getMemberStatus = (member: IManagedUser) => {
    if (!member.is_registered) return <span className="text-danger">Invité</span>;
    else if (!member.is_verified) return <span className="text-warning">En attente</span>;
    else return <span className="text-success">Actif</span>;
  };

  const sendDetachMemberRequest = (managerId: string, memberRoleId: string) => {
    const url = `${BACKEND_URL}/api/admin/users/${managerId}/role`;

    axios
      .delete(url, { data: { role_id: memberRoleId } })
      .then(() => {
        getMembers(managerId);
      })
      .catch((err) => window.console.error(err));
  };

  const onDetachMemberClick = (memberRoleId: string) => {
    if (
      confirm(
        `Attention : Vous êtes sur le point de détacher ce membre utilisateur. Êtes vous sûr(e) ?`
      )
    ) {
      sendDetachMemberRequest(manager.id, memberRoleId);
    }
  };

  return (
    <>
      {manager.profile === 0 && manager.role?.role === 'manager' && (
        <div className="card mt-4">
          <div className="card-header">Utilisateurs liés</div>
          <div className="card-body">
            <div className="mt-2 mb-2">L&apos;utilisateur est manager</div>
            <div style={{ height: '1px', backgroundColor: '#D0D1D3' }} className="mt-3"></div>
            <AttachUserToManagerV2 user={manager} onSubmit={() => getMembers(manager.id)} />

            <div className="mt-3 mb-3">Comptes liés à ce manager</div>
            {members && members.length > 0 && (
              <div className="table-responsive p-2" style={{ maxHeight: 450, overflow: 'auto' }}>
                <table className="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <th>Nom</th>
                      <th>Adresse e-mail</th>
                      <th>Poste</th>
                      <th>Téléphone</th>
                      <th>Statut</th>
                      <th>Détacher</th>
                      <th>Gérer</th>
                    </tr>
                  </thead>
                  {members &&
                    members.length > 0 &&
                    members.map((member: IManagedUser) => (
                      <tr key={`member-${member.id}`}>
                        <td>
                          {member.first_name} {member.last_name}
                        </td>
                        <td>{member.email}</td>
                        <td>{findPositionValueByTechnicalLabel(member.position)}</td>
                        <td>
                          <a href={`tel:${member.phone}`}>{member.phone}</a>
                        </td>
                        <td>{getMemberStatus(member)}</td>
                        <td>
                          <Link
                            to="#"
                            className="selector-link-delete"
                            onClick={() => onDetachMemberClick(member.role_id)}
                          >
                            Détacher
                          </Link>
                        </td>
                        <td>
                          {member.id && (
                            <a
                              href={`/admin/utilisateurs/${member.id}`}
                              className="selector-link-delete"
                            >
                              Gérer
                            </a>
                          )}
                        </td>
                      </tr>
                    ))}
                </table>
              </div>
            )}
            {!members ||
              (members && members.length === 0 && <p className="font-italic">Aucun compte lié</p>)}
          </div>
        </div>
      )}
      {manager.profile === 0 && manager.role?.role === 'collaborator' && (
        <div className="card mt-4">
          <div className="card-header">Utilisateurs liés</div>
          <div className="card-body">
            L&apos;utilisateur <span className="text-danger">n&apos;est pas manager</span>
            <div style={{ height: '1px', backgroundColor: '#D0D1D3' }} className="mt-3"></div>
            {manager.managers.length == 0 && (
              <div className="mt-3">Ce compte n&apos;est lié à aucun manager</div>
            )}
            {manager.managers.length > 0 && (
              <div className="mt-3">Ce compte est lié au manager {manager.managers[0].mail}</div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default UserManagementV2;
