import React, { useState } from 'react';
import axios from 'axios';
import { Redirect } from 'react-router';
import { BACKEND_URL } from '../../config//environment';

const CreateApiUser: React.FC = () => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');
  const [redirection, setRedirection] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<{ errorMessage: string }>(null);

  const handleFormSubmit = (e: React.FormEvent) => {
    axios
      .post(`${BACKEND_URL}/api/admin/api-users`, {
        username,
        password,
        'password-confirmation': passwordConfirmation,
      })
      .then(() => {
        setRedirection(true);
      })
      .catch((error) => {
        if (error.response) {
          setFormErrors(error.response.data);
        }
      });

    e.preventDefault();
  };

  if (redirection) {
    return <Redirect to="/admin/api-users" />;
  }

  return (
    <div className="card">
      <div className="card-header">Ajouter un Utilisateur API</div>
      <div className="card-body">
        <div className="row">
          <div className="col">
            {formErrors && (
              <div className="alert alert-danger">
                <ul>
                  <li>{formErrors.errorMessage}</li>
                </ul>
              </div>
            )}
            <form onSubmit={handleFormSubmit}>
              <div className="form-group row">
                <label htmlFor="username" className="col-md-3 mt-3">
                  Identifiant
                </label>
                <div className="col-md-9">
                  <input
                    type="text"
                    id="username"
                    data-cy="api-users-username"
                    name="username"
                    className="form-control"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="password" className="col-md-3 mt-3">
                  Mot de Passe
                </label>
                <div className="col-md-9">
                  <input
                    type="password"
                    id="password"
                    data-cy="api-users-password"
                    name="password"
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="password-confirmation" className="col-md-3 mt-3">
                  Confirmation de Mot de Passe
                </label>
                <div className="col-md-9">
                  <input
                    type="password"
                    id="password-confirmation"
                    data-cy="api-users-password-confirmation"
                    name="password-confirmation"
                    className="form-control"
                    value={passwordConfirmation}
                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                  />
                </div>
              </div>

              <div className="row justify-content-md-center mt-4">
                <div className="col-lg-6">
                  <button type="submit" className="btn btn-primary" data-cy="btn-add-api-user">
                    Ajouter
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateApiUser;
