import axios from 'axios';
import React, { FormEvent, useState } from 'react';
import { Redirect } from 'react-router';
import { BACKEND_URL } from '../../config//environment';
import BillingPriceForm from './BillingPriceForm';

const CreateBillingPrice: React.FC = () => {
  const [stripePrice, setStripePrice] = useState<IStripePrice>({
    id: null,
    name: null,
    type: 'charge',
    display_name: '',
    stripe_price_id: '',
    price_excl_tax: 0,
    price_id_after_expiration: null,
    trial_days: 1,
    active: true,
    prorate: false,
    iban_allowed: true,
    credit_card_allowed: true,
    bank_check_allowed: true,
    comment: '',
    created_at: null,
    updated_at: null,
  });
  const [redirection, setRedirection] = useState<boolean>(false);
  const [errors, setErrors] = useState<{
    [name: string]: Array<string>;
  }>(null);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    axios
      .post(`${BACKEND_URL}/api/admin/stripe-prices`, stripePrice)
      .then(() => setRedirection(true))
      .catch(({ response }) => setErrors(response.data.errors));
  };

  return (
    <>
      {redirection ? (
        <Redirect to="/admin/prices" />
      ) : (
        <div className="card">
          <div className="card-header">Nouvelle Offre</div>

          <div className="card-body">
            {errors && (
              <div className="alert alert-danger">
                <ul>
                  {Object.keys(errors).map((key, i) => (
                    <li key={`error-${i}`}>{errors[key][0]}</li>
                  ))}
                </ul>
              </div>
            )}

            <BillingPriceForm
              id="create-price-form"
              stripePrice={stripePrice}
              setStripePrice={setStripePrice}
              onSubmit={handleSubmit}
            />

            <div className="d-flex justify-content-center mt-4">
              <div className="col-lg-6">
                <button type="submit" className="btn btn-primary" form="create-price-form">
                  Ajouter
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateBillingPrice;
