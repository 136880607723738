import axios from 'axios';
import React, { FormEventHandler, useEffect, useState } from 'react';
import moment from 'moment';
import { BACKEND_URL } from '../../config//environment';

const BillingPriceForm: React.FC<{
  id: string;
  stripePrice: IStripePrice;
  setStripePrice: (price: IStripePrice) => void;
  onSubmit: FormEventHandler<HTMLFormElement>;
}> = ({ id, stripePrice, setStripePrice, onSubmit }) => {
  const handleStripePriceUpdate = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setStripePrice({ ...stripePrice, [name]: value });
  };

  return (
    <form onSubmit={onSubmit} id={id}>
      <div className="form-group row">
        <div className="col-md-2 mt-2">
          <label htmlFor="active">Active</label>
        </div>

        <div className="col-md-10">
          <select
            className="custom-select"
            id="active"
            name="active"
            onChange={handleStripePriceUpdate}
            defaultValue={`${stripePrice.active ? 1 : 0}`}
          >
            <option value="1">Oui</option>
            <option value="0">Non</option>
          </select>
        </div>
      </div>

      <div className="form-group row">
        <div className="col-md-2 mt-3">
          <label htmlFor="display-name">Nom affiché au client</label>
        </div>

        <div className="col-md-10">
          <input
            type="text"
            id="display-name"
            name="display_name"
            className="form-control"
            onChange={handleStripePriceUpdate}
            defaultValue={stripePrice.display_name}
          />
        </div>
      </div>

      <div className="form-group row">
        <div className="col-md-2 mt-3">
          <label htmlFor="trial-days">Nombre de jours</label>
        </div>

        <div className="col-md-6">
          <input
            type="number"
            min="0"
            id="trial-days"
            name="trial_days"
            className="form-control"
            onChange={handleStripePriceUpdate}
            defaultValue={stripePrice.trial_days}
          />
        </div>
        <div className="col-md-4 d-flex align-items-center">
          <span>
            Date de fin si déclenchée aujoud&apos;hui{' '}
            <b>{moment().add(stripePrice.trial_days, 'days').format('DD/MM/YYYY')}</b>
          </span>
        </div>
      </div>

      <div className="form-group row">
        <div className="col-md-2 mt-2">
          <label htmlFor="stripe-price-id">Abonnement après expiration de l’offre</label>
        </div>

        <div className="col-md-10">
          <AfterExpirationSelect
            stripePrice={stripePrice}
            setStripePrice={setStripePrice}
            handleStripePriceUpdate={handleStripePriceUpdate}
            value={stripePrice.price_id_after_expiration}
          />
        </div>
      </div>

      <div className="form-group row was-validated">
        <div className="col-md-2 mt-3">
          <label htmlFor="price-excl-tax">Prix HT</label>
        </div>

        <div className="col-md-10">
          <input
            type="text"
            pattern="0*[0-9]{1,6}(\.[0-9]{1,2})?"
            title="Le prix est sous le format 999999.99"
            id="price-excl-tax"
            name="price_excl_tax"
            className="form-control"
            onChange={handleStripePriceUpdate}
            defaultValue={stripePrice.price_excl_tax}
          />
        </div>
      </div>

      <div className="form-group row">
        <div className="col-md-2">
          <label htmlFor="comment">Commentaire</label>
        </div>

        <div className="col-md-10">
          <textarea
            id="comment"
            name="comment"
            rows={3}
            className="w-100"
            onChange={handleStripePriceUpdate}
            defaultValue={stripePrice.comment}
          />
        </div>
      </div>
    </form>
  );
};

export default BillingPriceForm;

const AfterExpirationSelect: React.FC<{
  stripePrice: IStripePrice;
  setStripePrice: (price: IStripePrice) => void;
  value: string;
  handleStripePriceUpdate: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
}> = ({ stripePrice, setStripePrice, value, handleStripePriceUpdate }) => {
  const [prices, setPrices] = useState<Array<IStripePrice>>([]);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    axios
      .get(`${BACKEND_URL}/api/admin/stripe-prices/subscriptions`)
      .then(({ data }) => {
        setPrices(data.prices);

        if (!stripePrice.price_id_after_expiration) {
          setStripePrice({
            ...stripePrice,
            ['price_id_after_expiration']: data.prices[0].stripe_price_id,
          });
        }
      })
      .finally(() => setLoaded(true));
  }, []);

  if (loaded) {
    return (
      <select
        className="custom-select"
        id="stripe-price-id"
        name="price_id_after_expiration"
        onChange={handleStripePriceUpdate}
        defaultValue={value}
      >
        {prices.length > 0 &&
          prices.map((price: IStripePrice) => (
            <option key={`opt-${price.id}`} value={price.stripe_price_id}>
              {price.display_name}
            </option>
          ))}
      </select>
    );
  } else {
    return null;
  }
};
