import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router';
import ErrorBox from '../../../common/errors/ErrorBox';
import CircularLoader from '../../../common/navigation/CircularLoader';
import Success from '../../navigation/Success';
import { Link } from 'react-router-dom';
import { BACKEND_URL } from '../../../config/environment';
import PhoneInput from '../../../common/navigation/PhoneInput';
import { formatDate } from '../../../constants';
/**
 * Load companies and show them in a responsive table.
 */
const CompanyV2: React.FC = () => {
  const [company, setCompany] = useState<ICompany>(null);
  const [invoices, setInvoices] = useState<Array<IInvoice>>([]);
  const [users, setUsers] = useState<Array<IUser>>([]);

  const [errorProfile, setErrorProfile] = useState<boolean>(false);
  const [successProfile, setSuccessProfile] = useState<string>(null);
  const [formErrorProfile, setFormErrorProfile] = useState<string>(null);
  const [successInfo, setSuccessInfo] = useState<string>(null);
  const [formErrorInfo, setFormErrorInfo] = useState<string>(null);
  const [isModifyingProfile, setIsModifyingProfile] = useState<boolean>(false);
  const [isModifyingInfo, setIsModifyingInfo] = useState<boolean>(false);

  // Company profile
  const [name, setName] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [zipCode, setZipCode] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [phoneCode, setPhoneCode] = useState<string>(null);
  const [webSite, setWebSite] = useState<string>('');

  const { enterpriseId }: { enterpriseId: string } = useParams();

  // More info
  const [siret, setSiret] = useState<number>(0);
  const [creationDate, setCreationDate] = useState<Date>();
  const [staff, setStaff] = useState<string>('');
  const [capitalStock, setCapitalStock] = useState<number>();
  const [turnover, setTurnover] = useState<number>();
  const [turnoverYear, setTurnoverYear] = useState<number>();
  const [longitude, setLongitude] = useState<number>(0);
  const [latitude, setLatitude] = useState<number>(0);

  const staffOptions = [
    { key: '0', label: '0' },
    { key: '1', label: '1-9' },
    { key: '2', label: '10-49' },
    { key: '3', label: '50-249' },
    { key: '4', label: '+250' },
  ];

  /**
   * Load the company.
   */
  useEffect(() => {
    if (!company) {
      axios
        .get(`${BACKEND_URL}/api/admin/companies/${enterpriseId}`)
        .then(({ data }) => {
          setCompany(data.company);
          setInvoices(data.invoices);
          setUsers(data.users);

          setName(data.company.name);
          setSiret(data.company.siret);
          setAddress(data.company.address);
          setZipCode(data.company.zip_code);
          setCity(data.company.city);
          setCreationDate(data.company.creation_date);
          setWebSite(data.company.website);
          setCapitalStock(data.company.capital_stock);
          setPhone(data.company.phone);
          setPhoneCode(data.company.phoneCode || 'FR');

          const option = staffOptions.find((opt) => {
            if (opt.label === data.company.size_company) return opt;
            return undefined;
          });
          if (option) {
            setStaff(option.label as string);
          }

          if (data.company.turnovers) {
            const currentTurnover = data.company.turnovers[0];
            setTurnover(currentTurnover.turnover);
            setTurnoverYear(currentTurnover.year);
          }
          setLongitude(data.company.longitude);
          setLatitude(data.company.latitude);
        })
        .catch(() => {
          setErrorProfile(true);
          return;
        });
    }
  }, [company]);

  const editProfile = () => {
    setSuccessProfile(null);
    setFormErrorProfile(null);

    if (isModifyingProfile) {
      axios
        .post(`${BACKEND_URL}/api/admin/companies/${enterpriseId}`, {
          name,
          siret,
          address,
          zip_code: zipCode,
          city,
          turnover,
          year: turnoverYear,
          capital_stock: capitalStock || undefined,
          size_company: staff || undefined,
          phone: phone || undefined,
          phone_code: phoneCode,
          website: webSite || undefined,
        })
        .then(() => {
          setSuccessProfile(`Le profil de l'entreprise a bien été modifié.`);
          setIsModifyingProfile(!isModifyingProfile);
        })
        .catch((err) => {
          const error = err?.response?.data?.errorMessage;
          setFormErrorProfile(error ? error : 'Une erreur est survenue.');
        });
    } else {
      setIsModifyingProfile(!isModifyingProfile);
    }
  };

  const resetProfile = () => {
    setName(company.name);
    setAddress(company.address);
    setZipCode(company.zip_code);
    setCity(company.city);
    setWebSite(company.website);
    setIsModifyingProfile(false);
  };

  const editInformation = () => {
    setSuccessInfo(null);
    setFormErrorInfo(null);

    if (isModifyingInfo) {
      axios
        .post(`${BACKEND_URL}/api/admin/companies/${enterpriseId}`, {
          name,
          siret,
          address,
          zip_code: zipCode,
          city,
          turnover,
          year: turnoverYear,
          capital_stock: capitalStock || undefined,
          size_company: staff || undefined,
          phone: phone || undefined,
          phone_code: phoneCode,
          website: webSite || undefined,
        })
        .then(() => {
          setSuccessInfo(`Les informations de l'entreprise ont bien été modifiées.`);
          setIsModifyingInfo(!isModifyingInfo);
        })
        .catch((err) => {
          const error = err?.response?.data?.errorMessage;
          setFormErrorInfo(error ? error : 'Une erreur est survenue.');
        });
    } else {
      setIsModifyingInfo(!isModifyingInfo);
    }
  };

  const resetInformation = () => {
    setSiret(company.siret);
    setCreationDate(company.creation_date);
    setCapitalStock(company.capital_stock);
    setPhone(company.phone);
    setPhoneCode(company.phone_code);

    const option = staffOptions.find((opt) => {
      if (opt.label === company.size_company) return opt;
      return undefined;
    });
    if (option) {
      setStaff(option.label as string);
    }

    if (company.turnovers) {
      const latestTurnover = company.turnovers.reduce((prev: any, current: any) => {
        return prev.year > current.year ? prev : current;
      });
      setTurnover(latestTurnover.turnover);
      setTurnoverYear(latestTurnover.year);
    }

    setIsModifyingInfo(false);
  };

  const handleDownloadInvoice = (invoice: IInvoice) => {
    axios
      .get(`${BACKEND_URL}/api/admin/companies/${enterpriseId}/invoices/${invoice.id}`)
      .then(({ data }) => {
        // force download
        const url = data;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  return (
    <>
      {!errorProfile && !company && <CircularLoader />}
      {errorProfile && (
        <ErrorBox text="Désolé, cette société n'existe pas" link="/admin/entreprises" />
      )}
      {successProfile && <Success text={successProfile} />}
      {successInfo && <Success text={successInfo} />}

      {company && (
        <>
          <div className="row">
            <div className="col-lg-6">
              <div className="card">
                <div className="card-header">
                  Profil de l&apos;entreprise <b>{company.name}</b>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label>Nom</label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Nom"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      disabled={!isModifyingProfile}
                    />
                  </div>

                  <div className="form-group">
                    <label>Adresse</label>
                    <input
                      type="text"
                      name="address"
                      className="form-control"
                      placeholder="Adresse"
                      onChange={(e) => setAddress(e.target.value)}
                      value={address}
                      disabled={!isModifyingProfile}
                    />
                  </div>

                  <div className="form-group">
                    <div className="row">
                      <div className="col-lg-6">
                        <label>Code postal</label>
                        <input
                          type="text"
                          name="zip_code"
                          className="form-control"
                          placeholder="Code postal"
                          onChange={(e) => setZipCode(e.target.value)}
                          value={zipCode}
                          disabled={!isModifyingProfile}
                        />
                      </div>

                      <div className="col-lg-6">
                        <label>Ville</label>
                        <input
                          type="text"
                          name="city"
                          className="form-control"
                          placeholder="Ville"
                          onChange={(e) => setCity(e.target.value)}
                          value={city}
                          disabled={!isModifyingProfile}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Numéro de téléphone</label>
                    <PhoneInput
                      value={{ phone: phone, code: phoneCode }}
                      onPhoneChange={(e) => setPhone(e.target.value)}
                      onCodeClick={(e) => setPhoneCode(e.currentTarget.value)}
                      disabled={!isModifyingProfile}
                    />
                  </div>

                  <div className="form-group">
                    <label>URL Site web</label>
                    <input
                      type="text"
                      name="address"
                      className="form-control"
                      placeholder="URL Site web"
                      onChange={(e) => setWebSite(e.target.value)}
                      value={webSite}
                      disabled={!isModifyingProfile}
                    />
                  </div>

                  {formErrorProfile && <ErrorBox text={formErrorProfile} />}

                  <div className="d-flex justify-content-between">
                    <button
                      className="btn btn-primary no-block"
                      id="btn-save"
                      data-cy="btn-save"
                      onClick={() => editProfile()}
                    >
                      {isModifyingProfile ? 'Enregistrer' : 'Modifier'}
                    </button>
                    <button
                      className="btn btn-outline-primary no-block"
                      id="btn-cancel"
                      data-cy="btn-cancel"
                      hidden={!isModifyingProfile}
                      onClick={() => resetProfile()}
                    >
                      Annuler
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="card">
                <div className="card-header">Autres informations</div>
                <div className="card-body">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-lg-6">
                        <label>SIRET</label>
                        <input
                          type="text"
                          name="siret"
                          className="form-control"
                          placeholder="SIRET"
                          onChange={(e) => setSiret(+e.target.value)}
                          value={siret}
                          disabled={!isModifyingInfo}
                        />
                      </div>

                      <div className="col-lg-6">
                        <label htmlFor="">SIRET vérifié via l&apos;API de l&apos;INSEE</label>
                        <input
                          type="text"
                          className="form-control"
                          value={company.is_verified ? 'Oui' : 'Non'}
                          disabled
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Date de création de l&apos;entreprise</label>
                    <input
                      name="date"
                      className="form-control"
                      placeholder="Date de création de l'entreprise"
                      value={creationDate ? creationDate.toString() : ''}
                      disabled
                    />
                  </div>

                  <div className="form-group">
                    <label>Effectif</label>
                    <select
                      name="staff_id"
                      data-cy="staff_id-id"
                      value={staff}
                      onChange={(e) => setStaff(e.target.value)}
                      className="form-control"
                      disabled={!isModifyingInfo}
                      style={{
                        color: staff ? '#495057' : '#A9AEB6',
                        backgroundColor: '#EEF1F6',
                      }}
                    >
                      <option value="" selected disabled hidden>
                        Effectif
                      </option>
                      {staffOptions.map((element) => (
                        <option
                          key={element.key}
                          value={element.label}
                          style={{ color: '#495057' }}
                        >
                          {element.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group">
                    <label>Capital Social</label>
                    <input
                      type="number"
                      name="capitalStock"
                      className="form-control"
                      placeholder="Capital Social"
                      onChange={(e) => setCapitalStock(Number.parseFloat(e.target.value))}
                      value={capitalStock}
                      disabled={!isModifyingInfo}
                    />
                  </div>

                  <div className="form-group">
                    <div className="row">
                      <div className="col-lg-8">
                        <label>Chiffre d&apos;affaire</label>
                        <input
                          type="number"
                          name="turnover"
                          className="form-control"
                          placeholder="Chiffre d'affaire"
                          onChange={(e) =>
                            setTurnover(e.target.value ? parseInt(e.target.value) : null)
                          }
                          value={turnover}
                          disabled={!isModifyingInfo}
                        />
                      </div>

                      <div className="col-lg-4">
                        <label htmlFor="">Année du CA</label>
                        <input
                          type="number"
                          name="turnoverYear"
                          className="form-control"
                          placeholder="Année du CA"
                          onChange={(e) =>
                            setTurnoverYear(e.target.value ? parseInt(e.target.value) : null)
                          }
                          value={turnoverYear}
                          disabled={!isModifyingInfo}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        Longitude :<br />
                        <input
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          value={longitude}
                          disabled={true}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        Latitude :<br />
                        <input
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          value={latitude}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>

                  {formErrorInfo && <ErrorBox text={formErrorInfo} />}

                  <div className="d-flex justify-content-between">
                    <button
                      className="btn btn-primary no-block"
                      id="btn-save"
                      data-cy="btn-save"
                      onClick={() => editInformation()}
                    >
                      {isModifyingInfo ? 'Enregistrer' : 'Modifier'}
                    </button>
                    <button
                      className="btn btn-outline-primary no-block"
                      id="btn-cancel"
                      data-cy="btn-cancel"
                      hidden={!isModifyingInfo}
                      onClick={() => resetInformation()}
                    >
                      Annuler
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">Informations de compte</div>

                <div className="card-body">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-lg-6">
                        <label htmlFor="">Date d&apos;inscription</label>
                        <input
                          type="text"
                          className="form-control"
                          value={company.created_at.toString()}
                          disabled
                        />
                      </div>

                      <div className="col-lg-6">
                        <label htmlFor="">Dernière modification</label>
                        <input
                          type="text"
                          className="form-control"
                          value={company.updated_at.toString()}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">Utilisateurs de cette entreprise</div>

                <div className="card-body">
                  <ul className="list-group">
                    {users &&
                      users.map((user: IUser, index: number) => (
                        <li className="list-group-item d-flex" key={index}>
                          <div>
                            <Link to={`/admin/utilisateurs/${user.id}`}>{user.mail}</Link>
                          </div>
                          <div className="ml-2">
                            <span>{user.phone ?? 'Téléphone non spécifié.'}</span>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">Factures de {company.name}</div>

                <div className="card-body">
                  <ul className="list-group">
                    {invoices &&
                      invoices.map((invoice: IInvoice) => (
                        <li
                          className="list-group-item d-flex justify-content-between"
                          key={invoice.id}
                        >
                          <div>
                            <b>{invoice.format_date}</b> -{invoice.amount_excl_tax} &euro; HT.
                          </div>
                          <a
                            href="#"
                            className="selector-link-download"
                            onClick={() => handleDownloadInvoice(invoice)}
                          >
                            Télécharger
                          </a>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CompanyV2;
