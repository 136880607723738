import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Success: React.FC<{ text: string; link?: string; onHide?: () => void }> = ({
  text,
  link,
  onHide,
}) => {
  useEffect(() => {
    if (onHide) {
      setTimeout(() => {
        onHide();
      }, 5000);
    }
  }, [text, onHide]);

  return (
    <div className="alert alert-success">
      {text}{' '}
      {link && (
        <>
          {' '}
          - <Link to={link}>Retour</Link>
        </>
      )}
    </div>
  );
};

export default Success;
