import React from 'react';

const TestAs: React.FC = () => {
  // const [logs, setLogs] = useState<Array<string>>(null);
  // const [lastPage, setLastPage] = useState<number>(0);
  // const [searchQuery, setSearchQuery] = useState<string>('');
  // const [loading, setLoading] = useState<boolean>(false);
  // const [currentPage, setCurrentPage] = useState<number>(0);

  // useEffect(() => {
  //   if (!logs) {
  //     changePage(0);
  //   }
  // }, [logs]);

  // /**
  //  * Load users in a specific page with a query string filter.
  //  * @param page Current page.
  //  */
  // const changePage = (page: number) => {
  //   setLastPage(0);
  //   setLoading(true);
  //   setCurrentPage(page);

  //   axios.get(`${BACKEND_URL}/api/admin/logs`).then(({ data }) => {
  //     setLogs(data.logs);
  //     setLastPage(data.users.last_page);
  //     setLoading(false);
  //   });
  // };

  // /**
  //  * Triggered when the user press a touch to detect if it is "Enter". It it is, is starts a search.
  //  * @param key Key pressed.
  //  */
  // const searchUser = (key: string) => {
  //   if (key === 'Enter' || searchQuery === '') {
  //     changePage(0);
  //   }
  // };

  return (
    <>
      <h2>Logs</h2>

      <div className="card card-body mt-3">
        <div className="row">
          <div className="col-sm-8 col-lg-10">
            <input
              type="text"
              className="form-control auth-input mt-0"
              placeholder="Rechercher dans les logs..."
              // onChange={(e) => setSearchQuery(e.target.value)}
              // onKeyUp={(e) => searchUser(e.key)}
            />
          </div>

          <div className="col-sm-4 col-lg-2">
            <button className="btn btn-primary" disabled={true} /*onClick={() => changePage(0)}*/>
              Rechercher
            </button>
          </div>
        </div>

        <div className="table-responsive">
          <div className="alert alert-info mt-3">Prochainement...</div>
        </div>
      </div>
    </>
  );
};

export default TestAs;
