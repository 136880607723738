import React from 'react';
import Modal from '../../common/Modal';

const PdfViewerModal: React.FC<{
  content: any;
  hidden: boolean;
  closeModal: () => void;
}> = ({ content, hidden, closeModal }) => {
  return (
    <>
      <Modal hidden={hidden} maxWidth={1700} closeModal={closeModal}>
        <div className="modal-body">
          <iframe height={800} width={1700} src={content} />
        </div>
      </Modal>
    </>
  );
};

export default PdfViewerModal;
