import React, { useState } from 'react';
import axios from 'axios';
import Modal from './index';
import ErrorMessage from '../../common/errors/ErrorMessage';
import { BACKEND_URL } from '../../config/environment';

/**
 * This react component is used to swap subscription to annual.
 * @param props
 */
const AddNewUserModal: React.FC<{
  hidden: boolean;
  refresh: () => void;
  closeModal: () => void;
}> = ({ hidden, closeModal, refresh }) => {
  const [mail, setMail] = useState<string | null>(null);
  const [error, setError] = useState<string>(null);

  const inviteUser = () => {
    axios
      .post(`${BACKEND_URL}/api/admin/invite-users`, { mail: mail })
      .then(() => {
        refresh();
        closeModal();
      })
      .catch((err) => {
        const error = err?.response?.data?.errorMessage;
        setError(error ? error : 'Une erreur est survenue.');
      });
  };

  return (
    <Modal hidden={hidden} maxWidth={900} closeModal={closeModal}>
      {error && <ErrorMessage text={error} />}
      <div className="modal-body">
        <>
          <h1>Ajouter un nouvel utilisateur</h1>
          <p className="mt-4">
            Renseignez le mail de l’utilisateur que vous voulez inviter à chantier privé.
            <br />
            <br />
          </p>
          <div className="row justify-content-md-center">
            <div className="col-lg-8 mb-3">
              <input
                className="form-control auth-input mt-2"
                placeholder="Adresse mail"
                name="new_user"
                onChange={(e) => setMail(e.target.value)}
              />
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <button
              type="button"
              data-cy="button-edit-password"
              id="button-edit-password"
              className="btn btn-secondary mt-3"
              disabled={!mail}
              onClick={() => inviteUser()}
              style={{ maxWidth: 240 }}
            >
              Ajouter
            </button>
          </div>
        </>
      </div>
    </Modal>
  );
};

export default AddNewUserModal;
