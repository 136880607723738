import React from 'react';
import { Link } from 'react-router-dom';

const Info: React.FC<{ text: string; link?: string }> = (props) => {
  return (
    <div className="alert alert-info">
      {props.text}{' '}
      {props.link && (
        <>
          {' '}
          - <Link to={props.link}>Retour</Link>
        </>
      )}
    </div>
  );
};

export default Info;
