import React, { useState, useEffect, ComponentClass } from 'react';

import axios from 'axios';
import CompanyMarkerWithInfoWindow from './CompanyMarkerWithInfoWindow';
import ProjectMarkerWithInfoWindow from './ProjectMarkerWithInfoWindow';

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  WithGoogleMapProps,
  WithScriptjsProps,
} from 'react-google-maps';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';
import { BACKEND_URL } from '../../config//environment';

const Map = () => {
  const GOOGLE_MAP_API_URL = `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAP_API_KEY}`;

  return (
    <CustomMap
      googleMapURL={GOOGLE_MAP_API_URL}
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `calc(100vh - 80px)` }} />}
      mapElement={<div style={{ height: `100%` }} />}
    />
  );
};

const CustomMap: ComponentClass<WithGoogleMapProps & WithScriptjsProps> = withScriptjs(
  withGoogleMap(() => {
    const [loadingCompanies, setLoadingCompanies] = useState(false);
    const [loadingProjects, setLoadingProjects] = useState(false);
    const [companies, setCompanies] = useState<Array<ICompany>>(null);
    const [projects, setProjects] = useState<Array<IProject>>(null);

    useEffect(() => {
      setLoadingCompanies(true);
      setLoadingProjects(true);
      getAllCompanies().then((companies) => {
        setCompanies(companies);
        setLoadingCompanies(false);
      });

      getAllProjects().then((projects) => {
        setProjects(projects);
        setLoadingProjects(false);
      });
    }, []);

    /**
     * Handle marker click.
     * @returns {void}
     */
    //eslint-disable-next-line
    const onMarkerClustererClick = (markerClusterer: any) => {
      markerClusterer.getMarkers();
    };

    /**
     * Get companies.
     * @param page companies page.
     * @returns {Array<ICompany>}
     */
    const getCompanies = async (page = 1) => {
      const apiResults = await axios
        .get(`${BACKEND_URL}/api/admin/companies/locations?page=${page}&limit=50`)
        .then(({ data }) => {
          return data.companies.data;
        });

      return apiResults;
    };

    /**
     * Get companies recursively.
     * @param page companies page.
     * @returns {Array<ICompany>}
     */
    const getAllCompanies = async (page = 1) => {
      const results = await getCompanies(page);
      if (results.length > 0) {
        return results.concat(await getCompanies(page + 1));
      } else {
        return results;
      }
    };

    /**
     * Get projects.
     * @param page projects page.
     * @returns {Array<IProject>}
     */
    const getProjects = async (page = 1) => {
      const apiResults = await axios
        .get(`${BACKEND_URL}/api/admin/projects/locations?page=${page}&limit=50`)
        .then(({ data }) => {
          return data.projects.data;
        });

      return apiResults;
    };

    /**
     * Get projects recursively.
     * @param page projects page.
     * @returns {Array<IProject>}
     */
    const getAllProjects = async (page = 1) => {
      const results = await getProjects(page);
      if (results.length > 0) {
        return results.concat(await getProjects(page + 1));
      } else {
        return results;
      }
    };

    return (
      <>
        {!loadingCompanies && !loadingProjects && companies && projects && (
          <GoogleMap defaultZoom={6} defaultCenter={{ lat: 47.0780911, lng: 2.3632841 }}>
            <MarkerClusterer
              onClick={onMarkerClustererClick}
              averageCenter
              enableRetinaIcons
              gridSize={60}
            >
              {companies.map(
                (company, index) =>
                  company.latitude &&
                  company.longitude && <CompanyMarkerWithInfoWindow key={index} company={company} />
              )}
            </MarkerClusterer>

            <MarkerClusterer
              onClick={onMarkerClustererClick}
              averageCenter
              enableRetinaIcons
              gridSize={60}
            >
              {projects.map(
                (project, index) =>
                  project.latitude &&
                  project.longitude && <ProjectMarkerWithInfoWindow key={index} project={project} />
              )}
            </MarkerClusterer>
          </GoogleMap>
        )}
      </>
    );
  })
);

export default Map;
