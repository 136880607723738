import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import CircularLoader from '../../common/navigation/CircularLoader';
import Info from '../navigation/Info';
import { BACKEND_URL } from '../../config//environment';

interface PosMap {
  [id: string]: number;
}

/**
 * Load questions by their categories
 * and display them in a responsive table for each sub-category.
 */
const FAQList: React.FC = () => {
  const [questionsByCategories, setQuestionsByCategories] = useState<Array<Array<IFaqQuestion>>>();
  const [loading, setLoading] = useState<boolean>(false);
  const positionBtnStyle = {
    border: 0,
    padding: 0,
    backgroundColor: 'inherit',
    outline: 'none',
  };

  const updateAllCategoryPositions = (id: string, positions: PosMap) => {
    return axios.post(`${BACKEND_URL}/api/admin/faq-categories/${id}/positions`, {
      positions: positions,
    });
  };

  const retrieveQuestionsData = () => {
    setLoading(true);

    axios.get(`${BACKEND_URL}/api/admin/faq-questions`).then(({ data }) => {
      setQuestionsByCategories(Object.values(data.faq_questions));
      setLoading(false);
    });
  };

  const getFaqById = (id: string) =>
    questionsByCategories
      .map((chunk) => chunk.filter((q) => id === q.id))
      .reduce((acc, faq) => acc.concat(faq, []))
      .reduce((acc) => acc);

  const extractPositionsMap = (category: Array<IFaqQuestion>) => {
    return category.reduce(
      (obj, faq) => ({
        ...obj,
        [faq.id]: faq.position,
      }),
      {}
    );
  };

  const getQuestionsByCategoryId = (questions: Array<Array<IFaqQuestion>>, id: string) => {
    return questions
      .map((chunk) => chunk.filter((q) => id === q.faq_category_id))
      .reduce((acc, faq) => acc.concat(faq, []));
  };

  /**
   * Increment if true decrement if false
   *
   * @param value
   * @param type
   */
  const incDec = (value: number, type: boolean) => (type ? value + 1 : value - 1);

  const isBetween = (value: number, range: Array<number>) => {
    const min = Math.min(...range);
    const max = Math.max(...range);

    return min <= value && value <= max;
  };

  const calculateNewQuestionsPosition = (id: string, type: string) => {
    const toIncrement: boolean = type === 'down';
    const old = getFaqById(id);
    const updated = {
      ...old,
      position: incDec(old.position, toIncrement),
    };

    return questionsByCategories.map((cat) =>
      cat.map((faq) => {
        const isTheUpdatedRow = updated.id === faq.id;
        const sameCategory = faq.faq_category_id === updated.faq_category_id;
        const isBetweenOldNewPosition = isBetween(faq.position, [old.position, updated.position]);

        if (isTheUpdatedRow) {
          return updated;
        }
        if (sameCategory && isBetweenOldNewPosition) {
          return {
            ...faq,
            position: incDec(faq.position, !toIncrement),
          };
        }
        return faq;
      })
    );
  };

  const prepareAndSendPositionsUpdateRequest = (id: string, updatedQuestions: IFaqQuestion[][]) => {
    const question = getFaqById(id);
    const categoryId = question.faq_category_id;
    const questions = getQuestionsByCategoryId(updatedQuestions, categoryId);
    const positions = extractPositionsMap(questions);

    return updateAllCategoryPositions(categoryId, positions);
  };

  const handleOnDeleteQuestion = (event: React.SyntheticEvent, faqID: string) => {
    const message = 'Êtes vous sûr(e) de vouloir supprimer cet question ?';
    if (!window.confirm(message)) {
      return;
    }

    setLoading(true);
    axios.delete(`${BACKEND_URL}/api/admin/faq-questions/${faqID}`).then(() => {
      const newState = questionsByCategories.map((chunk) => chunk.filter((q) => q.id !== faqID));
      setQuestionsByCategories(newState);
      setLoading(false);
    });

    event.preventDefault();
  };

  const handleUpPosition = (event: React.SyntheticEvent, id: string) => {
    const updatedQuestions = calculateNewQuestionsPosition(id, 'up');
    prepareAndSendPositionsUpdateRequest(id, updatedQuestions).then(() =>
      setQuestionsByCategories(updatedQuestions)
    );

    event.preventDefault();
  };

  const handleDownPosition = (event: React.SyntheticEvent, id: string) => {
    const updatedQuestions = calculateNewQuestionsPosition(id, 'down');
    prepareAndSendPositionsUpdateRequest(id, updatedQuestions).then(() =>
      setQuestionsByCategories(updatedQuestions)
    );

    event.preventDefault();
  };

  /**
   * Display or Hide faq categories.
   * @param id FAQ categorie.
   */
  const editFaqStatus = (id: string) => {
    axios.post(`${BACKEND_URL}/api/admin/faq-categories/${id}/status`).then(() => {
      retrieveQuestionsData();
    });
  };

  /**
   * Load questions first time.
   */
  useEffect(() => {
    if (!questionsByCategories) {
      retrieveQuestionsData();
    }
  }, []);

  return (
    <>
      {loading && <CircularLoader />}
      {!loading && questionsByCategories && questionsByCategories.length === 0 && (
        <Info text="Aucune question n'a été trouvé." />
      )}

      {questionsByCategories &&
        questionsByCategories.map(
          (questions: IFaqQuestion[]) =>
            !loading &&
            questions &&
            questions.length > 0 && (
              <div className="card mb-4" key={questions[0].faq_category.id}>
                <div className="card-header d-flex justify-content-between">
                  <h5>
                    {questions[0].faq_category.main_category.title}
                    {' > '}
                    {questions[0].faq_category.title}
                  </h5>
                  <Link
                    to={`/admin/faq/create?cat=${questions[0]?.faq_category?.main_category?.id}&subCat=${questions[0]?.faq_category?.id}`}
                  >
                    Ajouter une question
                  </Link>
                  <Link to="#" onClick={() => editFaqStatus(questions[0].faq_category.id)}>
                    {!questions[0].faq_category.is_shown ? 'Afficher' : 'Cacher'}
                  </Link>
                </div>

                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-hover table-bordered mt-2">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th className="col-6" scope="col">
                            Question
                          </th>
                          <th scope="col">Accessible sur App</th>
                          <th scope="col">Accessible sur Public</th>
                          <th scope="col">Visible</th>
                          <th scope="col">Modifier</th>
                          <th scope="col">Supprimer</th>
                        </tr>
                      </thead>
                      <tbody>
                        {questions &&
                          questions.length > 0 &&
                          questions
                            .sort((a, b) => a.position - b.position)
                            .map((faq: IFaqQuestion, index) => (
                              <tr key={faq.id}>
                                <td>
                                  <div>
                                    <button
                                      type="button"
                                      style={positionBtnStyle}
                                      onClick={(e) => handleUpPosition(e, faq.id)}
                                      disabled={index === 0}
                                    >
                                      <CaretUpIcon />
                                    </button>
                                    <button
                                      type="button"
                                      style={positionBtnStyle}
                                      onClick={(e) => handleDownPosition(e, faq.id)}
                                      disabled={index === questions.length - 1}
                                    >
                                      <CaretDownIcon />
                                    </button>
                                  </div>
                                </td>
                                <td>{faq.question}</td>
                                <td>
                                  <YesNo value={faq.app_visibility} />
                                </td>
                                <td>
                                  <YesNo value={faq.public_visibility} />
                                </td>
                                <td>
                                  <YesNo value={faq.visible} />
                                </td>
                                <td>
                                  <Link to={`/admin/faq/${faq.id}/edit`}>Modifier</Link>
                                </td>
                                <td>
                                  <a
                                    href="#"
                                    onClick={(event) => handleOnDeleteQuestion(event, faq.id)}
                                  >
                                    Supprimer
                                  </a>
                                </td>
                              </tr>
                            ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )
        )}
    </>
  );
};

export default FAQList;

const CaretUpIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    className="bi bi-caret-up-fill"
    viewBox="0 0 16 16"
  >
    <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
  </svg>
);

const CaretDownIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    className="bi bi-caret-down-fill"
    viewBox="0 0 16 16"
  >
    <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
  </svg>
);

const YesNo: React.FC<{ value: boolean }> = ({ value }) => {
  if (value) {
    return <span className="text-success">Oui</span>;
  } else {
    return <span className="text-danger">Non</span>;
  }
};
