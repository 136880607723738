import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CircularLoader from '../../common/navigation/CircularLoader';
import Info from '../navigation/Info';
import moment from 'moment';
import { BACKEND_URL, FRONTEND_URL } from '../../config//environment';

const Billings: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [prices, setPrices] = useState<Array<IStripePrice>>([]);

  const deletePrice = (id: string) => {
    if (window.confirm('Voulez-vous vraiment supprimer ?')) {
      axios.delete(`${BACKEND_URL}/api/admin/stripe-prices/${id}`).then(() => {
        retrievePricesData();
      });
    }
  };

  const retrievePricesData = () => {
    setLoading(true);

    axios
      .get(`${BACKEND_URL}/api/admin/stripe-prices`)
      .then(({ data }) => {
        setPrices(data.prices);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    retrievePricesData();
  }, []);

  return (
    <>
      <h2 className="mb-3">Facturation</h2>

      <Info text="Retrouvez ici la liste de tous les prix sur Chantier Privé." />

      <div className="card mt-3">
        <div className="card-header">Prix des offres</div>

        <div className="card-body">
          <div className="row mb-2">
            <div className="col-sm-3">
              <Link
                to="/admin/prices/create"
                className="btn btn-primary"
                id="add-stripe-price-anchor"
              >
                Ajouter une Offre
              </Link>
            </div>
          </div>

          {loading && <CircularLoader />}

          {!loading && prices.length === 0 && <Info text="Pas d'offres disponibles" />}

          {!loading && prices.length > 0 && (
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Nom affiché au client</th>
                  <th>Plan après période</th>
                  <th>Prix HT (€)</th>
                  <th>Nombre de jours</th>
                  <th>Fin de l&apos;abonnement si déclenchée aujourd&apos;hui</th>
                  <th>Offre active</th>
                  <th>Commentaires</th>
                  <th>Modifier</th>
                  <th>Supprimer</th>
                  <th>Lien</th>
                </tr>
              </thead>

              <tbody>
                {prices.map((price) => (
                  <tr key={`row-${price.id}`}>
                    <td>{price.display_name}</td>
                    <td>{price.price_after_expiration?.display_name}</td>
                    <td>{price.price_excl_tax}</td>
                    <td>{price.trial_days}</td>
                    <td>{moment().add(price.trial_days, 'days').format('DD/MM/YYYY')}</td>
                    <td>{price.active ? 'Oui' : 'Non'}</td>
                    <td>{price.comment}</td>
                    <td>
                      <Link to={`/admin/prices/${price.id}/edit`}>Modifier</Link>
                    </td>
                    <td>
                      <Link to="#" onClick={() => deletePrice(price.id)}>
                        Supprimer
                      </Link>
                    </td>
                    <td>
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        to={{ pathname: `${FRONTEND_URL}/login?offer=${price.name}` }}
                      >
                        Lien
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default Billings;
